import { ApiService } from '@/shared/_services/api.service';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { Observable, Subject, forkJoin, lastValueFrom, map, takeUntil,firstValueFrom } from 'rxjs';
import {
  ObjectUtil, DateUtil, UnitService, GraphService,
  SiteService, HelperService, RuntimeGraphService, HeatMapToolTipService,
  DeviceHelperService, ArrayUtil, zoneProfile_vav, zoneProfile_dab, zoneProfile_cpu_hyperstat, zoneProfile_cpu_non_hyperstat,
  zoneProfile_hpu_non_hyperstat, ZoneProfile_2pipe_non_hyperstat, zoneProfile_pipe4, zoneProfile_emr, zoneProfile_pid, zoneProfile_sse, zoneProfile_sense, zoneProfile_monitoring, zoneProfile_vrv, zoneReconfig_ti, zoneProfile_bpos, zoneProfile_otn, zoneProfile_dualDuct, zoneProfile_series,
  zoneProfile_parallel, zoneProfile_hyperstathpu, zoneProfile_hyperstatPipe2, zoneProfile_hyperstatCpu_specific, zoneProfile_hyperstatSplit_specific, zoneProfile_chilledBeam,zoneProfile_hsplit_cpu_widgets,zoneProfile_hs_pipe2,zoneProfile_Mystat_pipe2,
  CommonMethodService,
} from '@75f/portal-ui-components';
import { environment } from 'src/environments/environment';
import { profileTags } from 'src/app/shared/constant/profileTags';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-zone-layout',
  templateUrl: './zone-layout.component.html',
  styleUrls: ['./zone-layout.component.scss']
})
export class ZoneLayoutComponent implements OnInit {

  checkForAdvanceAhuProfiles = ["vavAdvancedHybridAhuV2", "dabAdvancedHybridAhuV2"];
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.graphWidth = window.innerWidth - 200;
  }

  @Input() qrCodeMetaData: any;


  private unsubscribe: Subject<void> = new Subject();
  private subscriptionCollection: Array<any> = new Array<any>();
  private heatCoolLimitsHisData = new Map<string, any>();
  private getUserSettingDataSubscriptionCollection: any = {};
  delayChanges = new Subject();

  showLoader: boolean = true;
  equipGraphicData: any;
  hsCpuWidgetsData: Array<any> = [];
  userTempPreference: any = '°F';
  checkWidgestLoaded = {};
  zonePriorityCount = 0;
  zoneScheduleCount = 0;
  tempZoneSettingsHolder = null;
  zonehasDomainName:any;


  zoneSettings: any = {
    currentTemp: undefined,
    coolingSupplyAir: undefined,
    coolingDamper: undefined,
    heatingDamper: undefined,
    heatingSupplyAir: undefined,
    desiredTempHeating: undefined,
    desiredTempCooling: undefined,
    heatingUserLimitMin: undefined,
    heatingUserLimitMax: undefined,
    coolingUserLimitMin: undefined,
    coolingUserLimitMax: undefined,
    coolingDeadband: undefined,
    heatingDeadband: undefined,
    equipScheduleStatus: undefined,
    scheduleType: undefined,
    equipStatusMessage: undefined,
    masterOperationMode: undefined,
    masterControllerMode: undefined,
    damperPos: undefined,
    reheatPos: undefined,
    enteringAirTemp: undefined,
    dischargeAirTemp: undefined,
    airFlow: undefined,
    zonePriority: undefined,
    ConditionMode: undefined,
    fanMode: undefined,
    buildingSchedule: undefined,
    zoneSchedule: undefined,
    zoneVacation: undefined,
    currentRate: undefined,
    energyReading: undefined,
    inputValue: undefined,
    offsetValue: undefined,
    targetValue: undefined,
    /****Config */
    dampersizetype1: undefined,
    dampersizetype2: undefined,
    dampertype1: undefined,
    dampertype2: undefined,
    dampershapetype1: undefined,
    dampershapetype2: undefined,
    tempoffset: undefined,
    maxdampercoolingpos: undefined,
    maxdamperheatingpos: undefined,
    mindampercoolingpos: undefined,
    mindamperheatingpos: undefined,
  };

  accordionHeaderStyle: any = {
    firstLevel: {
      'font-size': '16px',
      'font-weight': '600',
      // 'color': '#E24301',
      color: '#4e4e4e',
      'text-transform': 'capitalize'
    },
    secondLevel: {
      'font-size': '12px',
      'font-weight': '700',
      'line-height': '14px',
      'font-family': 'Lato',
      'color': '#333333',
      'text-transform': 'capitalize'
    },
  };

  buildings: any = {
    buildings: [],
    floors: [],
    floorsMaster: [],
    ccus: [],
    floorCcu: [],
    rooms: {},
    equips: {},
    zones: [],
    schedule: [],
    tuners: []
  };

  systemWidgets: any;

  onSystemMode = false;
  refs: any = {
    siteRef: undefined,
    floorRef: undefined,
    ccuName: undefined,
    zoneName: undefined,
    equipRef: undefined,
    roomRef: undefined,
    gateway: undefined,
    ccuEquipRef: undefined
  };

  priorityArray: any = [];
  version: any;
  cmVersion;
  allDevices: Array<any> = [];

  profileCheckFlag: Boolean = false;

  siteTz: string;
  siteTimeZones = {}
  buildingMaster: any;
  graphWidth: any;
  xCords: any = [];
  selectedDateRange: any;

  isDomainIntegrated: boolean = false;
  multiModuleProfile = false;
  checkHyperStatProfile: any;
  showTerminalEquipGraphics: boolean = false;
  profiles: any;
  isModbus: boolean;
  ccuOrZone = { type: 'zone', actionUsed: 'click' };
  siteRef: any;
  selected: any;
  roomObj: any;
  profileObj: any = {
    profileType: undefined,
    profile: undefined,
  };

  monitoringProfile: boolean = false;

  multiModuleProfileHasHPU = false;
  multiModuleProfileHasCPU = false;
  zonePriorityVal: any;
  oldZonePriorityVal: any;
  scheduleTypeVal: any;
  oldscheduleTypeVal: any;
  widgetData: any = [];
  partnerName: any;
  showPredefinedEquipGraphic: any = {};
  onGraphicListChangedEvent: any; 
  piAnalogSensorsDmDomainNames = [
    'voltageInput_ai1',
    'pressureSensor_ai1',
    'differentialAirPressureSensor_025_ai1',
    'airFlowSensor_ai1',
    'zoneHumidity_ai1',
    'zoneCo2_ai1',
    'zoneCo_ai1',
    'zoneNo2_ai1',
    'currentTx10_ai1',
    'currentTx20_ai1',
    'currentTx50_ai1',
    'genericIonSensorPoint_ai1'
  ];
  
  piAnalog2SensorsDmDomainNames = [
      'voltageInput_ai2',
      'pressureSensor_ai2',
      'differentialPressureSensor_025_ai2',
      'airFlowSensor_ai2',
      'zoneHumidity_ai2',
      'zoneCo2_ai2',
      'zoneCo_ai2',
      'zoneNo2_ai2',
      'currentTx10_ai2',
      'currentTx20_ai2',
      'currentTx50_ai2'
    ];
    
  thermistorSensorDmDomainNames = [
      'externalAirTempSensor',
      'airTempSensor100kOhms',
      'genericAlarmNC',
      'genericAlarmNO'
    ];
    
  nativeSensorDMDomainNames = [
      'currentTemp',
      'zoneHumidity',
      'zoneCo2',
      'zoneCo',
      'zoneNo',
      'pressureSensor',
      'zoneSound',
      'zoneOccupancy',
      'zoneIlluminance',
      'zoneCo2e',
      'zoneUvi',
      'zonePm25',
      'zonePm10'
    ];
  sensorType:any;
  sensorType2:any;
  loadAnalytics: boolean = false;

  constructor(public apiService: ApiService,
    public deviceHelper: DeviceHelperService,
    public helperService: HelperService,
    private siteService: SiteService,
    public runTimeGraphsService: RuntimeGraphService,
    public heatMapToolTipService: HeatMapToolTipService,
    public graphService: GraphService,
    public unitService: UnitService,
    private commonMethodService: CommonMethodService,
    private titleService: Title) { }

  ngOnInit(): void {
    this.partnerName = environment.partnerName;
    this.refs.siteRef = this.qrCodeMetaData.siteRef; // Adding site details to refs
    this.loadAnalytics = false;
    this.init();
  }

  init() {
    this.getSiteInfo(this.qrCodeMetaData.siteRef);   // Fetching site details
    this.getBuildingFloors(this.qrCodeMetaData.siteRef); // Fetching building details
  }

  // Fetching site details
  getSiteInfo(siteRef) {
    this.apiService.getSiteById(siteRef).subscribe(({ rows }) => {
      if (rows.length) {
        // got data for site
        if (rows[0] && rows[0].tz) {
          this.siteTz = rows[0].tz;
          this.siteTimeZones[siteRef] = this.apiService.getIANATimeZone(this.siteTz);
        }
        this.refs['siteDetails'] = rows[0];
      }
    });
  }

  // Fetching building details
  async getBuildingFloors(siteRef: string, isReload = false) {
    let query = `(room or ccu or floor or equip or device or schedule or schedulable or scheduleType or vacation or (building and occupancy) or (schedulable and default) or (building and limit and (min or max))) and siteRef==@${this.qrCodeMetaData.siteRef}`;
    this.apiService.getEntitiesInBuilding(query).pipe(
      map(this.helperService.listEntities),
      map(this.helperService.listZones),
      map(this.helperService.createEntityStructure),
    ).subscribe(
      async (response: any) => {
        this.buildingMaster = response;
        const entities = ArrayUtil.deepFlatten(response, 'entities');
        this.buildings['entites'] = entities;
        this.buildings['devices'] = this.getNodes(response, ['device']);
        this.buildings['equips'] = this.getNodes(response, ['equip']);
        this.buildings['buildingOccupancy'] = [];
        this.buildings['schedulable'] = this.buildings['entites'].filter(equip => ['schedulable'].every(elem => equip.markers.indexOf(elem) > -1))
        this.allDevices = this.getNodes(entities, ['cm', 'device']);
        this.buildings.floors = this.helperService.getBuildingComponents(this.buildingMaster, 'floor');
        this.buildings.tuners = this.helperService.getBuildingComponents(this.buildingMaster, 'equip') || [];
        this.buildings.ccus = this.buildings.floorCcu = this.helperService.getBuildingComponents(this.buildingMaster, 'ccu');
        const allZones = this.getNodes(entities, ['room']);
        this.buildings.zones = allZones;
        this.getZoneSettingsData();
      }, () => {

      }
    );

    this.refs.siteRef = siteRef;
  }

  // Fetching zone details
  async getZoneSettingsData() {
    this.graphWidth = window.innerWidth - 200;
    this.heatMapToolTipService.ccuorzone = this.ccuOrZone;
    this.checkWidgestLoaded = {};
    this.checkHyperStatProfile = undefined;
    this.refs.roomRef = '';
    this.refs.equipRef = '';
    this.zoneSettings = null;
    this.intializeDate(); // Intialize date for graph
    const zoneObj = this.buildings.zones.find(zone => {
      return zone._id == this.qrCodeMetaData.id;
    })
    this.titleService.setTitle(zoneObj?.name);
    this.refs.ccuRef = zoneObj?.referenceIDs?.ccu;
    let ccuObj = this.buildings.ccus.find(ccu => ccu._id == this.refs.ccuRef);
    this.refs.ahuRef = ccuObj?.referenceIDs?.ahu || ccuObj?.referenceIDs?.gateway;
    this.refs.ccuName = ccuObj?.name;

    try {
      let zoneid = this.qrCodeMetaData.id;
      let query = `siteRef==@${this.refs.siteRef} and (${this.refs.ccuRef ? 'id==@' + zoneid + ' or ccuRef==@' + this.refs.ccuRef : 'id==@' + zoneid + ' or roomRef==@' + zoneid})`;
      this.widgetData = await lastValueFrom(this.apiService.findByQuery(query).pipe(
        map(this.helperService.stripHaystackTypeMapping),
        map(this.helperService.listEntities),
        map(this.helperService.createEntityStructure)));
    } catch (error) {
      this.widgetData = [];
      console.log('Error in fetching ccu data', error);
    }

    this.getEquipRef(this.qrCodeMetaData.id);
    this.profiles = this.deviceHelper.getZoneProfile([this.roomObj]);
    this.modbusProfileSort();
    this.checkFilesTaggedforProfile();
    this.loadAnalytics = true;
    this.multiModuleProfile = (this.profiles.length > 1) ? true : false;
    if (this.profiles[(this.profiles).length - 1]) {
      this.profileCheckFlag = ['vav', 'dab', 'cpu', 'hpu', 'pipe2', 'pipe4', 'sse', 'ti', 'otn', 'bpos', 'dualDuct', 'series', 'parallel', 'vrv', 'chilledBeam'].includes(this.profiles[(this.profiles).length - 1].profileType);
    }
    if (this.multiModuleProfile) {
      const profiles = Array.from(this.profiles);
      profiles.forEach((_item) => {
        if (_item && ['vav', 'dab', 'ti', 'series', 'parallel', 'otn', 'bpos', 'chilledBeam'].includes(_item['profileType'])) {
          this.zonePriorityCount++;
        }
        if (_item && ['vav', 'dab', 'cpu', 'hpu', 'pipe2', 'pipe4', 'sse', 'ti', 'otn', 'bpos', 'dualDuct', 'series', 'parallel', 'vrv', 'chilledBeam'].includes(_item['profileType'])) {
          this.zoneScheduleCount++;
        }
      });
    }

    this.showTerminalEquipGraphics = false;
    this.getProfileData(this.profiles);
    this.getPointsData('zoneClick'); // Fetching 
  }


  async getTrueCfmEdgePoints(profileObj: any): Promise<{ calculativeDamperPoint: boolean, calculativeReheatPoint: boolean, reheatValue: any, systemOperationModePoint: any }> {
    const calculativeDamperPoint = !!this.helperService.getPointIdbyTags(profileObj, null, null, null, ['damperCmdCal'])?.[0];
    const calculativeReheatPoint = !!this.helperService.getPointIdbyTags(profileObj, null, null, null, ['reheatCmdCal'])?.[0];
    const reheatType = this.helperService.getPointIdbyTags(profileObj, null, null, null, ['reheatType'])?.[0];
    let reheatValue = null;
    let systemOperationModePoint = null;
    if (reheatType?.length > 0) {
      try {
        const response = await firstValueFrom(this.siteService.getBulkWritablePointDataForQRCode([reheatType],this.qrCodeMetaData));
        reheatValue = this.helperService.stripHaystackTypeMapping(response?.rows[0]?.data[0]?.val);
      } catch (error) {
        console.error('Error fetching writable point data:', error);
      }
    }

    try {
      systemOperationModePoint = await this.getSystemOperatingModepoint();
    } catch (error) {
      console.error('Error retrieving operating mode', error);
    }

    return { systemOperationModePoint, calculativeDamperPoint, calculativeReheatPoint, reheatValue };
  }

  getProfileData(profileObj: any) {
    profileObj.map(profile => {
      if (profile) {
        this.loadProfileTags(profile.profileType, profile.profile, profile.tags, profile.portNum, profile.modBusEquip);
      }
    });

    // Check if its multi module , and it doenst have any runtime ,fire data fetch from here
    if (this.multiModuleProfile && this.runTimeGraphsService.moduleWithRuntime == null && !this.multiModuleProfileHasHPU && !this.multiModuleProfileHasCPU) {
      this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange);
    }
  }

  async getSystemOperatingModepoint() {
    const equipObj =this.widgetData.filter(equip => ['system', 'equip', 'profile'].every(elem => equip.markers.indexOf(elem) > -1) && equip._id == this.refs.ahuRef)[0];
    let hsCpuAnalogOut1 = this.helperService.getPointIdbyTags(equipObj, null, null, null, ['operatingMode'])[0];
    try {
        const res = await firstValueFrom(this.siteService.getReadByIdManyForQRCode([hsCpuAnalogOut1],this.qrCodeMetaData));

        if (res?.rows?.length > 0) {
            return this.helperService.stripHaystackTypeMapping(res.rows[0]?.data[0]?.val).replace(/\ .*/, '');
        } else {
            console.error('Error: No data returned for the operating mode point');
            return null;
        }
    } catch (error) {
        console.error('Error fetching data', error);
        return null;
    }

  }

  // Loading Profile Tags
  async loadProfileTags(profileType: string, profileObj: any, tags: any, moduleIdentifier: string = null, modBusEquip: string = null) {
    if (profileType == 'hyperstat-cpu') {
      profileType = 'cpu'
    }
    this.profileObj.profileType = profileType;
    this.profileObj.profile = profileObj;
    let zoneProfileType = profileObj.markers.find(e => e === 'hyperstat' || e === 'smartstat' || e === 'hyperstatsplit' || e === 'mystat');
    switch (profileType) {
      case 'hpu':
        if (zoneProfileType == 'smartstat') {
          this.multiModuleProfileHasHPU = true;
          this.getHPUConfigPoints(profileObj).pipe(takeUntil(this.unsubscribe)).subscribe(responseList => {
            const airflowEnable = responseList[0];
            const hpcType = responseList[1];
            const hpuRelay5Config = responseList[2];
            if (airflowEnable.rows.length > 0 && hpcType.rows.length > 0 && hpuRelay5Config.rows.length > 0) {
              this.loadGraphWidget(profileType, profileObj, tags, airflowEnable.rows[0].val, hpcType.rows[0].data[0].val, hpuRelay5Config.rows[0].data[0].val, null, moduleIdentifier,false,null,false, false, null);
            }
            // Check if its multi module , fire data fetch from here
            if (this.multiModuleProfile) {
              this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange, 'hpu' + moduleIdentifier);
            }
          });
        } else {
          this.loadGraphWidget(profileType, profileObj, tags, true, null, null, null, moduleIdentifier,false,null,false, false, null);
        }
        break;

      case 'chilledBeam':
      case 'vav':
      case 'series':
      case 'parallel':
        let isVavTrueCfmControlEnabled: boolean;
        const vavTrueCfmAirFlow = this.helperService.getPointIdbyTags(profileObj, ['trueCfm', 'air', 'flow'], null, null, 'airFlowSensor');
        const vavTrueCfmMinHeating = this.helperService.getPointIdbyTags(profileObj, ['heating', 'min', 'trueCfm'], null, null, 'minCFMReheating');
        const vavTrueCfmMaxHeating = this.helperService.getPointIdbyTags(profileObj, ['heating', 'max', 'trueCfm'], null, null, 'maxCFMReheating');
        const vavTrueCfmMinCooling = this.helperService.getPointIdbyTags(profileObj, ['cooling', 'min', 'trueCfm'], null, null, 'minCFMCooling');
        const vavTrueCfmMaxCooling = this.helperService.getPointIdbyTags(profileObj, ['cooling', 'max', 'trueCfm'], null, null, 'maxCFMCooling');
        if (vavTrueCfmAirFlow.length > 0 && vavTrueCfmMinHeating.length > 0 && vavTrueCfmMaxHeating.length > 0 && vavTrueCfmMinCooling.length > 0 && vavTrueCfmMaxCooling.length > 0) {
          isVavTrueCfmControlEnabled = true;
        } else {
          isVavTrueCfmControlEnabled = false;
        }
        this.getTrueCfmEdgePoints(profileObj)
        .then(({ systemOperationModePoint, calculativeDamperPoint, calculativeReheatPoint, reheatValue }) => {
            this.loadGraphWidget(profileType, profileObj, tags, true, null, null, null, moduleIdentifier, isVavTrueCfmControlEnabled,systemOperationModePoint,calculativeDamperPoint, calculativeReheatPoint, reheatValue );
        })
        .catch(error => {
          this.loadGraphWidget(profileType, profileObj, tags, true, null, null, null, moduleIdentifier, isVavTrueCfmControlEnabled,null,false, false, null );
        });
        break;

      case 'dab':
      case 'dualDuct':
        let isDabTrueCfmControlEnabled: boolean;
        const dabTrueCfmAirFlow = this.helperService.getPointIdbyTags(profileObj, ['trueCfm', 'air', 'flow']);
        const dabTrueCfmIAQ = this.helperService.getPointIdbyTags(profileObj, ['trueCfm', 'iaq']);
        if (dabTrueCfmAirFlow.length > 0 && dabTrueCfmIAQ.length > 0) {
          isDabTrueCfmControlEnabled = true;
        } else {
          isDabTrueCfmControlEnabled = false;
        }
        this.loadGraphWidget(profileType, profileObj, tags, true, null, null, null, moduleIdentifier, isDabTrueCfmControlEnabled,null,false, false, null);
        break;

      case 'cpu':
        if (zoneProfileType == "hyperstat" || zoneProfileType == "hyperstatsplit") {

          let hsCpuAnalogOut1 = (this.helperService.getPointIdbyTags(profileObj, ['association', 'output', 'analog1'], null))
          let hsCpuAnalogOut2 = (this.helperService.getPointIdbyTags(profileObj, ['association', 'output', 'analog2'], null))
          let hsCpuAnalogOut3 = (this.helperService.getPointIdbyTags(profileObj, ['association', 'output', 'analog3'], null))
          let hsCpuAnalogIn1 = (this.helperService.getPointIdbyTags(profileObj, ['association', 'input', 'analog1'], null))
          let hsCpuAnalogIn2 = (this.helperService.getPointIdbyTags(profileObj, ['association', 'input', 'analog2'], null))

          this.hsCpuWidgetsData.push(hsCpuAnalogOut1[0], hsCpuAnalogOut2[0], hsCpuAnalogOut3[0], hsCpuAnalogIn1[0], hsCpuAnalogIn2[0])
          this.siteService.getBulkWritablePointDataForQRCode(this.hsCpuWidgetsData.filter(v => v), this.qrCodeMetaData).subscribe((writableData) => {

            const hsCpuAnalogIn1 = this.helperService.stripHaystackTypeMapping(writableData?.rows[3]?.data[0]?.val).split(' ')[0];
            const hsCpuAnalogIn2 = this.helperService.stripHaystackTypeMapping(writableData?.rows[4]?.data[0]?.val).split(' ')[0];

            this.loadGraphWidget(profileType, profileObj, tags, true, null, hsCpuAnalogIn1, hsCpuAnalogIn2, moduleIdentifier,false,null,false, false, null);
            if (this.multiModuleProfile) {
              this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange, profileType + moduleIdentifier);
            }
          });
        } else {
          this.multiModuleProfileHasCPU = true;
          this.getCPUConfigPoints(profileObj).pipe(takeUntil(this.unsubscribe)).subscribe(responseList => {
            const cpuRelay6Config = responseList[0];
            if (cpuRelay6Config.rows.length > 0) {
              this.loadGraphWidget(profileType, profileObj, tags, true, null, null, cpuRelay6Config.rows[0]?.data[0]?.val, moduleIdentifier,false,null,false, false, null);
            }
            // Check if its multi module , fire data fetch from here
            if (this.multiModuleProfile) {
              this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange, 'cpu' + moduleIdentifier);
            }

          });

        }
        break;

      default:
        this.loadGraphWidget(profileType, profileObj, tags, true, null, null, null, moduleIdentifier,false,null,false, false, null);
        break;
    }

    let equipName;
    if (this.multiModuleProfile) {
      equipName = profileObj.name;
    }



    this.loadUserIntent(profileType, profileObj, zoneProfileType);

    let entities = ArrayUtil.deepFlatten(this.widgetData, 'entities');
    this.getZoneSettings('singleDual', this.helperService.getPointIdbyTags(entities, ['hvacMode'], this.refs.roomRef), 'read');
  }

  async getPiDataDM(profileObj: any) {
    const piData = {
        target: '',
        dynamicTarget: '',
        loopOutput: '',
        input: '',
        inputUnit: '',
        outputUnit: ''
    };

    const thermistorNames = [
        'externalAirTempSensor',
        'airTempSensor100kOhms',
        'genericAlarmNC',
        'genericAlarmNO'
    ];
    const nativeSensorNames = [
        "currentTemp", "zoneHumidity", "zoneCo2", "zoneCo", "zoneNo", "pressureSensor",
        "zoneSound", "zoneOccupancy", "zoneIlluminance", "zoneCo2e", "zoneUvi", "zonePm25", "zonePm10"
    ];
    const piAnalogSensorsDm = [
      "voltageInput_ai1",
      "pressureSensor_ai1",
      "differentialAirPressureSensor_025_ai1",
      "airFlowSensor_ai1",
      "zoneHumidity_ai1",
      "zoneCo2_ai1",
      "zoneCo_ai1",
      "zoneNo2_ai1",
      "currentTx10_ai1",
      "currentTx20_ai1",
      "currentTx50_ai1",
      "genericIonSensorPoint_ai1"
    ];
    const piAnalog2SensorsDm = [
      "voltageInput_ai2",
      "pressureSensor_ai2",
      "differentialAirPressureSensor_025_ai2",
      "airFlowSensor_ai2",
      "zoneHumidity_ai2",
      "zoneCo2_ai2",
      "zoneCo_ai2",
      "zoneNo2_ai2",
      "currentTx10_ai2",
      "currentTx20_ai2",
      "currentTx50_ai2"
    ];         

    if (!profileObj) {
        return piData;
    }

    const entities = profileObj.entities || [];
    const getPointId = (tag: string) =>
        this.helperService.getPointIdbyTags(profileObj, null, null, null, tag)?.[0]; // Fetch the first ID for each tag

    const ids = [
        getPointId('nativeSensorType'),
        getPointId('analog1InputType'),
        getPointId('analog2InputType'),
        getPointId('thermistor1InputType')
    ].filter(id => id);
    if (ids.length === 0) {
        console.error('No valid IDs found for the provided tags.');
        return piData;
    }
    try {
        const data = await lastValueFrom(
            this.apiService.getWritablePointData(ids).pipe(
                map(this.helperService.stripHaystackTypeMapping)
            )
        );
        const resolveSensorType = (value: any, names: string[], adjustment = 0) => {
            const index = Number(value[0]?.data[0]?.val) - adjustment;
            return names[index] || null;
        };

        this.sensorType =
            resolveSensorType(data?.rows?.filter(item => item?.domainName=='nativeSensorType'), nativeSensorNames, 1) ||
            resolveSensorType(data?.rows?.filter(item => item?.domainName=='analog1InputType'), piAnalogSensorsDm, 1) ||
            resolveSensorType(data?.rows?.filter(item => item?.domainName=='thermistor1InputType'), thermistorNames, 1);

        this.sensorType2 = resolveSensorType(data?.rows?.filter(item => item?.domainName=='analog2InputType'), piAnalog2SensorsDm);
        const pointMappings = [
            { pointId: entities.find(e => e.domainName === 'pidTargetValue')?._id, key: 'target' },
            { pointId: entities.find(e => e.domainName === 'dynamicTargetValue')?._id, key: 'dynamicValue' },
            { pointId: entities.find(e => e.domainName === 'controlVariable')?._id, key: 'loopOutput' },
            { pointId: entities.find(e => e.domainName === 'processVariable')?._id, key: 'inputValue' }
        ];
        for (const mapping of pointMappings) {
            const entity = entities?.find(e => e?._id === mapping?.pointId);
            if (entity) {
                if (mapping.key === 'target') {
                    piData[mapping?.key] = "Target " + this.sensorType;
                }
                if (mapping?.key === 'inputValue') {
                    piData.inputUnit = entity.unit || '';
                } else if (mapping.key === 'loopOutput') {
                    piData.loopOutput = entity.unit || '';
                } else if ((mapping.key === 'dynamicValue') && this.sensorType2 != null) {
                    piData['dynamicTarget'] = "Dynamic Target Value " + this.sensorType2;
                }
            }
        }
    } catch (error) {
        console.error('Error resolving sensor types:', error);
    }
    return piData;
  }


  // Fetching Modbus Config Points
  getModbusPoints(profileObj, equipName) {
    let isHeartBeat = false;
    let heartBeatPoints = this.helperService.getPointIdbyTags(profileObj, ["heartbeat"], null);
    if (heartBeatPoints.length) isHeartBeat = true;
    if (heartBeatPoints.length > 0) {
      for (let i = 0; i < heartBeatPoints.length; i++) {
        let label = i == 0 && isHeartBeat ? 'lastUpdated' : "modbus" + (i + 1);
        let poinType = this.helperService.pointType(profileObj, heartBeatPoints[i], null, 'writable') ? 'read&hisWrite' : (this.helperService.pointType(profileObj, heartBeatPoints[i], null, 'his') ? 'read&hisRead' : 'read&read');
        this.getZoneSettings(label, [heartBeatPoints[i]], poinType, equipName);
      }
    }
    return profileObj;
  }

  // Fetching Profile Intent
  loadUserIntent(profileType, profileObj, zoneProfileType) {
    let equipName;
    if (this.multiModuleProfile) {
      equipName = profileObj.name
    }
    const certificationLevel = 'L0';
    const domainName = profileObj?.domainName != undefined;
    const isMystat = this.commonMethodService.checkIfMystat(profileObj);
    switch (profileType) {
      case 'vav':
        zoneProfile_vav.forEach((configData: any) => {
          this.isDomainIntegrated = !!profileObj.domainName;

          if (configData.certificationCheck.includes(certificationLevel)) {
            this.getZoneSettings(configData.key, this.helperService.getPointIdbyTags(profileObj, configData.tags, null, configData?.filter, configData?.domainName), configData.action, equipName);
          }
        });
        domainName ? this.getZoneSettings('autoAway', this.helperService.getPointIdbyTags(profileObj, ['auto', 'away', 'enable'], this.refs.roomRef), 'write', equipName) : this.getZoneSettings('autoAway', this.helperService.getPointIdbyTags(profileObj, ['auto', 'away', 'vav'], this.refs.roomRef), 'write', equipName);
        break;

      case 'series':
        zoneProfile_series.forEach((configData: any) => {
          this.isDomainIntegrated = !!profileObj.domainName;

          if (configData.certificationCheck.includes(certificationLevel)) {
            this.getZoneSettings(configData.key, this.helperService.getPointIdbyTags(profileObj, configData.tags, null, configData?.filter, configData?.domainName), configData.action, equipName);
          }
        });
        domainName ? this.getZoneSettings('autoAway', this.helperService.getPointIdbyTags(profileObj, ['auto', 'away', 'enable'], this.refs.roomRef), 'write', equipName) : this.getZoneSettings('autoAway', this.helperService.getPointIdbyTags(profileObj, ['auto', 'away', 'vav'], this.refs.roomRef), 'write', equipName);
        break;

      case 'parallel':
        zoneProfile_parallel.forEach((configData: any) => {
          this.isDomainIntegrated = !!profileObj.domainName;

          if (configData.certificationCheck.includes(certificationLevel)) {
            this.getZoneSettings(configData.key, this.helperService.getPointIdbyTags(profileObj, configData.tags, null, configData?.filter, configData?.domainName), configData.action, equipName);
          }
        });
        domainName ? this.getZoneSettings('autoAway', this.helperService.getPointIdbyTags(profileObj, ['auto', 'away', 'enable'], this.refs.roomRef), 'write', equipName) : this.getZoneSettings('autoAway', this.helperService.getPointIdbyTags(profileObj, ['auto', 'away', 'vav'], this.refs.roomRef), 'write', equipName);
        break;

      case 'dab':
        zoneProfile_dab['userIntent'].forEach((_profileObj: any) => {
          if (_profileObj.certificationCheck.includes(certificationLevel)) {
            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
          }
        });
        break;

      case 'cpu':
        if (zoneProfileType == "hyperstat" || zoneProfileType == "hyperstatsplit") {
          zoneProfile_cpu_hyperstat.forEach((_profileObj: any) => {
            if (_profileObj.certificationCheck.includes(certificationLevel)) {
              this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
            }
          });

          if (zoneProfileType == "hyperstat") {
            this.isDomainIntegrated = !!profileObj.domainName;
            zoneProfile_hyperstatCpu_specific.forEach((_profileObj: any) => {
              if (_profileObj.certificationCheck.includes(certificationLevel)) {
                this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
              }
            });
          } else {
            zoneProfile_hyperstatSplit_specific.forEach((_profileObj: any) => {
              if (_profileObj.certificationCheck.includes(certificationLevel)) {
                this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
              }
            });
          }

        } else {
          zoneProfile_cpu_non_hyperstat.forEach((_profileObj: any) => {
            if (_profileObj.certificationCheck.includes(certificationLevel)) {
              this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
            }
          });
        }
        break;

      case 'hpu':
        if (zoneProfileType == "hyperstat") {
          this.isDomainIntegrated = !!profileObj.domainName;
          zoneProfile_hyperstathpu['userIntent'].forEach((_profileObj: any) => {
            if (_profileObj.certificationCheck.includes(certificationLevel)) {
              this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
            }
          });

        } else {
          zoneProfile_hpu_non_hyperstat.forEach((_profileObj: any) => {
            if (_profileObj.certificationCheck.includes(certificationLevel)) {
              this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
            }
          });
        }
        break;

      case 'pipe2':
        if (zoneProfileType == "hyperstat") {
          this.isDomainIntegrated = !!profileObj.domainName;
          zoneProfile_hs_pipe2['userIntent'].forEach((_profileObj: any) => {
            if (_profileObj.certificationCheck.includes(certificationLevel)) {
              this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
            }
          });

        } else if(isMystat == "mystat") {
          zoneProfile_Mystat_pipe2['userIntent'].forEach((_profileObj: any) => {
            if (_profileObj.certificationCheck.includes(certificationLevel)) {
              this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, null, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
            }
          });
        }
        else {
          ZoneProfile_2pipe_non_hyperstat.forEach((_profileObj: any) => {
            if (_profileObj.certificationCheck.includes(certificationLevel)) {
              this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
            }
          });
        }
        break;

      case 'pipe4':
        zoneProfile_pipe4.forEach((_profileObj: any) => {
          if (_profileObj.certificationCheck.includes(certificationLevel)) {
            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
          }
        });
        break;

      case 'emr':
        equipName = profileObj.name;
        zoneProfile_emr.forEach((_profileObj: any) => {
          if (_profileObj.certificationCheck.includes(certificationLevel)) {
            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
          }
        });
        break;

      case 'pid':
        zoneProfile_pid['userIntent'].forEach((_profileObj: any) => {
          if (_profileObj.certificationCheck.includes(certificationLevel)) {
            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
          }
        });
        break;

      case 'sse':
        zoneProfile_sse['userIntent'].forEach((_profileObj: any) => {
          if (_profileObj.certificationCheck.includes(certificationLevel)) {
            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
          }
        });
        break;

      case 'ti':
        zoneReconfig_ti['userIntent'].forEach((_profileObj: any) => {
          if (_profileObj.certificationCheck.includes(certificationLevel)) {
            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
          }
        });
        break;

      case 'otn':
        zoneProfile_otn['userIntent'].forEach((_profileObj: any) => {
          if (_profileObj.certificationCheck.includes(certificationLevel)) {
            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter,_profileObj?.domainName), _profileObj.action, equipName);
          }
        });
        break;

      case 'bpos':
        zoneProfile_bpos.forEach((_profileObj: any) => {
          if (_profileObj.certificationCheck.includes(certificationLevel)) {
            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter), _profileObj.action, equipName);
          }
        });
        break;

      case 'dualDuct':
        zoneProfile_dualDuct.forEach((_profileObj: any) => {
          if (_profileObj.certificationCheck.includes(certificationLevel)) {
            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
          }
        });
        break;

      case 'vrv':
        zoneProfile_vrv.forEach((_profileObj: any) => {
          if (_profileObj.certificationCheck.includes(certificationLevel)) {
            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter), _profileObj.action, equipName);
          }
        });
        break;

      case 'modbus':
        profileObj = this.getModbusPoints(profileObj, equipName);
        break;

      case 'sense':
        zoneProfile_sense.forEach((_profileObj: any) => {
          if (_profileObj.certificationCheck.includes(certificationLevel)) {
            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
          }
        });
        break;

      case 'monitoring':
        zoneProfile_monitoring['userIntent'].forEach((_profileObj: any) => {
          if (_profileObj.certificationCheck.includes(certificationLevel)) {
            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
          }
        });
        break;

      case 'chilledBeam':
        zoneProfile_chilledBeam['userIntent'].forEach((_profileObj: any) => {
          this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, _profileObj.roomRef, _profileObj?.filter, _profileObj?.domainName), _profileObj.action, equipName);
        });
        break;

      default:
        console.log('This profile is not handled');
        break;
    }
    if (profileType !== 'modbus') {
      this.getZoneSettings('desiredTempHeating', this.helperService.getPointIdbyTags(profileObj, ['temp', 'air', 'desired', 'heating'], null, null, 'desiredTempHeating'), 'write');
      this.getZoneSettings('desiredTempCooling', this.helperService.getPointIdbyTags(profileObj, ['temp', 'air', 'desired', 'cooling'], null, null, 'desiredTempCooling'), 'write');
      this.getZoneSettings('currentTemp', this.helperService.getPointIdbyTags(profileObj, ['current', 'temp'], null, null, 'currentTemp'), 'read', equipName);
      const siteObj = this.getSiteObj(['equip', 'tuner']);
      this.getZoneSettings('zoneHeatingUserLimitMin', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'user', 'limit', 'min', 'schedulable'], this.refs.roomRef), 'write');
      this.getZoneSettings('zoneCoolingUserLimitMax', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'user', 'limit', 'max', 'schedulable'], this.refs.roomRef), 'write');
      this.getZoneSettings('zoneHeatingUserLimitMax', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'user', 'limit', 'max', 'schedulable'], this.refs.roomRef), 'write');
      this.getZoneSettings('zoneCoolingUserLimitMin', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'user', 'limit', 'min', 'schedulable'], this.refs.roomRef), 'write');
      this.getZoneSettings('buildingLimitMin', this.helperService.getPointIdbyTags(siteObj, ['building', 'limit', 'min'], null, ['tuner']), 'write');
      this.getZoneSettings('buildingLimitMax', this.helperService.getPointIdbyTags(siteObj, ['building', 'limit', 'max'], null, ['tuner']), 'write');
      this.getZoneSettings('buildingToZoneDifferential', this.helperService.getPointIdbyTags(siteObj, ['building', 'default', 'differential', 'kind'], null, ['tuner']), 'write');
      this.getZoneSettings('heatingDeadband', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'deadband', 'base', 'schedulable'], this.refs.roomRef,), 'write');
      this.getZoneSettings('coolingDeadband', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'deadband', 'base', 'schedulable'], this.refs.roomRef), 'write');
      this.getZoneSettings('zoneLevelunoccupiedZoneSetback', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['zone', 'setback', 'unoccupied', 'kind', 'schedulable'], this.refs.roomRef), 'write');
      let entities = ArrayUtil.deepFlatten(this.widgetData, 'entities');
      this.getZoneSettings('singleDual', this.helperService.getPointIdbyTags(entities, ['hvacMode'], this.refs.roomRef), 'read');

    }
  }

  // Fetching CPU Config Points
  getHsCpuCTData(profileObj) {
    const hsCpuCTData = {
      analog1Ct: '',
      analog2Ct: ''
    };
    const msPipe2Data = {
        analog1Ct: '',
    }
    let isMystat = this.commonMethodService.checkIfMystat(profileObj)
    if (profileObj) {
        const analog1Ct = this.helperService.getPointIdbyTags(profileObj, ['analog1', 'in', 'current', 'logical'], null);
        const analog2Ct = this.helperService.getPointIdbyTags(profileObj, ['analog2', 'in', 'current', 'logical'], null);
        if (profileObj.entities) {
            if(!isMystat) {
                profileObj.entities.map(entity => {
                    [{
                        pointId: analog1Ct,
                        key: 'analog1Ct'
                    }, {
                        pointId: analog2Ct,
                        key: 'analog2Ct'
                    }].forEach((point) => {
                        if (point.pointId == entity._id) {
                            hsCpuCTData[point.key] = entity.dispName;
                        }
                    });
                });
            }
            else {
                profileObj.entities.map(entity => {
                    [{
                        pointId: analog1Ct,
                        key: 'analog1Ct'
                    }].forEach((point) => {
                        if (point.pointId == entity._id) {
                            msPipe2Data[point.key] = entity.dispName;
                        }
                    });
                });
            }
        }
    }
    return isMystat ? msPipe2Data : hsCpuCTData;
}

  profileByID(index, profile) {
    return profile.profile._id;
  }

  // Fetching PI Config Points
  getPIData(profileObj) {
    const piData = {
      target: '',
      dynamicTarget: '',
      loopOutput: '',
      input: '',
      inputUnit: '',
      outputUnit: ''
    };
    if (profileObj) {
      const target = this.helperService.getPointIdbyTags(profileObj, ['zone', 'pid', 'target', 'config', 'value'], null);
      const dynamicTarget = this.helperService.getPointIdbyTags(profileObj, ['pid', 'dynamic', 'target', 'zone', 'value'], null);
      const input = this.helperService.getPointIdbyTags(profileObj, ['process', 'logical', 'variable'], null);
      const loopOutput = this.helperService.getPointIdbyTags(profileObj, ['control', 'variable'], null);
      if (profileObj.entities) {
        profileObj.entities.map(entity => {
          [{
            pointId: target,
            key: 'target'
          }, {
            pointId: dynamicTarget,
            key: 'dynamicTarget'
          }, {
            pointId: loopOutput,
            key: 'loopOutput'
          }, {
            pointId: input,
            key: 'input'
          }].forEach((point) => {
            if (point.pointId == entity._id) {
              piData[point.key] = entity.dispName;
              if (point.key == 'input') {
                piData.inputUnit = entity.unit;
              } else if (point.key == 'loopOutput') {
                piData.loopOutput = entity.unit;
              } else {
                piData.outputUnit = entity.unit;
              }
            }
          });
        });
      }
    }

    return piData;

  }

  // Fetching Graph Widget For Each Profile
  loadGraphWidget(profileType: string, profileObj: any, tags: any, airFlowEnabled: boolean, heatPumpChangeOverType: string = null, hpuRelay5Config: string = null, cpuRelay6Config: string = null, moduleIdentifier: string = null,istrueCfmControlEnabled: boolean = false,systemOperationModePoint:string = null,calculativeDamperPoint:boolean =false, calculativeReheatPoint:boolean = false, reheatValue:string = null) {
    try {
      this.profiles.map(async profile => {
        if (profile && (profileObj._id === profile.profile._id)) {
          let data = null;
          const mystat = this.commonMethodService.checkIfMystat(profileObj);
          let isMasterControllerEnabled = false;
          let isAirflowDirectionSupportEnabled = false;
          this.zonehasDomainName = profileObj?.markers?.includes('domainName');
          if (profileType == 'pid' && !this.zonehasDomainName ) {
            data = this.getPIData(profileObj);
          } 
          else if(profileType == 'pid' && this.zonehasDomainName) {
            data = await this.getPiDataDM(profileObj);
          }
          else if (profileType == 'cpu' || profileType == 'pipe2' || profileType == 'hpu') {
            this.checkHyperStatProfile = profileObj.markers.find(e => e === 'hyperstat' || e === 'hyperstatsplit');
            if (this.checkHyperStatProfile == 'hyperstat' || this.checkHyperStatProfile == 'hyperstatsplit' || mystat == 'mystat') {
              data = this.getHsCpuCTData(profileObj);
            }
          } else if (profileType == 'sense' || profileType == 'monitoring') {
            this.monitoringProfile = profileType == 'monitoring' ? true : false;
            data = this.getSenseData(profileObj);
          } else if (profileType == 'vrv') {
            const masterControllerMode = this.helperService.getPointIdbyTags(profileObj, ['masterController', 'vrv']);
            const airflowDirectionSupportCapability = this.helperService.getPointIdbyTags(profileObj, ['airflowDirection', 'vrv', 'support']);
            if (masterControllerMode.length > 0) {
              await this.apiService.getWritablePointData(masterControllerMode[0]).subscribe((response) => {
                let masterControllerModeValue = response.rows[0];
                if (masterControllerModeValue && this.helperService.stripHaystackTypeMapping(masterControllerModeValue.val).split(' ')[0] == 1) {
                  isMasterControllerEnabled = true;
                  profile.widgets = this.deviceHelper.getDeviceWidgets(profileType, this.refs.siteRef, this.refs.ahuRef, airFlowEnabled, heatPumpChangeOverType, hpuRelay5Config, cpuRelay6Config, moduleIdentifier, data, isMasterControllerEnabled, isAirflowDirectionSupportEnabled, false, true, this.checkHyperStatProfile,systemOperationModePoint,calculativeDamperPoint, calculativeReheatPoint, reheatValue,this.zonehasDomainName);
                }
              });
            }
            if (airflowDirectionSupportCapability.length > 0) {
              await this.siteService.getReadByIdManyForQRCode([airflowDirectionSupportCapability[0]],this.qrCodeMetaData).subscribe((response) => {
                let airflowDirectionSupportCapabilityValue = response.rows[0];
                if (airflowDirectionSupportCapabilityValue && this.helperService.stripHaystackTypeMapping(airflowDirectionSupportCapabilityValue.val).split(' ')[0] == 1) {
                  isAirflowDirectionSupportEnabled = true;
                  profile.widgets = this.deviceHelper.getDeviceWidgets(profileType, this.refs.siteRef, this.refs.ahuRef, airFlowEnabled, heatPumpChangeOverType, hpuRelay5Config, cpuRelay6Config, moduleIdentifier, data, isMasterControllerEnabled, isAirflowDirectionSupportEnabled, false, true, this.checkHyperStatProfile,systemOperationModePoint,calculativeDamperPoint, calculativeReheatPoint, reheatValue,this.zonehasDomainName);
                }
              });
            }

          }
          profile.widgets = this.deviceHelper.getDeviceWidgets(profileType, this.refs.siteRef, this.refs.ahuRef, airFlowEnabled, heatPumpChangeOverType, hpuRelay5Config, cpuRelay6Config, moduleIdentifier, data, isMasterControllerEnabled, isAirflowDirectionSupportEnabled, istrueCfmControlEnabled, true, this.checkHyperStatProfile,systemOperationModePoint,calculativeDamperPoint, calculativeReheatPoint, reheatValue,this.zonehasDomainName,mystat);
          // Only show runtime for first module in case of multimodule profile
          if (this.runTimeGraphsService.isRuntimeRendered && this.runTimeGraphsService.moduleWithRuntime == profile.portNum) {
            const subs_sysProfile = this.deviceHelper.systemProfileSubject.subscribe(systemProfile => {
              // Modify the runtime graph object
              if (systemProfile) {
                if (systemProfile.ccuRef != this.refs.ahuRef) {
                  return
                }
                // Runtime graphs logic
                if (this.runTimeGraphsService.runTimeProfile.size > 0 && this.runTimeGraphsService.runTimeProfile.has(this.refs.ahuRef)) {
                  profile.widgets[0] = this.runTimeGraphsService.runTimeProfile.get(this.refs.ahuRef);
                  this.loadRuntimeWidgetData(this.refs.ahuRef);
                } else {
                  const subs_runtimeSub = this.deviceHelper.systemRuntimeSubject.subscribe(runtimeProfile => {
                    if (runtimeProfile) {
                      // We can directly used after fetching once
                      this.runTimeGraphsService.runTimeProfile.set(runtimeProfile.ahuRef, runtimeProfile.widgetData);
                      profile.widgets[0] = runtimeProfile.widgetData;
                      this.loadRuntimeWidgetData(runtimeProfile.ahuRef);
                      subs_runtimeSub.unsubscribe();
                      subs_sysProfile.unsubscribe();
                    }
                  });

                  if (this.checkForAdvanceAhuProfiles.indexOf(systemProfile.systemProfileName) > -1) {
                    (async () => {
                      try {
                        const staticPreesurePoint = await this.toogleCheckforStaticPressurePoint();
                        const co2BasedDamperControlOn = await this.co2BasedDamperControlOn();
                        this.deviceHelper.getSystemProfileRuntimeWidgetData(
                          systemProfile.systemProfileName,
                          this.refs.siteRef,
                          this.refs.ahuRef,
                          false,
                          this.refs.gateway,
                          this.refs.ccuId,
                          this.refs.ccuName,
                          false,
                          staticPreesurePoint,
                          co2BasedDamperControlOn
                        );
                      } catch (error) {
                        console.error("Error:", error);
                      }
                    })();
                  } else {
                    this.deviceHelper.getSystemProfileRuntimeWidgetData(systemProfile.systemProfileName, this.refs.siteRef, this.refs.ahuRef, false, this.refs.gateway, this.refs.ccuId, this.refs.ccuName, false, null, null);
                  }
                }
                if (systemProfile.systemProfileName.includes('DEFAULT')) {
                  this.disableAllInputs();
                  // for defualt profile remove comfortindex and modes widget
                  this.systemWidgets = this.systemWidgets?.filter(widget => widget.id != 'comfortIndex');
                  this.systemWidgets = this.systemWidgets?.filter(widget => widget.id != 'modes');
                  profile.widgets = profile?.widgets?.filter(widget => widget.id != 'runtimesystemprofile');
                }
              } else {
                // means no runtime for this profile , fetch the data
                // All points have been fetched , get bulk read

                this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange);
              }
            });
          }
        }
      });

      this.loadWidgetData(profileType, profileObj, moduleIdentifier);
      // For profiles without runtime , fetch the data
      if (!this.multiModuleProfile && this.runTimeGraphsService.moduleWithRuntime == null) {
        // All points have been fetched , get bulk read
        this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange);
      }
    } catch (error) {
      console.log(error);
    }
  }

  disableAllInputs() {
    this.onSystemMode = true;
  }

  loadRuntimeWidgetData(equipRef: string) {
    if (this.runTimeGraphsService.cmBoardPortsMappingsForCollection.get(equipRef)) {
      this.runTimeGraphsService.cmBoardPortsMappingsForCollection.get(equipRef).forEach((value, key) => {
        this.getPointId(key, value);
      });
    }
    if (this.runTimeGraphsService.cmBoardPortsMappingsCollection.get(equipRef)) {
      this.runTimeGraphsService.cmBoardPortsMappingsCollection.get(equipRef).forEach((value, key) => {
        if (value.isEnabled) {
          this.getPointId(value.param, value.ref);
        }
      });
    }

    if (this.runTimeGraphsService.fullyModulatingProfileTagsCollection.get(equipRef)) {
      this.runTimeGraphsService.fullyModulatingProfileTagsCollection.get(equipRef).forEach((value, key) => {
        if (value.isEnabled) {
          this.getPointId(value.param, value.ref);
        }
      });
    }

    // All points have been fetched , get bulk read
    this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange);

    // Fetch the properties
    const btuObj = this.getBtuObj(['btu', 'equip', 'profile']);
    const equipObj = this.getEquip(['system', 'equip', 'profile']) || [];
    this.getPointProperties(equipObj);
    this.getPointProperties(btuObj);
  }


  // Fetching Widget Data
  loadWidgetData(profileType: string, profileObj: any, moduleIdentifier: string) {
    const hyperStatProfileName = this.profileObj?.profile?.markers?.find(e => e === 'hyperstat' || e === 'hyperstatsplit');
    const myStatProfile = this.commonMethodService.checkIfMystat(profileObj);
    const siteObj = this.getSiteObj(['tuner', 'equip']);
    const diagnosticObj = this.getDiagObj(['equip', 'diag'], true);
    if (moduleIdentifier) {
      switch (profileType) {
        case 'vav':
        case 'series':
        case 'parallel':
          this.getCommonPoints(moduleIdentifier, siteObj);
          profileTags['vav_terminal']['widgets'].forEach((p) => {
            this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null, null, p?.domainName)[0]);
          })
          break;

        case 'dab':
          this.getCommonPoints(moduleIdentifier, siteObj);
          zoneProfile_dab['widgets'].forEach((p) => {
            this.getPointId(p.key + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null, null, p?.domainName)[0]);
          })
          break;

        case 'cpu':
          if (hyperStatProfileName == "hyperstat" || hyperStatProfileName == "hyperstatsplit") {
            this.getCommonPoints(moduleIdentifier, siteObj);
            this.getPointId('scheduleType' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'logical', 'scheduleType'], this.refs.roomRef, null, 'scheduleType')[0]);
            zoneProfile_hsplit_cpu_widgets.forEach((p) => {
              this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, this.refs.roomRef, null, p?.domainName)[0]);
            })
            if (hyperStatProfileName == "hyperstat") {
              this.getPointId('modulatingDamperAnalog1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['damper', 'dcv', 'analog'], this.refs.roomRef,null,'dcvDamper')[0])
              this.getPointId('modulatingFanSpeedAnalog1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['output', 'fan', 'logical', 'cmd', 'analog'], this.refs.roomRef,null,'linearFanSpeed')[0]);
              this.getPointId('modulatingFanSpeedAnalog1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['output', 'fan', 'logical', 'cmd', 'analog'], this.refs.roomRef,null,'stagedFanSpeed')[0]);
            } else {
              this.getPointId('modulatingDamperAnalog1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['damper', 'analog', 'economizer', 'output'], this.refs.roomRef, null, 'oaoDamper')[0]);
            }
            /**Old Hyper stat cpu code */
          } else {
            this.getCommonPoints(moduleIdentifier, siteObj);
            this.getPointId('currentTemp' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'cpu', 'temp'], null)[0]);
            this.getPointId('desiredTempHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'cpu', 'temp', 'heating'], null)[0]);
            this.getPointId('desiredTempCooling' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'cpu', 'temp', 'cooling'], null)[0]);
            this.getPointId('scheduleType' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cpu', 'scheduleType'], null)[0]);
            this.getPointId('condfanLow' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'cpu', 'stage1', 'cmd'], null)[0]);
            this.getPointId('condfanHigh' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'cpu', 'stage2', 'cmd'], null)[0]);
            this.getPointId('cpuHumidifier' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'standalone', 'logical', 'cpu', 'humidifier'], null)[0]);
            this.getPointId('cpuDehumidifier' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'standalone', 'logical', 'cpu', 'dehumidifier'], null)[0]);
            this.getPointId('condheatingStage1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'heating', 'cpu', 'stage1'], null)[0]);
            this.getPointId('condheatingStage2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'heating', 'cpu', 'stage2'], null)[0]);
            this.getPointId('condcoolingStage1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cooling', 'cpu', 'stage1'], null)[0]);
            this.getPointId('condcoolingStage2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cooling', 'cpu', 'stage2'], null)[0]);
            this.getPointId('condheatingStage3' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'heating', 'cpu', 'stage3'], null)[0]);
            this.getPointId('condcoolingStage3' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cooling', 'cpu', 'stage3'], null)[0]);
            this.getPointId('airflowTempSensorTh1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cpu', 'air', 'temp', 'th1'], null)[0]);
            this.getPointId('FanModeCpuHpu' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cpu', 'fan', 'mode'], null)[0]);
            this.getPointId('ZoneOperationalMode' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'operating', 'mode', 'temp'], null)[0]);
            this.getPointId('ZoneConditionMode' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cpu', 'conditioning', 'mode'], null)[0]);
            this.getPointId('zoneOccupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode'], null)[0]);
            this.getPointId('occupancyDetection' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'zone', 'sensor', 'logical'], null)[0]);
            this.getPointId('autoAway' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['cpu', 'auto', 'away', 'config'], null)[0]);
            this.getPointId('autoForcedOccupied' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['cpu', 'auto', 'forced'], null)[0]);
            this.getPointId('pm10' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm10', 'sensor'], null)[0]);
            this.getPointId('pm2p5' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm2p5', 'sensor'], null)[0]);
            this.getPointId('Humidity' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['humidity', 'sensor', 'cur'], null)[0]);
            this.getPointId('CO2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'sensor', 'cur'], null)[0]);
            this.getPointId('CO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co', 'sensor', 'cur'], null)[0]);
            this.getPointId('VOC' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'sensor', 'cur'], null)[0]);
            this.getPointId('Pressure' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pressure', 'sensor', 'cur'], null)[0]);
            this.getPointId('sound' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sound', 'sensor', 'cur'], null)[0]);
            this.getPointId('Illuminance' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['illuminance', 'sensor', 'cur'], null)[0]);
            this.getPointId('UVI' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
            this.getPointId('UVI' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
            this.getPointId('NO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['no', 'sensor', 'cur'], null)[0]);
            this.getPointId('Occupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'sensor', 'cur'], null)[0]);
            this.getPointId('CO2Equivalent' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2Equivalent', 'sensor', 'cur'], null)[0]);
          }
          break;

        case 'cpuecon':
          profileTags['hs_cpu_cpuecon']['widgets'].forEach((p) => {
            this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null)[0]);
          })
          this.getPointId('heatingUserLimitMax' + moduleIdentifier, this.helperService.getPointIdbyTags(siteObj, ['sp', 'zone', 'limit', 'max', 'heating', 'default'])[0]);
          this.getPointId('coolingUserLimitMin' + moduleIdentifier, this.helperService.getPointIdbyTags(siteObj, ['sp', 'zone', 'limit', 'min', 'cooling', 'default'])[0]);
          this.getPointId('scheduleType' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'logical', 'scheduleType'], null)[0]);
          this.getPointId('coolingUserLimitMax' + moduleIdentifier, this.helperService.getPointIdbyTags(siteObj, ['sp', 'zone', 'limit', 'max', 'cooling', 'default'])[0]);
          this.getPointId('heatingUserLimitMin' + moduleIdentifier, this.helperService.getPointIdbyTags(siteObj, ['sp', 'zone', 'limit', 'min', 'heating', 'default'])[0]);
          break;
        case 'hpu':
          this.getCommonPoints(moduleIdentifier, siteObj);
          this.getPointId('hpuOperatingMode' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'operating', 'mode'], null)[0]);
          if (hyperStatProfileName == "hyperstat") {
            zoneProfile_hyperstathpu['widgets'].forEach((p) => {
              if (p.name == 'voc' || p.name == "co2" || p.name == "pm2p5") {
                this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null, ['config'], p?.domainName)[0]);
              } else {
                this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null, null, p?.domainName)[0]);
              }
            })
          } else {
            this.getPointId('currentTemp' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'hpu', 'temp'], null)[0]);
            this.getPointId('desiredTempHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'hpu', 'temp', 'heating'], null)[0]);
            this.getPointId('desiredTempCooling' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'hpu', 'temp', 'cooling'], null)[0]);

            this.getPointId('scheduleType' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'scheduleType'], null)[0]);
            this.getPointId('occupancyDetection' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'zone', 'sensor', 'logical'], null)[0]);
            this.getPointId('compressorStage1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'compressor', 'stage1'], null)[0]);
            this.getPointId('compressorStage2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'compressor', 'stage2'], null)[0]);
            this.getPointId('fanLow' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'hpu', 'stage1'], null)[0]);
            this.getPointId('auxHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'aux', 'heating'], null)[0]);
            this.getPointId('hpuOperatingMode' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'operating', 'mode', 'temp'], null)[0]);
            this.getPointId('heatpumpChangeoverCooling' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'heatpump', 'changeover', 'cooling'], null)[0]);
            this.getPointId('heatpumpChangeoverHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'heatpump', 'changeover', 'heating'], null)[0]);
            this.getPointId('fanHigh' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'standalone', 'logical', 'fan', 'hpu', 'stage2'], null)[0]);
            this.getPointId('hpuHumidifier' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'standalone', 'logical', 'hpu', 'humidifier'], null)[0]);
            this.getPointId('hpuDehumidifier' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'standalone', 'logical', 'hpu', 'dehumidifier'], null)[0]);
            this.getPointId('airflowTempSensorTh1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'air', 'temp', 'th1'], null)[0]);
            this.getPointId('FanModeCpuHpu' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'fan', 'mode'], null)[0]);
            this.getPointId('ZoneConditionMode' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'conditioning', 'mode'], null)[0]);
            this.getPointId('zoneOccupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode'], null)[0]);
            this.getPointId('autoAway' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['hpu', 'auto', 'away', 'config'], null)[0]);
            this.getPointId('autoForcedOccupied' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['hpu', 'auto', 'forced'], null)[0]);
            this.getPointId('Humidity' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['humidity', 'sensor', 'cur'], null)[0]);
            this.getPointId('CO2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'sensor', 'cur'], null)[0]);
            this.getPointId('CO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co', 'sensor', 'cur'], null)[0]);
            this.getPointId('NO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['no', 'sensor', 'cur'], null)[0]);
            this.getPointId('VOC' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'sensor', 'cur'], null)[0]);
            this.getPointId('Pressure' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pressure', 'sensor', 'cur'], null)[0]);
            this.getPointId('Occupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'sensor', 'cur'], null)[0]);
            this.getPointId('sound' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sound', 'sensor', 'cur'], null)[0]);
            this.getPointId('CO2Equivalent' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2Equivalent', 'sensor', 'cur'], null)[0]);
            this.getPointId('Illuminance' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['illuminance', 'sensor', 'cur'], null)[0]);
            this.getPointId('UVI' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
            this.getPointId('UVI' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
            this.getPointId('pm10' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm10', 'sensor'], null)[0]);
            this.getPointId('pm2p5' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm2p5', 'sensor'], null)[0]);
          }
          break;
        case 'pipe2':
          this.getCommonPoints(moduleIdentifier, siteObj);

          if (hyperStatProfileName == "hyperstat") {
            zoneProfile_hs_pipe2['widgets'].forEach((p) => {
              if (p.name == 'voc' || p.name == "co2" || p.name == "pm2p5") {
                this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null, ['config'], p?.domainName)[0]);
              } else {
                this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null,null,p?.domainName)[0]);
              }
            })
          } else if(myStatProfile) {
            zoneProfile_Mystat_pipe2['widgets'].forEach((p) => {
              this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, null, null, null, p.domainName)[0]);
            })
          }
           else {
            this.getPointId('currentTemp' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'pipe2', 'temp'], null)[0]);
            this.getPointId('desiredTempHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'pipe2', 'temp', 'heating'], null)[0]);
            this.getPointId('desiredTempCooling' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'pipe2', 'temp', 'cooling'], null)[0]);
            this.getPointId('scheduleType' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe2', 'scheduleType'], null)[0]);
            this.getPointId('occupancyDetection' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'zone', 'sensor', 'logical'], null)[0]);
            this.getPointId('fanLow' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'fcu', 'pipe2', 'low'], null)[0]);
            this.getPointId('fanMedium' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'fcu', 'pipe2', 'medium'], null)[0]);
            this.getPointId('fanHigh' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'fcu', 'pipe2', 'high'], null)[0]);
            this.getPointId('auxHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fcu', 'pipe2', 'aux', 'heating'], null)[0]);
            this.getPointId('waterValve' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'water', 'fcu', 'pipe2', 'valve'], null)[0]);
            this.getPointId('airflowTempSensorTh1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe2', 'air', 'temp', 'th1'], null)[0]);
            this.getPointId('SupplyWaterTemperature' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe2', 'water', 'temp', 'th2'], null)[0]);
            this.getPointId('FanModeFCU' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe2', 'fan', 'mode'], null)[0]);
            this.getPointId('ZoneOperationalMode' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe2', 'operating', 'mode', 'temp'], null)[0]);
            this.getPointId('ZoneConditionMode' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe2', 'conditioning', 'mode'], null)[0]);
            this.getPointId('zoneOccupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode'], null)[0]);
            this.getPointId('autoAway' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pipe2', 'auto', 'away', 'config'], null)[0]);
            this.getPointId('autoForcedOccupied' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pipe2', 'auto', 'forced'], null)[0]);
            this.getPointId('Humidity' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['humidity', 'sensor', 'cur'], null)[0]);
            this.getPointId('CO2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'sensor', 'cur'], null)[0]);
            this.getPointId('CO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co', 'sensor', 'cur'], null)[0]);
            this.getPointId('NO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['no', 'sensor', 'cur'], null)[0]);
            this.getPointId('VOC' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'sensor', 'cur'], null)[0]);
            this.getPointId('Pressure' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pressure', 'sensor', 'cur'], null)[0]);
            this.getPointId('Occupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'sensor', 'cur'], null)[0]);
            this.getPointId('sound' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sound', 'sensor', 'cur'], null)[0]);
            this.getPointId('CO2Equivalent' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2Equivalent', 'sensor', 'cur'], null)[0]);
            this.getPointId('Illuminance' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['illuminance', 'sensor', 'cur'], null)[0]);
            this.getPointId('UVI' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
            this.getPointId('UVI' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
            this.getPointId('pm10' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm10', 'sensor'], null)[0]);
            this.getPointId('pm2p5' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm2p5', 'sensor'], null)[0]);
          }
          break;

        case 'pipe4':
          this.getCommonPoints(moduleIdentifier, siteObj);
          this.getPointId('currentTemp' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'pipe4', 'temp'], null)[0]);
          this.getPointId('desiredTempHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'pipe4', 'temp', 'heating'], null)[0]);
          this.getPointId('desiredTempCooling' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'pipe4', 'temp', 'cooling'], null)[0]);

          this.getPointId('scheduleType' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe4', 'scheduleType'], null)[0]);
          this.getPointId('occupancyDetection' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'zone', 'sensor', 'logical'], null)[0]);
          this.getPointId('fanLow' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'fcu', 'pipe4', 'low'], null)[0]);
          this.getPointId('fanMedium' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'fcu', 'pipe4', 'medium'], null)[0]);
          this.getPointId('fanHigh' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'fcu', 'pipe4', 'high'], null)[0]);
          this.getPointId('heatingValve' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'water', 'heating', 'pipe4', 'valve'], null)[0]);
          this.getPointId('coolingValve' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'water', 'cooling', 'pipe4', 'valve'], null)[0]);
          this.getPointId('auxHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fcu', 'pipe4', 'aux', 'heating'], null)[0]);
          this.getPointId('waterValve' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'water', 'fcu', 'pipe4', 'valve'], null)[0]);
          this.getPointId('airflowTempSensorTh1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe4', 'air', 'temp', 'th1'], null)[0]);
          this.getPointId('FanModeFCU' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe4', 'fan', 'mode'], null)[0]);
          this.getPointId('ZoneOperationalMode' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe4', 'operating', 'mode', 'temp'], null)[0]);
          this.getPointId('ZoneConditionMode' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe4', 'conditioning', 'mode'], null)[0]);
          this.getPointId('zoneOccupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode'], null)[0]);
          this.getPointId('autoAway' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pipe4', 'auto', 'away', 'config'], null)[0]);
          this.getPointId('autoForcedOccupied' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pipe4', 'auto', 'forced'], null)[0]);
          this.getPointId('Humidity' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['humidity', 'sensor', 'cur'], null)[0]);
          this.getPointId('CO2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'sensor', 'cur'], null)[0]);
          this.getPointId('CO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co', 'sensor', 'cur'], null)[0]);
          this.getPointId('NO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['no', 'sensor', 'cur'], null)[0]);
          this.getPointId('VOC' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'sensor', 'cur'], null)[0]);
          this.getPointId('Pressure' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pressure', 'sensor', 'cur'], null)[0]);
          this.getPointId('Occupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'sensor', 'cur'], null)[0]);
          this.getPointId('sound' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sound', 'sensor', 'cur'], null)[0]);
          this.getPointId('CO2Equivalent' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2Equivalent', 'sensor', 'cur'], null)[0]);
          this.getPointId('Illuminance' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['illuminance', 'sensor', 'cur'], null)[0]);
          this.getPointId('UVI' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
          this.getPointId('UVI' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
          this.getPointId('pm10' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm10', 'sensor'], null)[0]);
          this.getPointId('pm2p5' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm2p5', 'sensor'], null)[0]);
          break;

        case 'emr':
          this.getPointId('currentRate' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'current', 'rate'], null)[0]);
          this.getPointId('energyMeterReading' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'emr', 'cur', 'sp'], null)[0]);
          break;

        case 'pid':
          const widgets = this.zonehasDomainName ? zoneProfile_pid['widgets'] : zoneProfile_pid['nonDmWidgets']
          widgets.forEach((p) => {
              this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, null, null, null, p?.domainName)[0]);
          })
          break;

        case 'sse':
          this.getCommonPoints(moduleIdentifier, siteObj);
          zoneProfile_sse['widgets'].forEach((p) => {
            this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null, null, p?.domainName)[0]);
          })
          break;

        case 'ti':
          this.getCommonPoints(moduleIdentifier, siteObj);
          zoneReconfig_ti['widgets'].forEach((p) => {
            this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null, null, p?.domainName)[0]);
          });
          break;

        case 'otn':
          this.getCommonPoints(moduleIdentifier, siteObj);
          zoneProfile_otn['widgets'].forEach((p: any) => {
            this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null, null, p?.domainName)[0]);
          });
          break;

        case 'dualDuct':
          this.getCommonPoints(moduleIdentifier, siteObj);
          this.getPointId('currentTemp' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'dualDuct', 'temp'], null)[0]);
          this.getPointId('desiredTempHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'dualDuct', 'temp', 'heating'], null)[0]);
          this.getPointId('desiredTempCooling' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'dualDuct', 'temp', 'cooling'], null)[0]);
          this.getPointId('zoneOccupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode', 'dualDuct'], null)[0]);
          this.getPointId('humidity' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['humidity', 'dualDuct'], null)[0]);
          this.getPointId('targetMinInsideHumidty' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['target', 'dualDuct', 'min', 'humidity', 'inside'], null)[0]);
          this.getPointId('targetMaxInsideHumidty' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['target', 'dualDuct', 'max', 'humidity', 'inside'], null)[0]);
          this.getPointId('co2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'dualDuct', 'air'], null)[0]);
          this.getPointId('zoneCO2Target' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'dualDuct', 'target'], null)[0]);
          this.getPointId('voc' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'dualDuct', 'air'], null)[0]);
          this.getPointId('zoneVOCTarget' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'dualDuct', 'target'], null)[0]);

          this.getPointId('enteringAirTemp' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['temp', 'dualDuct', 'air', 'entering', 'sensor'], null)[0]);
          this.getPointId('dischargeAirTemp' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['temp', 'dualDuct', 'air', 'discharge', 'sensor'], null)[0]);
          this.getPointId('scheduleType' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'dualDuct', 'scheduleType'], null)[0]);
          this.getPointId('maxHeatingDamperPos' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['max', 'dualDuct', 'heating', 'damper', 'limit'], null)[0]);
          this.getPointId('maxCoolingDamperPos' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['max', 'dualDuct', 'cooling', 'damper', 'limit'], null)[0]);
          this.getPointId('minHeatingDamperPos' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['min', 'dualDuct', 'heating', 'damper', 'limit'], null)[0]);
          this.getPointId('minCoolingDamperPos' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['min', 'dualDuct', 'cooling', 'damper', 'limit'], null)[0]);
          this.getPointId('coolingDamperPos' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['cooling', 'damper', 'zone', 'point', 'dualDuct', 'logical'], null)[0]);
          this.getPointId('heatingDamperPos' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['heating', 'damper', 'zone', 'point', 'dualDuct', 'logical'], null)[0]);
          this.getPointId('coolingSupplyAirflow' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['cooling', 'supply', 'zone', 'point', 'dualDuct', 'logical'], null)[0]);
          this.getPointId('heatingSupplyAirflow' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['heating', 'supply', 'zone', 'point', 'dualDuct', 'logical'], null)[0]);
          this.getPointId('autoAway' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['auto', 'dualDuct', 'away', 'config'], null)[0]);
          this.getPointId('autoForcedOccupied' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['dualDuct', 'auto', 'forced'], null)[0]);
          this.getPointId('reheatPosition' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['reheat', 'dualDuct', 'valve'], null)[0]);
          this.getPointId('zonePriority' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'dynamic', 'priority'], null)[0]);
          this.getPointId('occupancyDetection' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'detection', 'zone'], null)[0]);
          this.getPointId('CO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co', 'sensor', 'cur'], null)[0]);
          this.getPointId('NO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['no', 'sensor', 'cur'], null)[0]);
          this.getPointId('Pressure' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pressure', 'sensor', 'cur'], null)[0]);
          this.getPointId('Occupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'sensor', 'cur'], null)[0]);
          this.getPointId('sound' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sound', 'sensor', 'cur'], null)[0]);
          this.getPointId('CO2Equivalent' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2Equivalent', 'sensor', 'cur'], null)[0]);
          this.getPointId('Illuminance' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['illuminance', 'sensor', 'cur'], null)[0]);
          this.getPointId('UVI' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
          break;

        case 'modbus':
          break;

        case 'sense':
          this.getPointId('currentTemp' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'sense', 'temp'], null)[0]);
          this.getPointId('co2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'sensor', 'sense'], null)[0]);
          this.getPointId('humidity' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['humidity', 'sense', 'sensor'], null)[0]);
          this.getPointId('voc' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'sensor', 'sense'], null)[0]);
          this.getPointId('th1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sense', 'th1', 'logical'], null)[0]);
          this.getPointId('th2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sense', 'th2', 'logical'], null)[0]);
          this.getPointId('analog1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sense', 'analog1', 'logical'], null)[0]);
          this.getPointId('analog2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sense', 'analog2', 'logical'], null)[0]);
          this.getPointId('pm10' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm10', 'sensor', 'sense'], null)[0]);
          this.getPointId('pm2p5' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm2p5', 'sensor', 'sense'], null)[0]);
          this.getPointId('Humidity' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['humidity', 'sensor', 'cur'], null)[0]);
          this.getPointId('CO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co', 'sensor', 'cur'], null)[0]);
          this.getPointId('NO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['no', 'sensor', 'cur'], null)[0]);
          this.getPointId('Pressure' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pressure', 'sensor', 'cur'], null)[0]);
          this.getPointId('Occupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'sensor', 'cur'], null)[0]);
          this.getPointId('sound' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sound', 'sensor', 'cur'], null)[0]);
          this.getPointId('CO2Equivalent' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2Equivalent', 'sensor', 'cur'], null)[0]);
          this.getPointId('Illuminance' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['illuminance', 'sensor', 'cur'], null)[0]);
          this.getPointId('UVI' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
          break;

        case 'monitoring':
          zoneProfile_monitoring['widgets'].forEach((p) => {
            this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null, null, p?.domainName)[0]);
          });
          break;
          break;

        case 'vrv':
          this.getCommonPoints(moduleIdentifier, siteObj);
          this.getPointId("currentTemp" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'vrv', 'temp'], null)[0]);
          this.getPointId('desiredTempHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'vrv', 'temp', 'heating'], null)[0]);
          this.getPointId('desiredTempCooling' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'vrv', 'temp', 'cooling'], null)[0]);

          this.getPointId("vrvHumidity" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['humidity', 'vrv', 'sensor'], null)[0]);
          this.getPointId("fanSpeed" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['fanSpeed', 'vrv', 'enum'], null)[0]);
          this.getPointId("airflowDirection" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['airflowDirection', 'vrv', 'enum'], null)[0]);
          this.getPointId("filterStatus" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['filterStatus', 'vrv'], null)[0]);
          this.getPointId("coolHeatRight" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['coolHeatRight', 'vrv'], null)[0]);
          this.getPointId("operationMode" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['operation', 'mode', 'enum', 'vrv'], null)[0]);
          this.getPointId("masterOperationMode" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['operation', 'mode', 'master', 'vrv'], null)[0]);
          this.getPointId("masterControllerMode" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['masterController', 'vrv'], null)[0]);
          this.getPointId("airflowDirectionSupportCapability" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['airflowDirection', 'vrv', 'support'], null)[0]);
          this.getPointId('scheduleType' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'vrv', 'scheduleType'], null)[0]);
          this.getPointId('zoneOccupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode', 'vrv'], null)[0]);
          this.getPointId('testOperation' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['vrv', 'testOperation'], null)[0]);
          this.getPointId('telcoCheck' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['telecoCheck', 'vrv'], null)[0]);
          this.getPointId('vrvTh1Sensor' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['vrv', 'th1', 'sensor'], null)[0]);
          this.getPointId('vrvTh2Sensor' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['vrv', 'th2', 'sensor'], null)[0]);
          this.getPointId('vrvTh3Sensor' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['vrv', 'th3', 'sensor'], null)[0]);
          this.getPointId('vrvTh4Sensor' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['vrv', 'th4', 'sensor'], null)[0]);
          this.getPointId('vrvTh5Sensor' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['vrv', 'th5', 'sensor'], null)[0]);
          this.getPointId('vrvTh6Sensor' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['vrv', 'th6', 'sensor'], null)[0]);
          this.getPointId('CO2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'sensor', 'cur'], null)[0]);
          this.getPointId('CO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co', 'sensor', 'cur'], null)[0]);
          this.getPointId('NO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['no', 'sensor', 'cur'], null)[0]);
          this.getPointId('VOC' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'sensor', 'cur'], null)[0]);
          this.getPointId('Pressure' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pressure', 'sensor', 'cur'], null)[0]);
          this.getPointId('Occupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'sensor', 'cur'], null)[0]);
          this.getPointId('sound' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sound', 'sensor', 'cur'], null)[0]);
          this.getPointId('Illuminance' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['illuminance', 'sensor', 'cur'], null)[0]);
          this.getPointId('UVI' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
          this.getPointId('pm10' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm10', 'sensor'], null)[0]);
          this.getPointId('pm2p5' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm2p5', 'sensor'], null)[0]);
          break;

        case 'chilledBeam':
          this.getCommonPoints(moduleIdentifier, siteObj);
          zoneProfile_chilledBeam['widgets'].forEach((p) => {
            this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null, null, p.domainName)[0]);
          })
          break;

        default:
          console.log('This profile is not handled');
          break;
      }

      this.getPointId('zoneequipmentConnected' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], null)[0]);
      if (diagnosticObj)
        this.getPointId('cloudConnected' + moduleIdentifier, this.helperService.getPointIdbyTags(diagnosticObj, ['cloud', 'diag', 'connected'])[0]);
      if(profileType == 'pid' && this.zonehasDomainName) {
        const checkDomainNameMatchfor1 = (row) => {
            return this.piAnalogSensorsDmDomainNames?.includes(row?.domainName) ||
                   this.thermistorSensorDmDomainNames?.includes(row?.domainName) ||
                   this.nativeSensorDMDomainNames?.includes(row?.domainName);
          };
          const checkDomainNameMatchfor2 = (row) => {
            return this.piAnalog2SensorsDmDomainNames?.includes(row?.domainName);
          };
        const matchingRows1 = profileObj?.entities?.filter(checkDomainNameMatchfor1);
        const matchingRows2 = profileObj?.entities?.filter(checkDomainNameMatchfor2);
        let target = profileObj?.entities?.filter(item => item?.domainName == 'pidTargetValue');
        let dynamicTargetValue = profileObj?.entities?.filter(item => item?.domainName == 'dynamicTargetValue');
        if(target?.length>0 && matchingRows1?.length > 0){
            profileObj.entities.filter(item => item.domainName == 'pidTargetValue')[0].unit = matchingRows1[0]?.unit;
        }
        if(dynamicTargetValue?.length>0 && matchingRows2?.length>0){
            profileObj.entities.filter(item => item.domainName == 'dynamicTargetValue')[0].unit = matchingRows2[0]?.unit;
        }
    }
      // Set the properties
      this.getPointProperties(profileObj);
      this.getPointProperties(siteObj);
    } else {
      // not yet ready with all data
    }
  }

  // this will fill in details common points
  getCommonPoints(moduleIdentifier, siteObj) {
    this.getPointId('heatingUserLimitMin' + moduleIdentifier, this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'user', 'limit', 'min', 'schedulable'], this.refs.roomRef)[0]);
    this.getPointId('coolingUserLimitMax' + moduleIdentifier, this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'user', 'limit', 'max', 'schedulable'], this.refs.roomRef)[0]);
    this.getPointId('heatingUserLimitMax' + moduleIdentifier, this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'user', 'limit', 'max', 'schedulable'], this.refs.roomRef)[0]);
    this.getPointId('coolingUserLimitMin' + moduleIdentifier, this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'user', 'limit', 'min', 'schedulable'], this.refs.roomRef)[0]);
  }

  // this will get the point id and store it in the collection
  getPointId(key: string, pointRef: string) {
    const id: Array<string> = new Array<string>();
    if (!pointRef) return;
    // change the check from key -> pointRef by kuladeep 3.11.19
    if (this.graphService.graphPointsCollection.has(key + pointRef)) {
      id.push(this.graphService.graphPointsCollection.get(key + pointRef));
    } else {
      this.graphService.graphPointsCollection.set(key + pointRef, pointRef);
      id.push(pointRef);
    }

    return id;
  }

  getEquip(equipTags: any) {
    return this.buildings.equips.filter(equip => equipTags.every(elem => equip.markers.indexOf(elem) > -1) && equip._id == this.refs.ahuRef)[0];
  }

  getBtuObj(equipTags: any) {
    return this.buildings.equips
      .filter(equip => equipTags.every(elem => equip.markers.indexOf(elem) > -1) && equip.referenceIDs.gateway == this.refs.gateway)[0];
  }

  getDiagObj(equipTags: any, zone = false) {
    return this.buildings.equips
      .filter(equip => equipTags.every(elem => equip.markers.indexOf(elem) > -1) && equip._id == this.refs?.ahuRef)[0];
  }


  // this will set the enum and unit for the graph points
  setGraphEnumUnit(entity, value, key) {
    let unitCategory: boolean = false;
    let unit;
    if (entity.markers.includes("predefined")) {
      this.graphService.graphPointsUnitsCollection.set(key.replace(value, ''), 'V');
      this.graphService.graphPointsEnumCollection.set(key.replace(value, ''), entity.enum);
    } else {
      this.graphService.graphPointsUnitsCollection.set(key.replace(value, ''), unitCategory ? unit : entity.unit);
      this.graphService.graphPointsEnumCollection.set(key.replace(value, ''), entity.enum);
    }
  }

  // this will fill in details for enum and units
  getPointProperties(dataObj) {
    if (this.graphService.graphPointsCollection.size > 0) {
      if (dataObj && dataObj.entities) {
        dataObj.entities.map(entity => {
          this.graphService.graphPointsCollection.forEach((value, key) => {
            if (value == entity._id) {
              this.setGraphEnumUnit(entity, value, key);
            }
          });
        });
      }
    }
  }

  // this will fetch the time series data for the graph points
  fetchTimeSeriesData(daterange, adIdentifier: string = '') {
    // site timezone
    const tz: string = this.apiService.getIANATimeZone(this.siteTz);
    const utcOffset = dayjs().tz(tz).utcOffset();
    const id = this.ccuOrZone.type + this.ccuOrZone.actionUsed + (this.ccuOrZone.type == 'zone' ? this.refs.ahuRef : this.refs.roomRef) + adIdentifier + daterange?.startDate?.toString() + '-' + daterange?.endDate?.toString(); // this will serve as unique id subs handling
    // Set up check for download widgets status of data fetch
    if (this.ccuOrZone.actionUsed == 'click') {
      this.graphService.graphPointsCollection.forEach((singlePoint, key) => {
        if (singlePoint != undefined) {
          this.checkWidgestLoaded[key.replace(singlePoint, '')] = false;
        }
      });
    }

    const alreadySubscribed = (this.subscriptionCollection.filter(subs => {
      return subs.id == id;
    }).length > 0);

    if (!alreadySubscribed) {
      let pointList = [...this.graphService.graphPointsCollection.values()].filter(notUndefined => notUndefined !== undefined);
      if (pointList.length > 0) {
        const hisReadManySubject = this.apiService.gethisReadManyInterpolate(
          pointList,
          daterange,
          tz
        ).subscribe((res) => {

          if (res != undefined) {
            res = this.helperService.stripHaystackTypeMapping(res);
            let rows = [];
            if (res && res['rows']) {
              rows = this.filterTimeSeriesData(res);
            }
            const tempZones = [];
            if (rows) {
              this.graphService.graphPointsCollection.forEach((singlePoint, key) => {
                // singlePoint
                if (singlePoint != undefined) {
                  const filteredPointResponse = ObjectUtil.deepClone(rows.filter((x) => {
                    const ref = this.helperService.stripHaystackTypeMapping(x.id).split(' ')[0];
                    if ((typeof (singlePoint) != undefined) && ref.includes(singlePoint)) { return x; }
                  })[0]);

                  if (filteredPointResponse) {
                    let hisData = [];
                    hisData = [].concat(filteredPointResponse.data).map((row => {
                      const dateWithTimezone = dayjs(row.ts.replace(` ${filteredPointResponse.tz}`, '')).tz(tz);                      row.val = row['val'] ? row['val'].replace(filteredPointResponse.unit, '') : null;
                      row.val = row['val'] ? row['val'].replace(filteredPointResponse.unit, '') : null;
                      row.ts = dateWithTimezone.format('YYYY-MM-DDTHH:mm:ss');
                      if (row.val !== '' && row.ts != '') {
                        return row;
                      }
                    }));

                    const zoneType = key.replace(singlePoint, '');

                    // Handle cooling and heating user limits for multi module
                    if (zoneType.includes('heatingUserLimitMin') || zoneType.includes('heatingUserLimitMax') || zoneType.includes('coolingUserLimitMax') || zoneType.includes('coolingUserLimitMin')) {
                      let tempHisData = [];
                      if (!this.heatCoolLimitsHisData.has(singlePoint)) {
                        // Cache the limits in service
                        tempHisData = hisData.map(x => Object.assign({}, x));
                        this.heatCoolLimitsHisData.set(singlePoint, tempHisData);
                      } else {
                        // Check if it exists in cache in case of multi module
                        tempHisData = this.heatCoolLimitsHisData.get(singlePoint);
                      }
                      tempZones[zoneType] = tempHisData;
                    }
                    else {
                      let tempHisData = [];
                      tempHisData = hisData.map(x => Object.assign({}, x));
                      tempZones[zoneType] = tempHisData;
                    }

                    if (this.ccuOrZone.actionUsed == 'click') {
                      this.checkWidgestLoaded[zoneType] = true;
                    }

                  }
                }
              });
              this.subscriptionCollection.find(subs => subs.id == id).subscription.unsubscribe();
            }
            this.graphService.setData(tempZones);
            if (this.systemWidgets && this.systemWidgets[0] && this.systemWidgets[0]?.name?.includes('Default System Profile')) {
              this.heatMapToolTipService.deletefromOrigGraphData('comfortIndex' + this.refs?.ahuRef);
              this.heatMapToolTipService.deletefromOrigGraphData('modes' + this.refs?.ahuRef);
              // Hide the accordian
              const runtimeAccordian = (<HTMLInputElement>document.getElementById('accordian_' + this.systemWidgets[0]?.id + this.refs?.ahuRef));
              runtimeAccordian.click();
            }
          }
        }, noData => {
          this.graphService.resetDataonInvalidDate();
        });

        this.subscriptionCollection.push({ id: id, subscription: hisReadManySubject });
      }
    }
  }

  // this will fetch the coordinates for the graph points
  setXAxisCords(xVals: any) {
    if (xVals && xVals.length) {
      // clean up any item from previous selection
      this.xCords = [];
      xVals.forEach((x, i) => {
        if (x.textContent.toString() != '' && !x.textContent.toString().includes('.')) {
          this.xCords[i] = x.textContent.toString();
        }
      });
    }
  }

  // this will fetch the coordinates for the graph points
  zoneSingleDualGraph(res) {
    const graphIndexCooling = [];
    const graphIndexHeating = [];
    res['rows'].find((current) => {
      if (this.zoneSettings?.singleDual == "1" && (current.hasOwnProperty('heating') && current.hasOwnProperty('desired'))) {
        graphIndexCooling.push(current);
      } else if (this.zoneSettings?.singleDual == "2" && (current.hasOwnProperty('cooling') && current.hasOwnProperty('desired'))) {
        graphIndexHeating.push(current);
      }

      if (this.zoneSettings?.singleDual == "1" && (current.hasOwnProperty('limit') && current.hasOwnProperty('min') && current.hasOwnProperty('heating'))) {
        graphIndexCooling.push(current);
      } else if (this.zoneSettings?.singleDual == "2" && (current.hasOwnProperty('limit') && current.hasOwnProperty('min') && current.hasOwnProperty('cooling'))) {
        graphIndexHeating.push(current);
      }

      if (this.zoneSettings?.singleDual == "1" && (current.hasOwnProperty('limit') && current.hasOwnProperty('max') && current.hasOwnProperty('heating'))) {
        graphIndexCooling.push(current);
      } else if (this.zoneSettings?.singleDual == "2" && (current.hasOwnProperty('limit') && current.hasOwnProperty('max') && current.hasOwnProperty('cooling'))) {
        graphIndexHeating.push(current);
      }
    });


    if (graphIndexCooling.length) {
      let coolArray = graphIndexCooling.map((i) => i.id);
      for (let i = 0; i <= graphIndexCooling.length; i++) {
        res['rows'] = res['rows'].filter((item) => {
          return coolArray.indexOf(item.id) === -1;
        });
      }
    } else if (graphIndexHeating.length) {
      let heatArray = graphIndexHeating.map((i) => i.id);
      for (let i = 0; i <= graphIndexHeating.length; i++) {
        res['rows'] = res['rows'].filter((item) => {
          return heatArray.indexOf(item.id) === -1;
        });
      }

    }
    return res['rows'];
  }

  //***************************************Conversion Functions for graph data*************************************************************
  filterTimeSeriesData(data) {
    try {
        if((this.profileObj?.profileType == 'pid') && this.zonehasDomainName) {
            const checkDomainNameMatchFor1 = (row) => {
                return this.piAnalogSensorsDmDomainNames?.includes(row?.domainName) ||
                      this.thermistorSensorDmDomainNames?.includes(row?.domainName) ||
                      this.nativeSensorDMDomainNames?.includes(row?.domainName);
              };
            const checkDomainNameMatchFor2 = (row) => {
                return this.piAnalog2SensorsDmDomainNames?.includes(row?.domainName)
            };
            const matchingRows1 = data?.rows?.filter(checkDomainNameMatchFor1);
            const matchingRows2 = data?.rows?.filter(checkDomainNameMatchFor2);
            let target = data?.rows?.filter(item => item?.domainName == 'pidTargetValue');
            let dynamicTargetValue = data?.rows?.filter(item => item?.domainName == 'dynamicTargetValue');
            if(target?.length>0 && matchingRows1?.length>0) {
              data.rows.filter(item => item?.domainName == 'pidTargetValue')[0].unit = matchingRows1?.filter(item => item?.domainName == this.sensorType)[0]?.unit ;
                data?.rows?.filter(item => item?.domainName == 'pidTargetValue')[0]?.data.forEach(item => {
                  if (item?.val && typeof item?.val === 'string') {
                    item.val = item?.val?.split(' ')[0];
                  }
              });
            }
            if(dynamicTargetValue?.length>0 && matchingRows2?.length>0) {
              data.rows.filter(item => item?.domainName == 'dynamicTargetValue')[0].unit = matchingRows2[0]?.filter(item => item?.domainName == this.sensorType2)[0]?.unit;
                data?.rows?.filter(item => item?.domainName == 'dynamicTargetValue')[0]?.data.forEach(item => {
                  if (item?.val && typeof item?.val === 'string') {
                    item.val = item?.val?.split(' ')[0];
                  }
              });
            }
        }
    }
    catch(error) {
        console.error("Error processing domain name matches or unit assignments:", error)
    }
    let res = this.zoneSingleDualGraph(data);
    res = this.convertStagedFanValuetoVolt(res);
    let dataUnitExist = res?.filter(item => this.unitService.unitListArray.includes(item.unit)) || [];
    const dataUnitNotExist = res?.filter(item => !this.unitService.unitListArray.includes(item.unit)) || [];
    if (dataUnitExist.length > 0) {
      dataUnitExist = this.convertDataOnUnit(dataUnitExist);
    }
    return dataUnitNotExist.concat(dataUnitExist);
  }
  //Conversion of data based on user preference for fan speed
  convertStagedFanValuetoVolt(data) {
    const dataChanges = [];
    data.forEach(x => {
      if (x.hasOwnProperty('predefined') && x.hasOwnProperty('fan') && x.hasOwnProperty('speed')) {
        x.data.forEach(y => {
          if (y.d_d) {
            const value = Number(this.helperService.stripHaystackTypeMapping(y.d_d)) * 10 / 100;
            const valueToString = value.toString();
            y.d_d = `n:${valueToString}`

          }
        })
        dataChanges.push(x)
      } else if (x.hasOwnProperty('operating') && x.hasOwnProperty('mode') && x.hasOwnProperty('zone')) {
        x.data.forEach(y => {
          if (y.d_d) {
            const value = Number(this.helperService.stripHaystackTypeMapping(y.d_d));
            this.deviceHelper.setOperationModeData(value);
          }
        })
        dataChanges.push(x)
      } else {
        dataChanges.push(x)
      }
    })
    return dataChanges;
  }
  //Conversion of data based on user preference
  convertDataOnUnit(data) {
    let userPreference = {};
    let temperaturePoints = data.filter(item => this.unitService.temperatureUnitListArray.includes(item.unit));
    let energyConsumptionPoints = data.filter(item => this.unitService.energyConsumptionUnitListArray.includes(item.unit));
    let airflowVolumePoints = data.filter(item => this.unitService.airflowVolumeUnitListArray.includes(item.unit));
    let airPressurePoints = data.filter(item => this.unitService.airPressureUnitListArray.includes(item.unit));
    let waterPressurePoints = data.filter(item => this.unitService.waterPressureUnitListArray.includes(item.unit));
    let waterFlowPoints = data.filter(item => this.unitService.waterFlowUnitListArray.includes(item.unit));
    if (temperaturePoints.length > 0) {
      this.temperaturepoints(temperaturePoints, userPreference)
    }
    if (energyConsumptionPoints.length > 0) {
      this.energyConsumptionPoints(energyConsumptionPoints, userPreference)
    }
    if (airflowVolumePoints.length > 0) {
      this.airflowVolumePoints(airflowVolumePoints, userPreference)
    }
    if (airPressurePoints.length > 0) {
      this.airPressurePoints(airPressurePoints, userPreference)
    }
    if (waterPressurePoints.length > 0) {
      this.waterPressurePoints(waterPressurePoints, userPreference)
    }
    if (waterFlowPoints.length > 0) {
      this.waterFlowPoints(waterFlowPoints, userPreference)
    }
    return [...temperaturePoints, ...energyConsumptionPoints, ...airflowVolumePoints, ...airPressurePoints, ...waterPressurePoints, ...waterFlowPoints];
  }
  //Temperature points
  temperaturepoints(temperaturePoints, userPreference) {
    if (temperaturePoints.length > 0) {
      const userTemperaturePreference = userPreference.temperatureUnit;
      if (userTemperaturePreference == this.unitService.temperatureUnitListArray[0]) {
        for (let tempPoints of temperaturePoints) {
          let res = tempPoints.data;
          for (let conversionData of res) {
            if (conversionData.d_d != null) {
              var pointId = (tempPoints.dis).split('-').slice(-1).pop();
              let value = parseFloat(conversionData.d_d.split(':')[1]);
              let convertedValue = this.unitService.fahrenheitToCelecius(value, pointId);
              let feedbackData = 'n:' + convertedValue;
              conversionData['d_d'] = feedbackData;
            }
          }

        }
      }
    }
  }
  //energyConsumptionPoints
  energyConsumptionPoints(energyConsumptionPoints, userPreference) {
    if (energyConsumptionPoints.length > 0) {
      const userEnergyConsumptionPreference = userPreference.energyConsumptionUnit;
      if (userEnergyConsumptionPreference == this.unitService.energyConsumptionUnitListArray[1] || userEnergyConsumptionPreference == this.unitService.energyConsumptionUnitListArray[2] || userEnergyConsumptionPreference == this.unitService.energyConsumptionUnitListArray[3]) {
        for (let energyPoints of energyConsumptionPoints) {
          let res = energyPoints.data;
          for (let conversionData of res) {
            this.energyConversionpoints(conversionData, userEnergyConsumptionPreference)
          }
        }
      }
    }
  }
  //energyConversionPoints
  energyConversionpoints(conversionData, userEnergyConsumptionPreference) {
    if (conversionData.d_d != null) {
      let value = parseFloat(conversionData.d_d.split(':')[1]);
      let convertedValue
      if (userEnergyConsumptionPreference == this.unitService.energyConsumptionUnitListArray[1]) {
        convertedValue = this.unitService.energyConsumptionKBTU(value);
      } else if (userEnergyConsumptionPreference == this.unitService.energyConsumptionUnitListArray[2]) {
        convertedValue = this.unitService.energyConsumptionTonrefh(value);
      } else if (userEnergyConsumptionPreference == this.unitService.energyConsumptionUnitListArray[3]) {
        convertedValue = this.unitService.energyConsumptionGigajoules(value);
      }
      let feedbackData = 'n:' + convertedValue;
      conversionData.d_d = feedbackData;
    }
  }
  //airflowVolumePoints
  airflowVolumePoints(airflowVolumePoints, userPreference) {
    if (airflowVolumePoints.length > 0) {
      const userAirflowVolumePreference = userPreference.airflowVolumeUnit;
      if (userAirflowVolumePreference == this.unitService.airflowVolumeUnitListArray[1] || userAirflowVolumePreference == this.unitService.airflowVolumeUnitListArray[2] || userAirflowVolumePreference == this.unitService.airflowVolumeUnitListArray[3]) {
        for (let airflowVolume of airflowVolumePoints) {
          let res = airflowVolume.data;
          for (let conversionData of res) {
            this.airFlowVolumeConversionPoints(conversionData, userAirflowVolumePreference)
          }
        }
      }
    }
  }
  //airflow volume conversion points
  airFlowVolumeConversionPoints(conversionData, userAirflowVolumePreference) {

    if (conversionData.d_d != null) {
      let value = parseFloat(conversionData.d_d.split(':')[1]);
      let convertedValue
      if (userAirflowVolumePreference == this.unitService.airflowVolumeUnitListArray[1]) {
        convertedValue = this.unitService.airFlowMeterCubePerHour(value);
      } else if (userAirflowVolumePreference == this.unitService.airflowVolumeUnitListArray[2]) {
        convertedValue = this.unitService.airFlowLiterPerMinute(value);
      } else if (userAirflowVolumePreference == this.unitService.airflowVolumeUnitListArray[3]) {
        convertedValue = this.unitService.airFlowLiterPerSecond(value);
      }
      let feedbackData = 'n:' + convertedValue;
      conversionData.d_d = feedbackData;
    }

  }
  //airPressurepoints
  airPressurePoints(airPressurePoints, userPreference) {
    if (airPressurePoints.length > 0) {
      const userAirPressurePreference = userPreference.airPressureUnit;
      if (userAirPressurePreference == this.unitService.airPressureUnitListArray[1] || userAirPressurePreference == this.unitService.airPressureUnitListArray[2] || userAirPressurePreference == this.unitService.airPressureUnitListArray[3]) {
        for (let airPressure of airPressurePoints) {
          let res = airPressure.data;
          for (let conversionData of res) {
            this.airPressureConversionPoints(conversionData, userAirPressurePreference)
          }
        }
      }
    }
  }
  airPressureConversionPoints(conversionData, userAirPressurePreference) {
    if (conversionData.d_d != null) {
      let value = parseFloat(conversionData.d_d.split(':')[1]);
      let convertedValue
      if (userAirPressurePreference == this.unitService.airPressureUnitListArray[1]) {
        convertedValue = this.unitService.airPressureMmofWater(value);
      } else if (userAirPressurePreference == this.unitService.airPressureUnitListArray[2]) {
        convertedValue = this.unitService.airPressureCmOfWater(value);
      } else if (userAirPressurePreference == this.unitService.airPressureUnitListArray[3]) {
        convertedValue = this.unitService.airPressurePascle(value);
      }
      let feedbackData = 'n:' + convertedValue;
      conversionData.d_d = feedbackData;
    }
  }
  //waterpressurepoints
  waterPressurePoints(waterPressurePoints, userPreference) {
    if (waterPressurePoints.length > 0) {
      const userWaterPressurePreference = userPreference.waterPressureUnit;
      if (userWaterPressurePreference == this.unitService.waterPressureUnitListArray[1] || userWaterPressurePreference == this.unitService.waterPressureUnitListArray[2]) {
        for (let waterPressure of waterPressurePoints) {
          let res = waterPressure.data;
          for (let conversionData of res) {
            this.waterPressureConversionPoints(conversionData, userWaterPressurePreference);
          }
        }
      }
    }
  }
  waterPressureConversionPoints(conversionData, userWaterPressurePreference) {
    if (conversionData.d_d != null) {
      let value = parseFloat(conversionData.d_d.split(':')[1]);
      let convertedValue
      if (userWaterPressurePreference == this.unitService.waterPressureUnitListArray[1]) {
        convertedValue = this.unitService.waterPressurePsi(value);
      } else if (userWaterPressurePreference == this.unitService.waterPressureUnitListArray[2]) {
        convertedValue = this.unitService.waterPressureKpi(value);
      }
      let feedbackData = 'n:' + convertedValue;
      conversionData.d_d = feedbackData;
    }
  }
  //waterFlow
  waterFlowPoints(waterFlowPoints, userPreference) {
    if (waterFlowPoints.length > 0) {
      const userWaterFlowPreference = userPreference.waterFlowUnit;
      if (userWaterFlowPreference == this.unitService.waterFlowUnitListArray[1] || userWaterFlowPreference == this.unitService.waterFlowUnitListArray[2] || userWaterFlowPreference == this.unitService.waterFlowUnitListArray[3]) {
        for (let waterFlow of waterFlowPoints) {
          let res = waterFlow.data;
          for (let conversionData of res) {
            this.waterFlowConversionPoints(conversionData, userWaterFlowPreference);
          }
        }
      }
    }
  }
  waterFlowConversionPoints(conversionData, userWaterFlowPreference) {
    if (conversionData.d_d != null) {
      let value = parseFloat(conversionData.d_d.split(':')[1]);
      let convertedValue
      if (userWaterFlowPreference == this.unitService.waterFlowUnitListArray[1]) {
        convertedValue = this.unitService.waterFlowGalToMeterCubePerHour(value);
      } else if (userWaterFlowPreference == this.unitService.waterFlowUnitListArray[2]) {
        convertedValue = this.unitService.waterFlowGalToLiterPerMinute(value);
      } else if (userWaterFlowPreference == this.unitService.waterFlowUnitListArray[3]) {
        convertedValue = this.unitService.waterFlowGalToLiterPerSecond(value);
      }
      let feedbackData = 'n:' + convertedValue;
      conversionData.d_d = feedbackData;
    }
  }
  //******************************************************************Conversion FUnctions Ends************************************************


  // this will fetch the hpu data for the graph points
  getHPUConfigPoints(profile: any): Observable<any[]> {
    const airFlowEnable = this.apiService.getWritablePointData(this.helperService.getPointIdbyTags(profile, ['th1', 'enable'], null)).pipe(
      map(this.helperService.stripHaystackTypeMapping));
    const heatPumpchangeovertype = this.apiService.getWritablePointData(this.helperService.getPointIdbyTags(profile, ['zone', 'hpu', 'changeover', 'type'], null)).pipe(
      map(this.helperService.stripHaystackTypeMapping));
    const hpuRelay5Config = this.apiService.getWritablePointData(this.helperService.getPointIdbyTags(profile, ['relay5', 'type', 'config'], null)).pipe(
      map(this.helperService.stripHaystackTypeMapping));

    return forkJoin([airFlowEnable, heatPumpchangeovertype, hpuRelay5Config]);
  }

  // this will fetch the cpu data for the graph points
  getCPUConfigPoints(profile: any): Observable<any[]> {
    const cpuRelay6Config = this.apiService.getWritablePointData(this.helperService.getPointIdbyTags(profile, ['relay6', 'type', 'config'], null)).pipe(
      map(this.helperService.stripHaystackTypeMapping));

    return forkJoin([cpuRelay6Config]);
  }

  // this will fetch the equip data for the graph points
  getSiteObj(equipTags: any) {
    return this.buildings.equips
      .filter(equip => equipTags.every(elem => equip.markers.indexOf(elem) > -1))[0];
  }

  modbusProfileSort() {
    //The below conditional checks is applicable for only modbus profiles.
    //Based on port number child and parent equips are classified.
    let portNumber: Number;
    this.profiles.forEach(x => {
      this.isModbus = x.profileType == "modbus" ? true : false;
      if (!this.isModbus) {
        x['profileName'] = x.profile.name;
        return;
      }
      x['profileName'] = `${x.profile.name}_${x.profile._id}`;
      if ((!x.profile?.referenceIDs?.hasOwnProperty('equip')) && this.isModbus) {
        portNumber = x.portNum;;
        x['isChild'] = false;
      }

      if (this.isModbus && (x.profile?.referenceIDs?.hasOwnProperty('equip') && x.portNum == portNumber)) {
        x['isChild'] = true;
      } else if (this.isModbus && (x.profile?.referenceIDs?.hasOwnProperty('equip') && x.portNum != portNumber)) {
        x['isChild'] = false;
      }
    })
    this.profiles.sort((a, b) => {
      if (!a.profile.referenceIDs.equip && !b.profile.referenceIDs.equip) {
        return 0;
      }
      if (a.profile.referenceIDs.equip && !b.profile.referenceIDs.equip) {
        return 1;
      }
      if (!a.profile.referenceIDs.equip && b.profile.referenceIDs.equip) {
        return -1;
      }
      if (a.profile.referenceIDs.equip && b.profile.referenceIDs.equip) {
        return 1;
      }
      return 0;
    })
  }

  //Checking markers for the profile
  getNodes(arr, tags) {
    if (!arr || (Array.isArray(arr) && !arr.length)) {
      return [];
    }
    return arr.filter((_filterItem) => _filterItem)
      .filter((_item) => {
        const found = (_item.markers && Array.isArray(_item.markers)) ? tags.every(elem => _item.markers.indexOf(elem) > -1) : false;
        if (found) {
          return _item;
        }
      });
  }

  //Get the reference of the equipment and room
  getEquipRef(zoneId: string) {
    const room = this.widgetData?.find(et => et._id == zoneId);
    if (room) {
      this.roomObj = room;
      let equip = room.entities.filter(equip => equip.type == 'equip')[0];
      if (equip) {
        this.refs.roomRef = equip?.referenceIDs?.room;
        this.refs.ccuRef = equip?.referenceIDs?.ccu;
        this.refs.equipRef = equip._id;
        this.refs.ahuRef = equip?.referenceIDs?.ahu || equip?.referenceIDs?.gateway;
      }
    }
  }

  //***************************************Important Method for Getting Points Data*************************************************************
  getPointsData(key: string) {
    this.getUserSettingDataSubscriptionCollection[key] = this.helperService.getPointData()
      .pipe(
        takeUntil(this.unsubscribe))
      .subscribe(res => {

        if (key == 'reConfig') {
          this.delayChanges.next(ObjectUtil.deepClone({
            val: res,
            key: key
          }));

        } else {
          this.delayChanges.next(ObjectUtil.deepClone({
            val: res,
            key: key
          }));

          setTimeout(() => {
            this.zoneSettingsFunction({ val: res, key: key });
          }, 3000);
        }
      });
  }

  isModuleOnline(profile) {
    if (!this.zoneSettings) { return; }
    if (this.profiles.length > 1 || (this.isProfileEMR(this.profiles[this.profiles.length - 1]) && this.profiles)) {
      if (!(this.zoneSettings && this.zoneSettings[profile.profile.name] && this.zoneSettings[profile.profile.name]['lastUpdated'] && this.zoneSettings[profile.profile.name]['lastUpdated'].hasOwnProperty('val'))) { return; }
      this.zoneSettings[profile.profile.name]['lastUpdated']['val'] = dayjs.utc(this.zoneSettings[profile.profile.name]['lastUpdated']['time']).local();
      const timeDiffMinutes = Math.abs(this.zoneSettings[profile.profile.name]['lastUpdated']['val'].diff(dayjs(), 'minutes'));
      return timeDiffMinutes < 16;
    } else {
      if (!(this.zoneSettings && this.zoneSettings['lastUpdated'] && this.zoneSettings['lastUpdated'].hasOwnProperty('val'))) { return; }
      this.zoneSettings['lastUpdated']['val'] = dayjs.utc(this.zoneSettings['lastUpdated']['time']).local();
      const timeDiffMinutes = Math.abs(this.zoneSettings['lastUpdated']['val'].diff(dayjs(), 'minutes'));
      return timeDiffMinutes < 16;
    }
  }

  // Format the zone name
  formatZoneName(equipName: any) {
    try {
      let profileName = this.setZoneProfileName(equipName);
      return profileName;
    } catch (error) {
      return "";
    }
  }

  // Set the zone profile name
  setZoneProfileName(equipName: any) {
    const subProfile = equipName.profileType == 'vav' ? ' REHEAT - NO FAN' : '';
    let profileName;
    const isCarrier = environment.partnerName === 'carrier';
    if (equipName && equipName.profileType) {
      switch (equipName.profileType) {
        case 'pipe2':
        case 'pipe4':
        case 'hpu':
        case 'cpu':
          profileName = this.setHyperstatSmartStatName(equipName)
          break;
        case 'pid':
          profileName = 'pi loop controller';
          break;
        case 'dualDuct':
          profileName = isCarrier ? 'vvt-c dual duct' : 'dab dual duct';
          break;
        case 'series':
          profileName = 'vav reheat-series';
          break;
        case 'parallel':
          profileName = 'vav reheat-parallel';
          break;
        case 'dab':
          profileName = isCarrier ? 'vvt-c' : 'dab';
          break
        case 'modbus':
          //change in the emr_zone  to emr filtering based on modbus profile for modbus profile
          profileName = equipName.modBusEquip;
          break;
        case 'otn':
          profileName = 'temperature influencing'
          break;
        case 'chilledBeam':
          profileName = 'active chilled beams + doas';
          break;
        case 'NON_75F_PROFILE':
          return equipName.profileName;

        default:
          profileName = equipName.profileType;
      }
    }

    if (equipName.profileType == "modbus") {
      return profileName.concat(' (' + equipName.portNum + ')');
    } else {
      return profileName ? profileName.toUpperCase().concat(subProfile).concat(' (' + equipName.portNum + ')') : '';
    }
  }

  // Set the hyperstat and smartstat name
  setHyperstatSmartStatName(equipName) {

    let profiles = ['pipe2', 'cpu', 'hpu', 'pipe4'];
    let subProfileName = equipName.profile.markers.find(e => e === 'hyperstat' || e === 'smartstat');
    const checkForHsSplit = equipName.profile.markers.find(e => e === 'hyperstatsplit');
    const zoneProfile = profiles.includes(equipName.profileType);
    const checkForMystat = equipName.profile.markers.find(e => e === 'mystat');

    if (checkForHsSplit && zoneProfile) {
      return 'hyperstat split- cpu & economizer';
    } else if (subProfileName && zoneProfile  && !checkForMystat) {
      return `${subProfileName}` + '-' + `${equipName.profileType}`;
    } else if (checkForMystat && zoneProfile) {
      if(equipName.profileType == 'pipe2') {
        return 'MyStat - 2 PIPE FCU';
      }
    }

  }

  // Mehtod for loading equip graphics for particular profiles
  setTerminalGraphicValues(res) {
    this.equipGraphicData = ObjectUtil.deepClone(res);
    const profileTypesToCheck = ['chilledBeam', 'dab', 'dualDuct', 'sse', 'vav', 'series', 'pipe2', 'pipe4', 'hpu', 'parallel', 'cpu'];
    const checkProfileTypeMatch = this.profiles.some((obj: { profileType: string }) => profileTypesToCheck.includes(obj.profileType));
    if (checkProfileTypeMatch || this.checkHyperStatProfile === `hyperstatsplit`) {
      this.showTerminalEquipGraphics = true;
    } else {
      this.showLoader = false;
    }
  }

  // Method to set data for equip graphics
  zoneSettingsFunction(res) {
    this.zoneSettings = ObjectUtil.deepClone(res.val);
    this.setTerminalGraphicValues(this.zoneSettings);
    this.tempZoneSettingsHolder = ObjectUtil.deepClone(res.val);
    this.handleHumidityDehumidity();
    this.handleZonePriority();
    this.handleZoneSchedule();
    this.heartBeathandle();

    // this.updateActiveTileSettings();
    // this.zoneUserIntentSettings();
  }

  heartBeathandle() {
    if (!this.zoneSettings) { return; }
    if (this.profiles && this.profiles.length > 1 || this.isProfileEMR(this.profiles[this.profiles.length - 1])) {
      this.profiles.forEach(profile => {
        if (this.zoneSettings[profile.profile.name]?.lastUpdated?.time && this.zoneSettings[profile.profile.name]?.lastUpdated.hasOwnProperty('val')) {
          this.zoneSettings[profile.profile.name]['lastUpdated']['val'] = dayjs.utc(this.zoneSettings[profile.profile.name].lastUpdated.time).local();
        }
      });
    } else {
      if (this.zoneSettings?.lastUpdated?.time && this.zoneSettings?.lastUpdated.hasOwnProperty('val')) {
        this.zoneSettings['lastUpdated']['val'] = dayjs.utc(this.zoneSettings.lastUpdated.time).local();
      }
    }
  }

  dateFormat(val) {
    return DateUtil.dateFormat(val, this.apiService.getIANATimeZone(this.siteTz));
  }

  isProfileEMR(equipName) {
    return equipName && (equipName.profileType && equipName.profileType && equipName.profileType == 'emr');
  }

  handleZonePriority() {
    if (this.multiModuleProfile) {
      // this.multiModuleZonePriority();
      // this.getMultiZonePriorityVal();
    } else {
      if (this.zoneSettings && this.zoneSettings.zonePriority) {
        this.zonePriorityVal = String(this.zoneSettings.zonePriority.val);
      }
    }
  }

  handleZoneSchedule() {
    if (this.multiModuleProfile) {
      // this.multiModuleZoneSchedule();
      // this.getMultiScheduleTypeVal();
    } else {
      if (this.zoneSettings && this.zoneSettings.scheduleType && this.zoneSettings.scheduleType.hasOwnProperty('val')) {
        this.scheduleTypeVal = String(this.zoneSettings.scheduleType.val);
      }
    }
  }

  handleHumidityDehumidity() {
    let hyperStatProfileName = this.profileObj?.profile?.markers?.find(e => e === 'hyperstatsplit' || e === 'hyperstat');
    if (hyperStatProfileName?.length || this.multiModuleProfile) {
      let keys = ['relay1Association', 'relay2Association', 'relay3Association', 'relay4Association', 'relay5Association', 'relay6Association']
      let relayKeysStatus = ['relay1', 'relay2', 'relay3', 'relay4', 'relay5', 'relay6']
      //Note As of now for only hyperstat split has relay 7 and relay 8 for the other hyperstat profiles
      // with the old builds we have up to relay6 in futture aprt form hyperstat  split also we will get relay8 
      if (hyperStatProfileName == 'hyperstatsplit') {
        keys = [...keys, 'relay7Association', 'relay8Association']
        relayKeysStatus = [...relayKeysStatus, 'relay7', 'relay8']
      }
      if (this.profiles?.length) {
        this.profiles.forEach((_item, _index) => {
          if (_item['profileType'] == 'cpu' || _item['profileType'] == 'cpuecon' || _item['profileType'] == 'hpu' || _item['profileType'] == 'pipe2') {
            let profileName = _item['profile']?.['name'] ? _item['profile']['name'] : '';
            let relayVal;
            let relayEnabled
            for (let i = 0; i < keys.length; i++) {
              let profileTypeCheck = _item['profileType']
              const relayKey = profileTypeCheck == _item['profileType'] ? keys[i] : '';
              const relayKeyStatus = profileTypeCheck == _item['profileType'] ? relayKeysStatus[i] : '';
              if (this.profiles.length > 1) {
                relayVal = this.setRelayproperties(profileName, relayKey);
                relayEnabled = this.setRelayproperties(profileName, relayKeyStatus);
              } else {
                relayVal = this.relaypropertForEmptyProfiles(relayKey);
                relayEnabled = this.relaypropertForEmptyProfiles(relayKeyStatus);
              }
              if (!_item[relayKey] || (_item[relayKey] != relayVal)) {
                _item[relayKey] = relayVal;

                if (_item['profileType'] == 'pipe2' && relayVal == 8 && relayEnabled == 1 || _item['profileType'] == 'cpu' && relayEnabled == 1 && relayVal == 11 || _item['profileType'] == 'cpuecon' && relayEnabled == 1 && relayVal == 11 || _item['profileType'] == 'hpu' && relayEnabled == 1 && relayVal == 10) {
                  _item['tags'] = ObjectUtil.insertKey('targetHumidity', ['zone', 'target', 'humidifier'], _item['tags'], 1);
                  this.profiles[_index] = Object.assign({}, _item);
                }


                if (_item['profileType'] == 'pipe2' && relayEnabled == 1 && relayVal == 9 || _item['profileType'] == 'cpu' && relayEnabled == 1 && relayVal == 12 || _item['profileType'] == 'cpuecon' && relayEnabled == 1 && relayVal == 12 || _item['profileType'] == 'hpu' && relayEnabled == 1 && relayVal == 11) {
                  _item['tags'] = ObjectUtil.insertKey('targetDehumidity', ['zone', 'target', 'dehumidifier'], _item['tags'], 1);
                  this.profiles[_index] = Object.assign({}, _item);
                }
              }

            }
          }
        });

      }
    } else {

      if (this.profiles?.length) {
        this.profiles.forEach((_item, _index) => {
          if (_item['profileType'] == 'cpu' || _item['profileType'] == 'hpu') {
            let relayKey = _item['profileType'] == 'cpu' ? 'relay6Type' : 'relay5Type';
            let profileName = _item['profile']?.['name'] ? _item['profile']['name'] : '';
            let relayVal;
            if (this.profiles.length > 1) {
              relayVal = (this.zoneSettings && profileName && this.zoneSettings[profileName]) ?
                ((this.zoneSettings[profileName]?.[relayKey]?.['val']) ?
                  (this.zoneSettings[profileName][relayKey]['val'] || '') : '') : '';
            } else {
              relayVal = (this.zoneSettings) ?
                ((this.zoneSettings[relayKey]?.['val']) ?
                  (this.zoneSettings[relayKey]['val'] || '') : '') : '';
            }
            if (!_item[relayKey] || (_item[relayKey] != relayVal)) {
              _item[relayKey] = relayVal;
              if (relayVal == '2') { // Adding targetHumidity
                _item['tags'] = ObjectUtil.insertKey('targetHumidity', ['zone', 'target', 'humidity'], _item['tags'], 1);
                _item['tags']['targetDehumidity'] ? delete _item['tags']['targetDehumidity'] : '';
                this.profiles[_index] = Object.assign({}, _item);
              } else if (relayVal == '3') { // Adding targetDehumidity
                _item['tags'] = ObjectUtil.insertKey('targetDehumidity', ['zone', 'target', 'dehumidifier'], _item['tags'], 1);
                _item['tags']['targetHumidity'] ? delete _item['tags']['targetHumidity'] : '';
                this.profiles[_index] = Object.assign({}, _item);
              } else {
                _item['tags']['targetHumidity'] ? delete _item['tags']['targetHumidity'] : '';
                _item['tags']['targetDehumidity'] ? delete _item['tags']['targetDehumidity'] : '';
                this.profiles[_index] = Object.assign({}, _item);
              }
            }
          }
        });
      }
    }

  }

  // this will fetch the relay data for the graph points
  relaypropertForEmptyProfiles(relayKey) {
    let relay;
    if (this.zoneSettings) {
      if (this.zoneSettings[relayKey] && this.zoneSettings[relayKey]['val']) {
        relay = this.zoneSettings[relayKey]['val'] || '';
      } else {
        relay = ''
      }
    } else {
      relay = ''
    }

    return relay;
  }

  // this will fetch the relay data for the graph points
  setRelayproperties(profileName, relayKey) {
    let relay
    if (this.profiles.length > 1) {
      if (this.zoneSettings && profileName && this.zoneSettings[profileName]) {

        if (this.zoneSettings[profileName][relayKey] && this.zoneSettings[profileName][relayKey]['val']) {
          relay = this.zoneSettings[profileName][relayKey]['val'] || '';
        }
      } else {
        relay = ''
      }
    }
    return relay;

  }

  // this will fetch the all data for all points
  getZoneSettings(settingType: string, pointID: any, endPointType: string, profileEquipName: string = undefined) {
    if (pointID) {
      pointID.map((pId) => this.helperService.assemblePointIdData(pId, endPointType, settingType, profileEquipName, 'update'));
      Object.assign(this.priorityArray, { [settingType]: {} });
      this.priorityArray[settingType]['id'] = pointID;
    }
  }

  // this will fetch the sense data for the graph points
  getSenseData(profileObj) {
    const senseData = {
      analog1: '',
      analog2: '',
      th1: '',
      th2: '',
    };
    if (profileObj) {
      const profileType = this.monitoringProfile ? 'monitoring' : 'sense';
      const analog1 = this.helperService.getPointIdbyTags(profileObj, [profileType, 'logical', 'analog1'], null);
      const analog2 = this.helperService.getPointIdbyTags(profileObj, [profileType, 'logical', 'analog2'], null);
      const th1 = this.helperService.getPointIdbyTags(profileObj, [profileType, 'logical', 'th1'], null);
      const th2 = this.helperService.getPointIdbyTags(profileObj, [profileType, 'logical', 'th2'], null);
      if (profileObj.entities) {
        profileObj.entities.map(entity => {
          [{
            pointId: analog1,
            key: 'analog1'
          }, {
            pointId: analog2,
            key: 'analog2'
          }, {
            pointId: th1,
            key: 'th1'
          }, {
            pointId: th2,
            key: 'th2'
          }].forEach((point) => {
            if (point.pointId == entity._id) {
              senseData[point.key] = entity.dispName;
              if (point.key == 'analog1') {
                senseData.analog1 = entity.dispName;
              } else if (point.key == 'analog2') {
                senseData.analog2 = entity.dispName;
              } else if (point.key == 'th1') {
                senseData.th1 = entity.dispName;
              } else if (point.key == 'th2') {
                senseData.th2 = entity.dispName;
              }
            }
          });
        });
      }
    }
    return senseData;
  }


  async transformData(dataArray: any[]): Promise<{ [key: string]: string }> {
    const result: { [key: string]: string } = {};

    dataArray.forEach(item => {
      const domainName = item.domainName;
      const value = item.data[0]?.val;

      if (domainName && value) {
        // Remove the 'n:' prefix if it exists
        const cleanedValue = value.startsWith('n:') ? value.slice(2) : value;
        result[domainName] = cleanedValue;
      }
    });

    return result;
  }


  async co2BasedDamperControlOn() {
    const equipObj = this.getEquip(['system', 'equip', 'profile']) || [];
    let hsCpuAnalogOut1 = this.helperService.getPointIdbyTags(equipObj, null, null, null, ['co2BasedDamperControlOn'])[0];
    try {
      const res = await firstValueFrom(this.siteService.getBulkWritablePointDataForQRCode([hsCpuAnalogOut1], this.qrCodeMetaData));
      if (res?.rows?.length > 0) {
        return this.helperService.stripHaystackTypeMapping(res.rows[0]?.val).replace(/\ .*/, '');
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async toogleCheckforStaticPressurePoint(): Promise<string | undefined> {
    try {
      const equipObj = this.getEquip(['system', 'equip', 'profile']);
      const pointIds = this.getPointIdsForVavDabadvanceSystemProfiles(equipObj);

      // Fetch writable data and current read points data using lastValueFrom
      const [writableData, writableData1] = await Promise.all([
        lastValueFrom(this.siteService.getBulkWritablePointDataForQRCode(pointIds.advanceAhuSpPoint.filter(v => v), this.qrCodeMetaData)),
        lastValueFrom(this.siteService.getReadByIdManyForQRCode(pointIds.advanceAhuSpPointReadpoints.filter(v => v), this.qrCodeMetaData))
      ]);

      const domainNames = writableData1?.rows?.map(item => item.domainName);
      const transformedWritableData = await this.transformData(writableData?.rows || []);


      return this.determineResult(transformedWritableData, domainNames);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
  // Method to get point IDs
  private getPointIdsForVavDabadvanceSystemProfiles(equipObj: any): {
    advanceAhuSpPoint: (string | undefined)[],
    advanceAhuSpPointReadpoints: (string | undefined)[]
  } {
    const tags = [
      'pressureSensorBusAdd0', 'pressureBasedFanControlOn', 'analog1InputAssociation', 'analog2InputAssociation',
      'ductStaticPressureSensor1_1', 'ductStaticPressureSensor1_2', 'ductStaticPressureSensor1_10',
      'ductStaticPressureSensor2_1', 'ductStaticPressureSensor2_2', 'ductStaticPressureSensor2_10',
      'ductStaticPressureSensor3_1', 'ductStaticPressureSensor3_2', 'ductStaticPressureSensor3_10'
    ];

    const pointIds: { [key: string]: string | undefined } = {};
    tags.forEach(tag => {
      pointIds[tag] = this.helperService.getPointIdbyTags(equipObj, null, null, null, tag)[0];
    });

    // Return the structured object with arrays
    return {
      advanceAhuSpPoint: [
        pointIds['pressureSensorBusAdd0'],
        pointIds['pressureBasedFanControlOn'],
        pointIds['analog1InputAssociation'],
        pointIds['analog2InputAssociation']
      ],
      advanceAhuSpPointReadpoints: [
        pointIds['ductStaticPressureSensor1_1'], pointIds['ductStaticPressureSensor1_2'], pointIds['ductStaticPressureSensor1_10'],
        pointIds['ductStaticPressureSensor2_1'], pointIds['ductStaticPressureSensor2_2'], pointIds['ductStaticPressureSensor2_10'],
        pointIds['ductStaticPressureSensor3_1'], pointIds['ductStaticPressureSensor3_2'], pointIds['ductStaticPressureSensor3_10']
      ]
    };
  }


  getSensorKey(pressureFanControl: number, analogInput: number) {
    const sensorMaps = {
      0: { 12: 'ductStaticPressureSensor1_1', 13: 'ductStaticPressureSensor1_2', 14: 'ductStaticPressureSensor1_10' },
      1: { 15: 'ductStaticPressureSensor2_1', 16: 'ductStaticPressureSensor2_2', 17: 'ductStaticPressureSensor2_10' },
      2: { 18: 'ductStaticPressureSensor3_1', 19: 'ductStaticPressureSensor3_2', 20: 'ductStaticPressureSensor3_10' },
    }

    return sensorMaps[pressureFanControl]?.[analogInput];
  };

  private determineResult(transformedWritableData: any, domainNames: string[] | undefined): string | undefined {
    const pressureSensorBusAdd0Value = Number(transformedWritableData['pressureSensorBusAdd0']);
    const pressureBasedFanControlValue = Number(transformedWritableData['pressureBasedFanControlOn']);
    const analog1InputAssociationValue = Number(transformedWritableData['analog1InputAssociation']);
    const analog2InputAssociationValue = Number(transformedWritableData['analog2InputAssociation']);

    const getSensorResult = (analogInput: number): string | undefined => {
      const sensorKey = this.getSensorKey(pressureBasedFanControlValue, analogInput);
      return sensorKey && domainNames?.includes(sensorKey) ? sensorKey : undefined;
    };

    if ([12, 13, 14, 15, 16, 17, 18, 19, 20].includes(analog1InputAssociationValue)) {
      const result = getSensorResult(analog1InputAssociationValue);
      if (result) return result;
    }

    if ([12, 13, 14, 15, 16, 17, 18, 19, 20].includes(analog2InputAssociationValue)) {
      const result = getSensorResult(analog2InputAssociationValue);
      if (result) return result;
    }

    const additionalChecks = {
      0: { 1: 'ductStaticPressureSensor1_2' },
      1: { 2: 'ductStaticPressureSensor2_2' },
      2: { 3: 'ductStaticPressureSensor3_2' }
    };

    const additionalResult = additionalChecks[pressureBasedFanControlValue]?.[pressureSensorBusAdd0Value];
    if (additionalResult && domainNames?.includes(additionalResult)) {
      return additionalResult;
    }
  }

  // Initialize the date 
  intializeDate() {
    if (this.siteTz) {
      const tz: string = this.apiService.getIANATimeZone(this.siteTz);

      this.selected = {
        startDate: dayjs().tz(tz).startOf('day'),
        endDate: dayjs(Date.now()).tz(tz),
        timeZone: tz
      };
      this.heatMapToolTipService.selectedDateRange = this.selected;
    }
  }

  checkFilesTaggedforProfile() {
    if (this.profiles?.length > 0) {
      const observables = this.profiles.map(profile => {
        return this.apiService.getTaggedFiles(profile.profile._id);
      });
      forkJoin(observables).subscribe(
        (responses: any[]) => {
          responses.forEach((res, index) => {
            const profile = this.profiles[index];
            profile['taggedFiles'] = res;
            profile['enableViewAssets'] = res && res.length > 0;
          });
        },
        (error) => {
          console.log('Error fetching tagged files for profiles', error);
        }
      );
    }
  }

  graphicListChangeEvent(data) {
    this.onGraphicListChangedEvent = data;
}

  showPredefinedEquipGraphics(data){
    this.showPredefinedEquipGraphic[data?.entityRef] = data?.showPredefinedGraphics;
  }
}
