import { Component, HostListener, Input, OnInit } from '@angular/core';
import { SiteService, HelperService } from '@75f/portal-ui-components';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  entitiesList: any;
  graphWidth: number;
  @Input() refs: any;
  @Input() ccuOrZone: string;
  @Input() buildings: any;
  isAnalyticsLoading: boolean = true;


  constructor(public siteService: SiteService, public helperService: HelperService) { }

  ngOnInit(): void {
    this.graphWidth = window.innerWidth;
    this.isAnalyticsLoading = true;
    this.getEquipsAndDevicesFromSiteId();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.graphWidth = window.innerWidth;
  }

  getEquipsAndDevicesFromSiteId() {
    this.siteService.getQuerybySite(this.refs.siteRef, "(equip or device or ccu or room or floor)").subscribe((res) => {
      res = this.helperService.stripHaystackTypeMapping(res);
      this.entitiesList = res.rows;    
      this.isAnalyticsLoading = false;
    }, (err) => {
      console.log(err, 'something went wrong');
    });

  }
}
