<div id="qr-code-header" class="header">
  <div class="logo">
    <img
      [ngClass]="{'carrier-logo' : partnerName == 'carrier' , 'airoverse-logo' : partnerName == 'airoverse',  'logo-75f': partnerName == '75f' }"
      src="../../../assets/images/logo.png" alt="Logo">
  </div>
</div>

<div class="content-layout">

  <div *ngIf="!showEquipGraphics && isEquipGraphicsLoading" class="loader-container">
    <div class="equip-graphics-title">
      Graphic Profile
    </div>
    <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '0', height: '200px' }"></ngx-skeleton-loader>
  </div>


  <puc-accordion *ngIf="!isEquipGraphicsLoading"
    [blockTitle]="'Graphic Profile - ' + equipGraphicsProfiles" [isEquipGraphics]="true" [isOpen]="true"
    (accordianState)="true" (displayTimeToggle)="false" [isQRCode]="true" [enableLastUpdatedTime]="false"
    [showAssetButton]="true" [enableViewAssets]="enableViewAssetsSystemProfile"
    [taggedFilesList]="taggedFilesListSystemProfile" [siteRef]="refs.siteRef">
    <puc-custom-graphics-layout *ngIf="!isEquipGraphicsLoading && !showPredefinedEquipGraphic?.[refs.ccuId]" [open]="true" [siteRef]="refs.siteRef"
      [graphicsFrom]="'ccu'" [siteName]="" [ccuRef]="qrCodeMetaData.id" [roomRef]="refs.roomRef"
      [graphicListChangedEvent]="onGraphicListChangedEvent" [isExternal]="true"
      (showPredefinedEquipGraphics)="showPredefinedEquipGraphics($event)">
    </puc-custom-graphics-layout>
    <puc-system-equip-graphics *ngIf="showPredefinedEquipGraphic?.[refs.ccuId] && equipGraphicSystemProfile && equipGraphicData && showEquipGraphics" [hoverdOrClickEventFalg]="true"
      [showLastUpdated]="false" [equipGraphicData]="equipGraphicData" [tempPreference]="userTempPreference"
      [systemReconfigProfileName]="equipGraphicSystemProfile" [ccuRef]="refs.ccuId"
      [siteRef]="refs.siteRef"></puc-system-equip-graphics>
    <div class="no-custom-graphics-text" *ngIf="showPredefinedEquipGraphic?.[refs.ccuId] && !showEquipGraphics">
      No custom graphics to show
    </div>
    <puc-asset-data-table [ahuRef]="refs.ahuRef" [isSystem]="true">
    </puc-asset-data-table>
  </puc-accordion>


  <puc-accordion blockTitle="Predefined Widgets" [isOpen]="true" [titleStyle]="accordionHeaderStyle?.firstLevel"
    (accordianState)="true">
    <div *ngIf="!systemWidgets">
      <ngx-skeleton-loader count="10" [theme]="{ 'border-radius': '0', height: '50px' }"></ngx-skeleton-loader>
    </div>
    <ng-container *ngFor="let widget of systemWidgets">
      <puc-accordion [blockTitle]="widget.name" [titleStyle]="accordionHeaderStyle.secondLevel"
        [graphId]="widget.id+refs.ahuRef" isOpen="true">
        <puc-widget>
          <puc-graph [graphWidth]="graphWidth" [graphData]="widget" [graphId]="widget.id+refs.ahuRef" [linearData]=""
            (xAxisCords)="setXAxisCords($event)" [leftPos]="30" [moduleIdentifier]="" [moduleRef]="refs.ahuRef" [isQRCode]="true">
          </puc-graph>
        </puc-widget>
      </puc-accordion>
    </ng-container>
  </puc-accordion>

  <!-- Analytics Section -->
  <app-analytics *ngIf="loadAnalytics" [refs]="refs" [ccuOrZone]="'ccu'" [buildings]="buildings"></app-analytics>

</div>