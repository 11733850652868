import { Component, HostListener, Input, OnInit } from '@angular/core';
import {
    ObjectUtil, PucSystemVavComfortIndexGraphWidget,
    PucSystemDabComfortIndexGraphWidget,
    UnitService, GraphService,HeatmapService,CommonService,
    SiteService, HelperService, RuntimeGraphService, HeatMapToolTipService, systemprofileWidgets,
    DeviceHelperService, ArrayUtil,
    systemProfileTagsArray, systemProfileOaoTagsArray, systemProfileFilterTags, systemProfileFilterOAOTags,
    systemprofileEquipGraphics, vavDabAdvancedAhuV2EquipGraphics
} from '@75f/portal-ui-components';

import { takeUntil, map, first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { lastValueFrom, Subject ,firstValueFrom} from 'rxjs';
import * as _ from 'lodash-es';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ApiService } from '@/shared/_services/api.service';
import { Title } from '@angular/platform-browser';
dayjs.extend(utc);
dayjs.extend(timezone);

@Component({
    selector: 'app-system-layout',
    templateUrl: './system-layout.component.html',
    styleUrls: ['./system-layout.component.scss']
})
export class SystemLayoutComponent implements OnInit {
    @Input() qrCodeMetaData: any;
    oaoConnectModule:boolean = false
    checkForAdvanceAhuProfiles = ["vavAdvancedHybridAhuV2", "dabAdvancedHybridAhuV2"];
    equipGraphicSystemProfile: any;
    equipGraphicsProfiles: any;
    equipGraphicData: any;
    userTempPreference: any = '°F';
    checkWidgestLoaded = {};
    showEquipGraphics: boolean;
    isEquipGraphicsLoading:boolean = true;
    systemReconfigurationSettings: any;
    buildings: any = {
        buildings: [],
        floors: [],
        floorsMaster: [],
        ccus: [],
        floorCcu: [],
        rooms: {},
        equips: {},
        zones: [],
        schedule: [],
        tuners: []
    };
    isdomainName: boolean = false;
    systemWidgets: any;
    showSchedulerOccupancy: boolean;
    onSystemMode = false;
    refs: any = {
        siteRef: undefined,
        floorRef: undefined,
        ccuName: undefined,
        zoneName: undefined,
        equipRef: undefined,
        roomRef: undefined,
        gateway: undefined,
        ccuEquipRef: undefined,
        ccuConfigRef: undefined,
        vavDabConncetModuleRef:undefined,
    };

    priorityArray: any = [];
    version: any;
    private unsubscribe: Subject<void> = new Subject();
    cmVersion;
    allDevices: Array<any> = [];
    bypassDamperId: any;
    private subscriptionCollection: Array<any> = new Array<any>();
    private heatCoolLimitsHisData = new Map<string, any>();
    hoverZoneData: any;
    modbusEquipId: any;
    systemPoints: any;

    ccuSetting: any = {
        master: null,
        systemMode: null,
        status: null,
        desiredComfortIndex: 5,
        compensateHumidity: null,
        demandResponse: null,
        minHumidity: null,
        maxHumidity: null,
        equipStatus: null,
        occStatus: null,
        supplyAirflowTempCurrent: null,
        supplyAirflowTempSetpoint: null,
        ductStaticPressureCurrent: null,
        ductStaticPressureSetPoint: null,
        occupancyStatus: null,
        oao: null,
        smartPostpurge: null,
        smartPrepurge: null,
        demandResponseActivation: null,
        enhancedVentilation: null,
    };
    systemReconfigProfileName: any;
    systemReconfigTitle: any = '';
    profileReconfigCheck: boolean;
    private getUserSettingDataSubscriptionCollection: any = {};
    delayChanges = new Subject();
    siteTz: string;
    siteTimeZones = {}
    buildingMaster: any;
    ccuSettingObj: any = [];
    graphWidth: any;
    xCords: any = [];
    selectedDateRange: any;
    widgetData: any = [];
    accordionHeaderStyle: any = {
        firstLevel: {
            'font-size': '1rem',
            'font-weight': '700',
            'text-transform': 'capitalize',
        },
        secondLevel: {
            'font-size': '0.8rem',
            'margin': '2px 0',
            'font-weight': '500',
            'color': '#231f20',
            'text-transform': 'capitalize',
        },
    };
    partnerName:any;
    taggedFilesListSystemProfile: any;
    enableViewAssetsSystemProfile: boolean = false;
    showPredefinedEquipGraphic: any = {};
    onGraphicListChangedEvent: any; 
    loadAnalytics: boolean = false;
    
    constructor(
        public apiService: ApiService,
        public deviceHelper: DeviceHelperService,
        public helperService: HelperService,
        public heatmapService: HeatmapService,
        private siteService: SiteService,
        public runTimeGraphsService: RuntimeGraphService,
        public heatMapToolTipService: HeatMapToolTipService,
        public graphService: GraphService,
        public unitService: UnitService,
        private titleService: Title,
        private commonService: CommonService
    ) { }

    ngOnInit(): void {
        this.partnerName = environment.partnerName;
        this.loadAnalytics = false;
        this.init();
    }

    init() {
        this.getSiteInfo(this.qrCodeMetaData.siteRef);
        this.getBuildingFloors(this.qrCodeMetaData.siteRef);
    }

    /**
     * Fetches the building floors for a given site reference.
     * @param siteRef - The reference ID of the site.
     */
    async getBuildingFloors(siteRef: string) {
        let query = `(room or ccu or floor or equip or device or schedule or schedulable or scheduleType or vacation or (building and occupancy) or (schedulable and default) or (domainName==@ccuConfiguration) or (building and limit and (min or max))) and siteRef==@${this.qrCodeMetaData.siteRef}`;

        this.apiService.getEntitiesInBuilding(query).pipe(
            map(this.helperService.listEntities),
            map(this.helperService.listZones),
            map(this.helperService.createEntityStructure),
        ).subscribe(
            async (response: any) => {
                this.buildingMaster = response;
                const entities = ArrayUtil.deepFlatten(response, 'entities');
                this.buildings['entites'] = entities;
                this.buildings['devices'] = this.getNodes(response, ['device']);
                this.buildings['equips'] = this.getNodes(response, ['equip']);
                this.buildings['buildingOccupancy'] = [];
                this.buildings['schedulable'] = this.buildings['entites'].filter(equip => ['schedulable'].every(elem => equip.markers.indexOf(elem) > -1))
                
                this.allDevices = this.getNodes(entities, ['cm', 'device']);
                this.buildings.tuners = this.helperService.getBuildingComponents(this.buildingMaster, 'equip') || [];
                this.buildings.ccus = this.buildings.floorCcu = this.helperService.getBuildingComponents(this.buildingMaster, 'ccu');
                const allZones = this.getNodes(entities, ['room']);
                this.buildings.zones = allZones;
                let ccuData = this.buildings.ccus.filter(ccu => ccu._id == this.qrCodeMetaData.id)[0];
                this.titleService.setTitle(ccuData.name);
                let ccuConfigRef = await lastValueFrom(this.apiService.findByQuery(`siteRef==@${siteRef} and ccuRef==@${ccuData._id} and domainName==@ccuConfiguration`));
                let vavDabConncetModule = await lastValueFrom(
                    this.apiService.getHaystackDataByQuery(
                      `siteRef==@${ccuData.referenceIDs.site} and ahuRef==@${ccuData.referenceIDs.ahu} and (domainName==@dabAdvancedHybridAhuV2_connectModule or domainName==@vavAdvancedHybridAhuV2_connectModule)`
                    )
                );
                let ccuObj = {
                    "ccuId": ccuData._id,
                    "siteRef": ccuData.referenceIDs.site,
                    "ccuName": ccuData.name,
                    "ccuAhu": ccuData.referenceIDs.ahu,
                    "equipRef": ccuData.referenceIDs.equip,
                    "gateway": ccuData.referenceIDs.gateway,
                    "oaoRef": ccuData.referenceIDs.oaoRef,
                    "bypassDamperId": ccuData.referenceIDs.bypassDamperId,
                    "ccuConfigRef": this.helperService.stripHaystackTypeMapping(ccuConfigRef?.rows?.[0]?.id),
                    "vavDabConncetModuleRef": this.helperService.stripHaystackTypeMapping(vavDabConncetModule?.rows?.[0]?.id)                         
                }

                if(ObjectUtil.isNotUndefinedOrNull(ccuObj.vavDabConncetModuleRef)) {
                    let checkConnectModeuleOaoIsPaired = await lastValueFrom(this.apiService.findByQuery(`equipRef==@${ccuObj.vavDabConncetModuleRef} and domainName==@oaoDamper`));
                    if(checkConnectModeuleOaoIsPaired.rows.length) {
                        this.oaoConnectModule = true
                    }
                }
                this.getSystemSettingsOnClick(ccuObj);

            }, () => {

            }
        );
        this.refs.siteRef = siteRef;
    }

    

    /**
     * Retrieves nodes from an array based on the provided tags.
     * 
     * @param arr - The array of nodes to filter.
     * @param tags - The tags to match against the nodes' markers.
     * @returns An array of nodes that match all the provided tags.
     */
    getNodes(arr, tags) {
        if (!arr || (Array.isArray(arr) && !arr.length)) {
            return [];
        }
        return arr.filter((_filterItem) => _filterItem)
            .filter((_item) => {
                const found = (_item.markers && Array.isArray(_item.markers)) ? tags.every(elem => _item.markers.indexOf(elem) > -1) : false;
                if (found) {
                    return _item;
                }
            });
    }

    /**
     * Sets the x-axis coordinates.
     * 
     * @param xVals - The array of x-axis values.
     */
    setXAxisCords(xVals: any) {
        if (xVals && xVals.length) {
            // clean up any item from previous selection
            this.xCords = [];
            xVals.forEach((x, i) => {
                if (x.textContent.toString() != '' && !x.textContent.toString().includes('.')) {
                    this.xCords[i] = x.textContent.toString();
                }
            });
        }
    }

    /**
     * Retrieves site information based on the provided site reference.
     * 
     * @param {string} siteRef - The reference of the site.
     * @returns {void}
     */
    getSiteInfo(siteRef) {
        this.apiService.getSiteById(siteRef).subscribe(({ rows }) => {
            if (rows.length) {
                // got data for site
                if (rows[0] && rows[0].tz) {
                    this.siteTz = rows[0].tz;
                    this.siteTimeZones[siteRef] = this.apiService.getIANATimeZone(this.siteTz);
                }
                this.refs['siteDetails'] = rows[0];
            }
        });
    }

    /**
     * Retrieves system settings based on the provided ccuObj.
     * 
     * @param ccuObj - The ccuObj containing the ccuId used for querying system settings.
     * @returns A Promise that resolves to the retrieved system settings.
     * @throws If an error occurs during the retrieval process.
     */

    async getSystemSettingsOnClick(ccuObj: any) {
        try {
            this.widgetData = await lastValueFrom(this.apiService.findByQuery('ccuRef==@' + ccuObj.ccuId).pipe(
                map(this.helperService.stripHaystackTypeMapping),
                map(this.helperService.listEntities),
                map(this.helperService.listTuners),
                map(this.helperService.createEntityStructure)));
        } catch (error) {
            this.widgetData = [];
        }

        this.getSystemprofileEquiNames();

        this.checkWidgestLoaded = {};

        this.intializeDate();

        this.refs.ccuName = ccuObj.ccuName;
        this.refs.ahuRef = ccuObj.ccuAhu;
        this.refs.siteRef = ccuObj.siteRef;
        this.refs.ccuId = ccuObj.ccuId;
        this.refs.oaoRef = ccuObj.oaoRef;
        this.refs.bypassDamperId =  ccuObj.bypassDamperId;
        this.refs.ccuConfigRef = ccuObj?.ccuConfigRef;
        this.refs.equipRef = ccuObj.equipRef;
        this.refs.gateway = ccuObj.gateway;
        this.refs.vavDabConncetModuleRef = ccuObj.vavDabConncetModuleRef;

        this.loadAnalytics = true;

        if(this.oaoConnectModule) {
            ccuObj.connectModuleOAOCheck = true;
        }
        if (this.getOAOEquip()) {
            ccuObj.ccuOAOCheck = true;
        }
        if (this.getBypassDamper()) {
            ccuObj.ccuBypassDamperCheck = true;
        }

        this.fetchSysDetails(ccuObj);
    }
    /**
     * Initializes the date range for the system layout component.
     * If `siteTz` is defined, it retrieves the IANA time zone using `siteService.getIANATimeZone`.
     * Sets the `selectedDateRange` object with the current start and end dates in the specified time zone.
     * Updates the `heatMapToolTipService.selectedDateRange` with the new `selectedDateRange`.
     */

    intializeDate() {
        if (this.siteTz) {
            const tz: string = this.siteService.getIANATimeZone(this.siteTz);

            this.selectedDateRange = {
                "startDate": dayjs().tz(tz).startOf('day'),
                "endDate": dayjs(Date.now()).tz(tz),
                "timeZone": tz
            }
            this.heatMapToolTipService.selectedDateRange = this.selectedDateRange;
        }
    }

    /**
     * Retrieves the system profile equipment names.
     * 
     * @remarks
     * This method subscribes to the `systemProfileSubject` and retrieves the system profile equipment names.
     * It sets the `equipGraphicSystemProfile` property to the value of the `systemProfileName` property from the system profile.
     * It also sets the `equipGraphicsProfiles` property to the result of calling the `getSystemProfileName` method with the `systemProfileName` as an argument.
     * If the `equipGraphicsProfiles` is equal to 'Default System Profile', the `showEquipGraphics` property is set to false.
     * Otherwise, the `showEquipGraphics` property is set to true.
     * Finally, it calls the `getSystemProfileConfig` method.
     */
    getSystemprofileEquiNames() {
        this.deviceHelper.systemProfileSubject.pipe(first()).subscribe((systemProfile) => {
            this.equipGraphicSystemProfile = systemProfile['systemProfileName']
            this.equipGraphicsProfiles = this.getSystemProfileName(systemProfile['systemProfileName'])
            if ((this.equipGraphicsProfiles.toLowerCase() == 'Default System Profile'.toLowerCase()) || ["VVT-C External Ahu", "DAB External Ahu","VAV External Ahu"].map(item => item.toLowerCase()).indexOf(this.equipGraphicsProfiles.toLowerCase()) > -1) {
                this.showEquipGraphics = false
            } else {
                this.showEquipGraphics = true
            }
            this.isEquipGraphicsLoading = false;
            this.getSystemProfileConfig()
        });
    }

    /**
     * Fetches system details.
     * 
     * @param ccuObj - The CCU object.
     * @returns A promise that resolves when the system details are fetched.
     */
    async fetchSysDetails(ccuObj) {
        this.equipGraphicData = []
        this.equipGraphicSystemProfile = ''
        this.systemReconfigurationSettings = null;
        this.refs.equipRef = ccuObj.equipRef;
        this.refs.gateway = ccuObj.gateway;
        let humidifierEnabled: any;
        const equipObj = this.getEquip(['system', 'equip', 'profile']);
        const hasDomainName = equipObj?.markers?.includes('domainName');
        this.isdomainName = hasDomainName;
        let connectivityWidgetDetails = this.getConnectivityWidgetStatus();
        const humidifierRef = this.helperService.getPointIdbyTags(equipObj, ['point', 'system', 'config', 'enabled', 'output', 'sp', 'humidification'])
        if (humidifierRef.length > 0) {
            await this.apiService.getWritablePointData(humidifierRef[0]).subscribe((response) => {
                let humidityValue = response.rows[0];
                if (this.helperService.stripHaystackTypeMapping(humidityValue.val).split(' ')[0] == 1) {
                    humidifierEnabled = true
                    this.systemWidgets = this.deviceHelper.getSystemWidgets(this.refs.siteRef, ccuObj, true, humidifierEnabled, connectivityWidgetDetails);
                }
            });
        }
        this.systemWidgets = this.deviceHelper.getSystemWidgets(this.refs.siteRef, ccuObj, true, humidifierEnabled, connectivityWidgetDetails);
        this.checkFilesTaggedforProfile(ccuObj.ccuId);
        const subs_sysProfile = this.deviceHelper.systemProfileSubject.subscribe(systemProfile => {
            if (systemProfile) {
                if (systemProfile.ccuRef != ccuObj.ccuAhu) {
                    return
                }
                // Runtime graphs logic
                if (this.runTimeGraphsService.runTimeProfile.size > 0 && this.runTimeGraphsService.runTimeProfile.has(this.refs.ahuRef)) {
                    this.systemWidgets[0] = this.runTimeGraphsService.runTimeProfile.get(this.refs.ahuRef);
                    this.loadRuntimeWidgetData(this.refs.ahuRef);
                } else {
                    const subs_runtimeSub = this.deviceHelper.systemRuntimeSubject.subscribe(runtimeProfile => {
                        if (runtimeProfile) {
                            // We can directly use after fetching once
                            this.runTimeGraphsService.runTimeProfile.set(runtimeProfile.ahuRef, runtimeProfile.widgetData);
                            this.systemWidgets[0] = runtimeProfile.widgetData;
                            this.loadRuntimeWidgetData(runtimeProfile.ahuRef);
                            subs_runtimeSub.unsubscribe();
                            subs_sysProfile.unsubscribe();
                        }
                    });
                }

                if (this.checkForAdvanceAhuProfiles.indexOf(systemProfile.systemProfileName) > -1) {
                    (async () => {
                        try {
                            const staticPreesurePoint = await this.toogleCheckforStaticPressurePoint();
                            const co2BasedDamperControlOn = await this.co2BasedDamperControlOn();
                            this.deviceHelper.getSystemProfileRuntimeWidgetData(
                                systemProfile.systemProfileName,
                                this.refs.siteRef,
                                this.refs.ahuRef,
                                false,
                                this.refs.gateway,
                                this.refs.ccuId,
                                this.refs.ccuName,
                                false,
                                staticPreesurePoint,
                                co2BasedDamperControlOn
                            );
                        } catch (error) {
                            console.error("Error:", error);
                        }
                    })();
                } else {
                    this.deviceHelper.getSystemProfileRuntimeWidgetData(systemProfile.systemProfileName, this.refs.siteRef, this.refs.ahuRef, false, this.refs.gateway, this.refs.ccuId, this.refs.ccuName,hasDomainName,null,null);
                }
                this.onSystemMode = false;


                if (systemProfile.systemProfileName !== "vavAdvancedHybridAhuV2" && systemProfile.systemProfileName.includes('VAV') || systemProfile.systemProfileName.includes('vav')) {
                    this.systemWidgets[this.systemWidgets.length - 1] = (new PucSystemVavComfortIndexGraphWidget()).getGraphWidget();
                } else if (systemProfile.systemProfileName !== "dabAdvancedHybridAhuV2" && systemProfile.systemProfileName.includes('DAB') || systemProfile.systemProfileName.includes('dab')) {
                    this.systemWidgets[this.systemWidgets.length - 1] = (new PucSystemDabComfortIndexGraphWidget()).getGraphWidget();
                } else if (systemProfile.systemProfileName.includes('DEFAULT')) {
                    // for defualt profile remove comfortindex and modes widget
                    this.systemWidgets = this.systemWidgets.filter(widget => widget.id != 'comfortIndex');
                    this.systemWidgets = this.systemWidgets.filter(widget => widget.id != 'modes');
                    this.systemWidgets = this.systemWidgets.filter(widget => widget.id != 'runtimesystemprofile');
                }
            } else {
                // means no runtime for this profile , fetch the data
                // All points have been fetched , get bulk read
                this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange);
            }
        });
        // load system widgets data
        this.loadSystemWidgetsData();
        this.getCcuSettings(this.refs.ahuRef);
        // get system profile type
        this.getSystemProfileType();
        setTimeout(() => {
            this.getCCUSettingData();
        }, 1000);


        this.graphWidth = window.innerWidth;
        this.systemReconfigurationSettings = null;
        this.systemReconfigProfileName = '';
        this.systemReconfigTitle = '';
        this.getSystemProfileConfig();


    }


    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.graphWidth = window.innerWidth;
    }

    /**
     * Retrieves the name of the system profile based on the given profile value.
     * 
     * @param {string} profile - The profile value.
     * @returns {string} - The name of the system profile.
     */
    getSystemProfileName(profile) {
      return this.commonService.getSystemProfileName(profile);
    }

    /**
     * Retrieves the system profile configuration.
     * 
     * This method retrieves the necessary data for the system configuration setting based on the system profile.
     * It fetches the equipment object, OAO object, and graphic points data.
     * Then, it loops through the profile tags array and retrieves the required data for each profile tag.
     * If the profile tag key is 'dcwbEnabled', it calls the getZoneSettings method with specific parameters.
     * Otherwise, it calls the getZoneSettings method with different parameters.
     * If the OAO object exists, it loops through the OAO profile tags array and retrieves the required data for each profile tag.
     * Finally, it calls the getUserSettingData method with the 'reConfig' parameter.
     */
    getSystemProfileConfig() {
        const equipObj = this.getEquip(['system', 'equip', 'profile']) || [];
        const OAOObj = this.getOAOEquip();
        // below is the method to get Equip Graphic points data;
        if ((this.equipGraphicSystemProfile !== 'dabExternalAHUController') || (this.equipGraphicSystemProfile !== 'vavExternalAHUController')) {
            this.getEquipGraphicPoints();
        }
        // Loop all the profile tags array and get the required data for the system configuration setting based on system profile.
        systemProfileTagsArray.forEach((_profileObj: any) => {
            const key = _profileObj.key;
            if (key == 'dcwbEnabled') {
                this.getZoneSettings(key, this.helperService.getPointIdbyTags(equipObj, systemProfileFilterTags.tags, null, systemProfileFilterTags.filterTags, _profileObj?.domainName), _profileObj.action);
            } else {
                this.getZoneSettings(key, this.helperService.getPointIdbyTags(equipObj, _profileObj?.tags, null, null, _profileObj?.domainName), _profileObj.action);
            }
        });
        if (OAOObj) {
            /** OAO **/
            systemProfileOaoTagsArray.forEach(_profileObj => {
                if (_profileObj.key == 'outsideminopen') {
                    this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(OAOObj, systemProfileFilterOAOTags.tags, null, systemProfileFilterOAOTags.filterTags), _profileObj.action);
                } else {
                    this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(OAOObj, _profileObj.tags), _profileObj.action);
                }
            });
        }
        this.getUserSettingData('reConfig');
    }

    getEquip(equipTags: any) {
        return this.widgetData.find(equip => equipTags.every(elem => equip.markers.indexOf(elem) > -1) && equip._id == this.refs.ahuRef);
    }

    /**
     * Loads the system widgets data.
     * Retrieves equipment, diagnostic, site, OAO, BTU, EMR, Modbus, and bypass damper objects.
     * Sets the application version.
     * Retrieves system widgets data using the obtained objects.
     * Sets the properties for the obtained objects.
     */
    loadSystemWidgetsData() {
        let OAOObj
        if(this.oaoConnectModule) {
            OAOObj = this.widgetData.find(et => et._id == this.refs.vavDabConnectModuleRef);
        } else {
            OAOObj = this.getOAOEquip();
        }
        let equipObj = this.getEquip(['system', 'equip', 'profile']) || [];
        let diagnosticObj = this.getDiagObj();
        let siteObj = this.getSiteObj(['tuner', 'equip']);
        const btuObj = this.getBtuObj();
        const getEMRObj = this.getEMRObj();
        const getModbusObj = this.getModbusObj();
        const getBypassBamper = this.getBypassDamper();
        const ccuConfigObj = this.equipConfig('ccuConfiguration');
        // get ccu version
        const appVerId = this.helperService.getPointIdbyTags(diagnosticObj, ['app', 'diag', 'version'])[0] || '';
        this.version = '';
        this.getSystemWidgetsData(equipObj, diagnosticObj, siteObj, OAOObj, btuObj, getEMRObj, getModbusObj, getBypassBamper, ccuConfigObj)
        //Set the properties
        this.getPointProperties(equipObj);
        this.getPointProperties(siteObj);
        this.getPointProperties(diagnosticObj);
        if(!this.oaoConnectModule) {
            OAOObj ? this.getPointProperties(OAOObj) : '';
        }
        getBypassBamper ? this.getPointProperties(getBypassBamper) : '';
    }

    /**
     * Retrieves the properties of a point.
     * 
     * @param dataObj - The data object containing the entities.
     */
    getPointProperties(dataObj) {
        if (this.graphService.graphPointsCollection.size > 0) {
            if (dataObj && dataObj.entities) {
                dataObj.entities.map(entity => {
                    this.graphService.graphPointsCollection.forEach((value, key) => {
                        if (value == entity._id) {
                            this.setGraphEnumUnit(entity, value, key);
                        }
                    });
                });
            }
        }
    }


    /**
     * Retrieves the diagnostic object from the widget data.
     * 
     * @returns The diagnostic object that matches the specified conditions.
     */
    getDiagObj() {
        return this.widgetData.find(equip => ['equip', 'diag'].every(elem => equip.markers.indexOf(elem) > -1) && (equip.referenceIDs.gateway == this.refs.gateway || equip.referenceIDs.ahu == this.refs.ahuRef));
    }

    /**
     * Retrieves the EMR object from the widget data.
     * 
     * @returns The EMR object that matches the specified conditions.
     */
    getEMRObj() {
        return this.widgetData.find(equip => ['system', 'emr', 'equip', 'profile'].every(elem => equip.markers.indexOf(elem) > -1) && (equip.referenceIDs.gateway == this.refs.gateway || equip.referenceIDs.ahu == this.refs.ahuRef));
    }

    /**
     * Retrieves the BTU object from the widget data.
     * 
     * @returns The BTU object that matches the specified conditions.
     */
    getBtuObj() {
        return this.widgetData.find(equip => ['system', 'btu', 'equip', 'profile'].every(elem => equip.markers.indexOf(elem) > -1) && (equip.referenceIDs.gateway == this.refs.gateway || equip.referenceIDs.ahu == this.refs.ahuRef));
    }

    /**
     * Retrieves the Modbus object from the widget data.
     * 
     * @returns The Modbus object that matches the specified conditions.
     */
    getModbusObj() {
        return this.widgetData.find(equip => ['system', 'modbus', 'equip'].every(elem => equip.markers.indexOf(elem) > -1) && (equip.referenceIDs.gateway == this.refs.gateway || equip.referenceIDs.ahu == this.refs.ahuRef));
    }

    /**
     * Retrieves the bypass damper from the widget data.
     * 
     * @returns The bypass damper object if found, otherwise undefined.
     */
    getBypassDamper() {
        return this.widgetData.find(equip => ['bypassDamper'].every(elem => equip.markers.indexOf(elem) > -1));
    }

    /**
     * Retrieves the OAO equipment from the widget data.
     * 
     * @returns The OAO equipment object that matches the specified conditions.
     */
    getOAOEquip() {
        return this.widgetData.find(equip => ['oao', 'equip', 'profile'].every(elem => equip.markers.indexOf(elem) > -1) && (equip.referenceIDs.gateway == this.refs.gateway || equip.referenceIDs.ahu == this.refs.ahuRef));
    }




    /**
     * Retrieves the site object based on the provided equipment tags.
     * 
     * @param equipTags - The equipment tags to filter the site objects.
     * @returns The site object that matches the provided equipment tags.
     */
    getSiteObj(equipTags: any) {
        return this.buildings.equips
            .filter(equip => equipTags.every(elem => equip.markers.indexOf(elem) > -1))[0];
    }

    /**
     * Retrieves the connectivity status of various components.
     * 
     * @returns An object containing the connectivity status of different components:
     * - `oao`: The connectivity status of OAO.
     * - `btu`: The connectivity status of BTU.
     * - `emr`: The connectivity status of EMR.
     * - `cloud`: The connectivity status of the cloud.
     */
    getConnectivityWidgetStatus() {
        const OAOObj = this.getOAOEquip();
        const btuObj = this.getBtuObj();
        const getEMRObj = this.getEMRObj();
        const diagnosticObj = this.getDiagObj();
        let connectivity = {
            oao: '',
            btu: '',
            emr: '',
            cloud: ''
        }
        //contectivity
        if (OAOObj) {
            connectivity.oao = this.helperService.getPointIdbyTags(OAOObj, ['heartbeat', 'oao'])[0];
        }
        if (btuObj) {
            connectivity.btu = this.helperService.getPointIdbyTags(btuObj, ['heartbeat', 'btu'])[0];
        }
        if (getEMRObj) {
            connectivity.emr = this.helperService.getPointIdbyTags(getEMRObj, ['heartbeat', 'emr'])[0];
        }
        if (diagnosticObj) {
            connectivity.cloud = this.helperService.getPointIdbyTags(diagnosticObj, ['cloud', 'diag', 'connected'])[0];
        }
        return connectivity;
    }

    /**
     * Sets the graph enum unit for the given entity, value, and key.
     * 
     * @param entity - The entity object.
     * @param value - The value to replace in the key.
     * @param key - The key to modify.
     */
    setGraphEnumUnit(entity, value, key) {
        let unitCategory: boolean = false;
        let unit;

        if (entity.markers.includes("predefined")) {
            this.graphService.graphPointsUnitsCollection.set(key.replace(value, ''), 'V');
            this.graphService.graphPointsEnumCollection.set(key.replace(value, ''), entity.enum);
        } else {
            this.graphService.graphPointsUnitsCollection.set(key.replace(value, ''), unitCategory ? unit : entity.unit);
            this.graphService.graphPointsEnumCollection.set(key.replace(value, ''), entity.enum);
        }
    }

    /**
     * Retrieves system widgets data based on the provided objects.
     * 
     * @param equipObj - The equipment object.
     * @param diagnosticObj - The diagnostic object.
     * @param siteObj - The site object.
     * @param OAOObj - The OAO object.
     * @param btuObj - The BTU object.
     * @param getEMRObj - The EMR object.
     * @param getModbusObj - The Modbus object.
     * @param getBypassBamper - The bypass damper object.
     * @param ccuConfigObj - The CCU configuration object.
     */
    getSystemWidgetsData(equipObj, diagnosticObj, siteObj, OAOObj, btuObj, getEMRObj, getModbusObj, getBypassBamper, ccuConfigObj) {
        let sysObj: any;
        for (let i = 0; i < systemprofileWidgets.length; i++) {
            const systemProfileObj = systemprofileWidgets[i];

            if (systemProfileObj.objType == 'equipObj' || (systemProfileObj.objType == 'equipObj' && systemProfileObj.key == 'ConditionMode')) {
                sysObj = equipObj;
            } else if (systemProfileObj.objType == 'OAOObj' && OAOObj) {
                sysObj = OAOObj;
            } else if (systemProfileObj.objType == 'diagnosticObj') {
                sysObj = diagnosticObj;
            } else if (systemProfileObj.objType == 'btuObj') {
                sysObj = btuObj;
            } else if (systemProfileObj.objType == 'getEMRObj') {
                sysObj = getEMRObj;
            } else if (systemProfileObj.objType == 'modbusObj') {
                sysObj = getModbusObj;
            } else if (systemProfileObj.objType == 'ccuConfigObj') {
                sysObj = ccuConfigObj
            } else if (systemProfileObj.objType == 'bypassDamperObj') {
                sysObj = getBypassBamper;
            } else if (systemProfileObj.objType == "schedulable" && this.showSchedulerOccupancy) {
                sysObj = this.buildings.schedulable;
            } else if (systemProfileObj.objType == "notSchedulable" && !this.showSchedulerOccupancy) {
                sysObj = siteObj;
            }

            this.getPointId(systemProfileObj.key, this.helperService.getPointIdbyTags(sysObj, systemProfileObj.tags ? systemProfileObj.tags : null, null, null, systemProfileObj?.domainName)[0]);
        }

    }


    /**
     * Loads the runtime widget data for the specified equipment reference.
     * 
     * @param equipRef - The equipment reference.
     */
    loadRuntimeWidgetData(equipRef: string) {
        if (this.runTimeGraphsService.cmBoardPortsMappingsForCollection.get(equipRef)) {
            this.runTimeGraphsService.cmBoardPortsMappingsForCollection.get(equipRef).forEach((value, key) => {
                this.getPointId(key, value);
            });
        }
        if (this.runTimeGraphsService.cmBoardPortsMappingsCollection.get(equipRef)) {
            this.runTimeGraphsService.cmBoardPortsMappingsCollection.get(equipRef).forEach((value, key) => {
                if (value.isEnabled) {
                    this.getPointId(value.param, value.ref);
                }
            });
        }

        if (this.runTimeGraphsService.fullyModulatingProfileTagsCollection.get(equipRef)) {
            this.runTimeGraphsService.fullyModulatingProfileTagsCollection.get(equipRef).forEach((value, key) => {
                if (value.isEnabled) {
                    this.getPointId(value.param, value.ref);
                }
            });
        }

        // All points have been fetched , get bulk read
        this.fetchTimeSeriesData(this.selectedDateRange);

        // Fetch the properties
        const btuObj = this.getBtuObj();
        const equipObj = this.getEquip(['system', 'equip', 'profile']) || [];
        this.getPointProperties(equipObj);
        this.getPointProperties(btuObj);
    }


    /**
     * Retrieves the point ID based on the provided key and point reference.
     * @param key - The key used to identify the point.
     * @param pointRef - The reference of the point.
     * @returns An array containing the point ID.
     */
    getPointId(key: string, pointRef: string) {
        const id: Array<string> = new Array<string>();
        if (!pointRef) return;
        // change the check from key -> pointRef by kuladeep 3.11.19
        if (this.graphService.graphPointsCollection.has(key + pointRef)) {
            id.push(this.graphService.graphPointsCollection.get(key + pointRef));
        } else {
            this.graphService.graphPointsCollection.set(key + pointRef, pointRef);
            id.push(pointRef);
        }

        return id;
    }
    /**
     * Fetches time series data for a given date range and optional adIdentifier.
     * @param daterange - The date range for which to fetch the data.
     * @param adIdentifier - An optional identifier for additional data.
     */

    fetchTimeSeriesData(daterange, adIdentifier: string = '') {
        let type = 'ccu'
        // site timezone
        const tz: string = this.apiService.getIANATimeZone(this.siteTz);
        const utcOffset = dayjs().tz(tz).utcOffset();
        const id = (type == 'ccu' ? this.refs.ahuRef : this.refs.roomRef) + adIdentifier + daterange.startDate.toString() + '-' + daterange.endDate.toString(); // this will serve as unique id subs handling
        // Set up check for download widgets status of data fetch
        this.graphService.graphPointsCollection.forEach((singlePoint, key) => {
            if (singlePoint != undefined) {
                this.checkWidgestLoaded[key.replace(singlePoint, '')] = false;
            }
        });


        const alreadySubscribed = (this.subscriptionCollection.filter(subs => {
            return subs.id == id;
        }).length > 0);

        if (!alreadySubscribed) {
            let pointList = [...this.graphService.graphPointsCollection.values()].filter(notUndefined => notUndefined !== undefined);
            if (pointList.length > 0) {
                const hisReadManySubject = this.apiService.gethisReadManyInterpolate(
                    pointList,
                    daterange,
                    tz
                ).subscribe((res) => {
                    if (res != undefined) {
                        res = this.helperService.stripHaystackTypeMapping(res);
                        let rows = [];
                        if (res && res['rows']) {
                            rows = this.filterTimeSeriesData(res);
                        }
                        const tempZones = [];

                        if (rows) {
                            this.graphService.graphPointsCollection.forEach((singlePoint, key) => {
                                // singlePoint
                                if (singlePoint != undefined) {
                                    const filteredPointResponse = ObjectUtil.deepClone(rows.filter((x) => {
                                        const ref = this.helperService.stripHaystackTypeMapping(x.id).split(' ')[0];
                                        if ((typeof (singlePoint) != undefined) && ref.includes(singlePoint)) { return x; }
                                    })[0]);

                                    if (filteredPointResponse) {
                                        let hisData = [];
                                        hisData = [].concat(filteredPointResponse.data).map((row => {
                                            const dateWithTimezone = dayjs(row.ts.replace(` ${filteredPointResponse.tz}`, '')).tz(tz);
                                            row.val = row['val'] ? row['val'].replace(filteredPointResponse.unit, '') : null;
                                            row.ts = dateWithTimezone.format('YYYY-MM-DDTHH:mm:ss');
                                            if (row.val !== '' && row.ts != '') {
                                                return row;
                                            }
                                        }));

                                        const zoneType = key.replace(singlePoint, '');

                                        // Handle cooling and heating user limits for multi module
                                        if (zoneType.includes('heatingUserLimitMin') || zoneType.includes('heatingUserLimitMax') || zoneType.includes('coolingUserLimitMax') || zoneType.includes('coolingUserLimitMin')) {
                                            let tempHisData = [];
                                            if (!this.heatCoolLimitsHisData.has(singlePoint)) {
                                                // Cache the limits in service
                                                tempHisData = hisData.map(x => Object.assign({}, x));
                                                this.heatCoolLimitsHisData.set(singlePoint, tempHisData);
                                            } else {
                                                // Check if it exists in cache in case of multi module
                                                tempHisData = this.heatCoolLimitsHisData.get(singlePoint);
                                            }
                                            tempZones[zoneType] = tempHisData;
                                        }
                                        else {
                                            let tempHisData = [];
                                            tempHisData = hisData.map(x => Object.assign({}, x));
                                            tempZones[zoneType] = tempHisData;
                                        }
                                    }
                                }
                            });
                            this.subscriptionCollection.find(subs => subs.id == id).subscription.unsubscribe();

                            
                        }
                        this.graphService.setData(tempZones);
                        if (this.systemWidgets && this.systemWidgets[0] && this.systemWidgets[0]?.name?.includes('Default System Profile')) {
                            this.heatMapToolTipService.deletefromOrigGraphData('comfortIndex' + this.refs?.ahuRef);
                            this.heatMapToolTipService.deletefromOrigGraphData('modes' + this.refs?.ahuRef);
                            // Hide the accordian
                            const runtimeAccordian = (<HTMLInputElement>document.getElementById('accordian_' + this.systemWidgets[0]?.id + this.refs?.ahuRef));
                            runtimeAccordian.click();
                        }
                    }
                }, noData => {
                    this.graphService.resetDataonInvalidDate();
                });

                this.subscriptionCollection.push({ id: id, subscription: hisReadManySubject });
            }
        }
    }

    /**
     * Filters the time series data.
     * 
     * @param data - The data to be filtered.
     * @returns The filtered data.
     */
    filterTimeSeriesData(data) {
        let res = data['rows'];
        res = this.convertStagedFanValuetoVolt(res);
        let dataUnitExist = res?.filter(item => this.unitService.unitListArray.includes(item.unit)) || [];
        const dataUnitNotExist = res?.filter(item => !this.unitService.unitListArray.includes(item.unit)) || [];
        if (dataUnitExist.length > 0) {
            dataUnitExist = this.convertDataOnUnit(dataUnitExist);
        }
        return dataUnitNotExist.concat(dataUnitExist);
    }

    /**
     * Retrieves the CCU settings for a given CCU/AHU.
     * 
     * @param ccuAhu - The CCU/AHU identifier.
     * @returns An array of system points with their corresponding properties.
     */
    getCcuSettings(ccuAhu: any) {
        const equipObj = this.getEquip(['system', 'equip', 'profile']) || [];
        const systemMode = equipObj ? this.helperService.getPointIdbyTags(equipObj, ['sp', 'system', 'mode', 'rtu'])[0] : null;
        const systemPoints = [
            {
                pointName: 'systemMode',
                query: `(equipRef==@${ccuAhu})`,
                type: 'write',
                tags: ['system', 'userIntent', 'mode', `${!systemMode ? 'conditioning' : 'rtu'}`],
                domainName: "conditioningMode"
            },
            {
                pointName: 'cloudLastUpdated',
                query: `(equipRef==@${this.refs.equipRef})`,
                type: 'read',
                tags: ['diag', 'cloud', 'connected']
            },
            {
                pointName: 'desiredComfortIndex',
                type: 'write',
                tags: ['system', 'userIntent', 'ci', 'desired']
            },
            {
                pointName: 'demandResponse',
                query: `(equipRef==@${this.refs.ccuConfigRef})`,
                type: 'write',
                tags: ['userIntent', 'demand', 'system', 'response'],
                domainName: "demandResponseEnrollment"
            },
            , {
                pointName: 'demandResponseActivation',
                query: `(equipRef==@${this.refs.ccuConfigRef})`,
                type: 'write',
                tags: ['demand', 'activation', 'response'],
                domainName: "demandResponseActivation"
            },
            {
                type: 'write',
                pointName: 'minHumidity',
                tags: ['min', 'system', 'target', 'humidity'],
                domainName: "systemtargetMinInsideHumidity"
            },
            {
                tags: ['target', 'humidity', 'max', 'system'],
                pointName: 'maxHumidity',
                type: 'write',
                domainName: "systemtargetMaxInsideHumidity"
            },
            {
                pointName: 'equipStatus',
                type: 'write',
                tags: ['system', 'status', 'message'],
                domainName: "equipStatusMessage"
            }, {
                pointName: 'dualSetpointControlEnable',
                type: 'write',
                domainName: 'dualSetpointControlEnable'
            }, {
                pointName: 'airTempHeatingSp',
                type: 'read',
                domainName: 'airTempHeatingSp'
            }, {
                pointName: 'airTempCoolingSp',
                type: 'read',
                domainName: 'airTempCoolingSp'
            }, {
                pointName: 'dualSetpointControlEnable',
                type: 'write',
                domainName: 'dualSetpointControlEnable'
            }, {
                pointName: 'operatingMode',
                type: 'write',
                domainName: 'operatingMode'
            },
            {
                pointName: 'occupancyStatus',
                type: 'write',
                tags: ['system', 'scheduleStatus'],
                domainName: "equipScheduleStatus"
            },
            {
                pointName: 'occStatus',
                type: 'read',
                tags: ['occStatus']
            },

            {
                pointName: 'ductStaticPressureSetPoint',
                type: 'read',
                domainName: "ductStaticPressureSetpoint"
            },
            {
                pointName: 'supplyAirflowTempSetpoint',
                type: 'read',
                domainName: "supplyAirflowTemperatureSetpoint"
            },

            {
                pointName: 'dcvDamper',
                type: 'read',
                domainName: 'dcvDamperCalculatedSetpoint'
            }, {
                pointName: 'relay-1',
                type: 'write',
                tags: ['system', 'point', 'config', 'enabled', 'output', 'sp', 'relay1'],
                domainName: 'relay1OutputEnable'
            }, {
                pointName: 'relay-2',
                type: 'write',
                tags: ['system', 'point', 'config', 'enabled', 'output', 'sp', 'relay2'],
                domainName: 'relay2OutputEnable'
            },
            {
                pointName: 'relay-3',
                type: 'write',
                tags: ['system', 'point', 'config', 'enabled', 'output', 'sp', 'relay3'],
                domainName: 'relay3OutputEnable'
            },
            {
                pointName: 'relay-4',
                type: 'write',
                tags: ['system', 'point', 'config', 'enabled', 'output', 'sp', 'relay4'],
                domainName: 'relay4OutputEnable'
            },
            {
                pointName: 'relay-5',
                type: 'write',
                tags: ['system', 'point', 'config', 'enabled', 'output', 'sp', 'relay5'],
                domainName: 'relay5OutputEnable'
            },
            {
                pointName: 'relay-6',
                type: 'write',
                tags: ['system', 'point', 'config', 'enabled', 'output', 'sp', 'relay6'],
                domainName: 'relay6OutputEnable'
            },
            {
                pointName: 'relay-7',
                type: 'write',
                tags: ['system', 'point', 'config', 'enabled', 'output', 'sp', 'relay7'],
                domainName: 'relay7OutputEnable'
            },
            {
                pointName: 'analog-out1',
                type: 'write',
                tags: ['system', 'point', 'config', 'enabled', 'output', 'sp', 'analog1'],
                domainName: "analog1OutputEnable"
            },
            {
                pointName: 'analog-out2',
                type: 'write',
                tags: ['system', 'point', 'config', 'enabled', 'output', 'sp', 'analog2'],
                domainName: "analog2OutputEnable"
            },
            {
                pointName: 'analog-out3',
                type: 'write',
                tags: ['system', 'point', 'config', 'enabled', 'output', 'sp', 'analog3'],
                domainName: "analog3OutputEnable"
            },
            {
                pointName: 'analog-out4',
                type: 'write',
                tags: ['system', 'point', 'config', 'enabled', 'output', 'sp', 'analog4'],
                domainName: "analog4OutputEnable"
            },
            {
                pointName: 'relay-1Mapping',
                type: 'write',
                tags: ['system', 'point', 'config', 'association', 'output', 'sp', 'relay1'],
                domainName: 'relay1OutputAssociation',
            },
            {
                pointName: 'relay-2Mapping',
                type: 'write',
                tags: ['system', 'point', 'config', 'association', 'output', 'sp', 'relay2'],
                domainName: 'relay2OutputAssociation',
            },
            {
                pointName: 'relay-3Mapping',
                type: 'write',
                tags: ['system', 'point', 'config', 'association', 'output', 'sp', 'relay3'],
                domainName: 'relay3OutputAssociation',
            },
            {
                pointName: 'relay-4Mapping',
                type: 'write',
                tags: ['system', 'point', 'config', 'association', 'output', 'sp', 'relay4'],
                domainName: 'relay4OutputAssociation',
            },
            {
                pointName: 'relay-5Mapping',
                type: 'write',
                tags: ['system', 'point', 'config', 'association', 'output', 'sp', 'relay5'],
                domainName: 'relay5OutputAssociation',
            },
            {
                pointName: 'relay-6Mapping',
                type: 'write',
                tags: ['system', 'point', 'config', 'association', 'output', 'sp', 'relay6'],
                domainName: 'relay6OutputAssociation',
            },
            {
                pointName: 'relay-7Mapping',
                type: 'write',
                tags: ['system', 'point', 'config', 'association', 'output', 'sp', 'relay7'],
                domainName: 'relay7OutputAssociation',
            },
            {
                pointName: 'relay-7Mapping',
                type: 'write',
                tags: ['system', 'point', 'config', 'humidifier', 'sp', 'relay7']
            },
            //
            //VAV Advanced AHU Points
            {
                pointName: 'supplyAirflowTemperatureSetpoint',
                type: 'read',
                domainName: 'supplyAirflowTemperatureSetpoint'

            }, {
                pointName: 'ductStaticPressureSetpoint',
                type: 'read',
                domainName: 'ductStaticPressureSetpoint'
            },
            {
                pointName: 'supplyAirflowTempControl',
                type: 'write',
                domainName: 'supplyAirTempControlOn'
            }, {
                pointName: 'pressureBasedFanControl',
                type: 'write',
                domainName: 'pressureBasedFanControlOn'
            },
            //Supply Air Temp Points 
            {
                pointName: 'supplyAirTemperature1',
                type: 'read',
                domainName: 'supplyAirTemperature1'
            }, {
                pointName: 'supplyAirTemperature2',
                type: 'read',
                domainName: 'supplyAirTemperature2'
            }, {
                pointName: 'supplyAirTemperature3',
                type: 'read',
                domainName: 'supplyAirTemperature3'
            }, {
                pointName: 'averageSat',
                type: 'read',
                domainName: 'averageSat'
            }, {
                pointName: 'satSpMin',
                type: 'read',
                domainName: 'minSat'
            }, {
                pointName: 'satSpMax',
                type: 'read',
                domainName: 'maxSat'
            },
            //Duct static pressure points
            {
                pointName: 'ductStaticPressureSensor1',
                type: 'read',
                domainName: 'ductStaticPressureSensor1_2'
            },
            {
                pointName: 'ductStaticPressureSensor2',
                type: 'read',
                domainName: 'ductStaticPressureSensor2_2'
            }, {
                pointName: 'ductStaticPressureSensor3',
                type: 'read',
                domainName: 'ductStaticPressureSensor3_2'
            }, {
                pointName: 'averagePressure',
                type: 'read',
                domainName: 'averagePressure'
            }, {
                pointName: 'minPressure',
                type: 'read',
                domainName: 'minPressure'
            }, {
                pointName: 'maxPressure',
                type: 'read',
                domainName: 'maxPressure'
            }, {
                pointName: "coolingstage1",
                domainName: "loadCoolingStage1",
                type: "read",
            }, {
                pointName: "coolingstage2",
                domainName: "loadCoolingStage2",
                type: "read",
            }, {
                pointName: "coolingstage3",
                domainName: "loadCoolingStage3",
                type: "read",
            }, {
                pointName: "coolingstage4",
                domainName: "loadCoolingStage4",
                type: "read",
            }, {
                pointName: "coolingstage5",
                domainName: "loadCoolingStage5",
                type: "read",
            }, {
                pointName: "heatingstage1",
                domainName: "loadHeatingStage1",
                type: "read",
            }, {
                pointName: "heatingstage2",
                domainName: "loadHeatingStage2",
                type: "read",
            }, {
                pointName: "heatingstage3",
                domainName: "loadHeatingStage3",
                type: "read",
            }, {
                pointName: "heatingstage4",
                domainName: "loadHeatingStage4",
                type: "read",
            }, {
                pointName: "heatingstage5",
                domainName: "loadHeatingStage5",
                type: "read",
            },{
                pointName: "satcoolingstage1",
                domainName: "satCoolingStage1Feedback",
                type: "read",
            }, {
                pointName: "satcoolingstage2",
                domainName: "satCoolingStage2Feedback",
                type: "read",
            }, {
                pointName: "satcoolingstage3",
                domainName: "satCoolingStage3Feedback",
                type: "read",
            }, {
                pointName: "satcoolingstage4",
                domainName: "satCoolingStage4Feedback",
                type: "read",
            }, {
                pointName: "satcoolingstage5",
                domainName: "satCoolingStage5Feedback",
                type: "read",
            }, {
                pointName: "satheatingstage1",
                domainName: "satHeatingStage1Feedback",
                type: "read",
            }, {
                pointName: "satheatingstage2",
                domainName: "satHeatingStage2Feedback",
                type: "read",
            }, {
                pointName: "satheatingstage3",
                domainName: "satHeatingStage3Feedback",
                type: "read",
            }, {
                pointName: "satheatingstage4",
                domainName: "satHeatingStage4Feedback",
                type: "read",
            }, {
                pointName: "satheatingstage5",
                domainName: "satHeatingStage5Feedback",
                type: "read",
            }, {
                pointName: "loadbasedcoolingcontrol",
                domainName: "loadBasedCoolingControl",
                type: "read",
            }, {
                pointName: "satbasedcoolingcontrol",
                domainName: "satBasedCoolingControlFeedback",
                type: "read",
            }, {
                pointName: "satbasedheatingcontrol",
                domainName: "satBasedHeatingControlFeedback",
                type: "read",
            }, {
                pointName: "loadbasedheatingcontrol",
                domainName: "loadBasedHeatingControl",
                type: "read",
            },
            {
                pointName: 'dcvDamperControl',
                type: 'read',
                domainName: ['co2BasedDamperControl']
            },
            {
                pointName: 'advancedAhuOperatingMode',
                type: 'read',
                domainName: 'operatingMode'
            }, {
                pointName: 'compositSignal',
                type: 'read',
                domainName: 'compositeSignal'
            },
            /** */
            //Duct static pressure Points New 
            {
                pointName: 'ductStaticPressureSensor1_1',
                type: 'read',
                domainName: 'ductStaticPressureSensor1_1',
            }, {
                pointName: 'ductStaticPressureSensor1_2',
                type: 'read',
                domainName: 'ductStaticPressureSensor1_2',
            }, {
                pointName: 'ductStaticPressureSensor1_10',
                type: 'read',
                domainName: 'ductStaticPressureSensor1_10',
            },
            //
            {
                pointName: 'ductStaticPressureSensor2_1',
                type: 'read',
                domainName: 'ductStaticPressureSensor2_1',
            }, {
                pointName: 'ductStaticPressureSensor2_2',
                type: 'read',
                domainName: 'ductStaticPressureSensor2_2',
            }, {
                pointName: 'ductStaticPressureSensor2_10',
                type: 'read',
                domainName: 'ductStaticPressureSensor2_10',
            },
            //
            {
                pointName: 'ductStaticPressureSensor3_1',
                type: 'read',
                domainName: 'ductStaticPressureSensor3_1',
            }, {
                pointName: 'ductStaticPressureSensor3_2',
                type: 'read',
                domainName: 'ductStaticPressureSensor3_2',
            }, {
                pointName: 'ductStaticPressureSensor3_10',
                type: 'read',
                domainName: 'ductStaticPressureSensor3_10',
            },
            // 
            {
                pointName: 'pressureSensorBusAdd0',
                type: 'write',
                domainName: 'pressureSensorBusAdd0',
            }, {
                pointName: 'analog1InputAssociation',
                type: 'write',
                domainName: 'analog1InputAssociation',
            }, {
                pointName: 'analog2InputAssociation',
                type: 'write',
                domainName: 'analog2InputAssociation',
            },
        ];
        this.systemPoints = systemPoints;
    }


    /**
     * Converts the data on the unit and returns an array of converted points.
     * 
     * @param data - The data to be converted.
     * @returns An array of converted points.
     */
    convertDataOnUnit(data) {
        let userPreference = {};
        let temperaturePoints = data.filter(item => this.unitService.temperatureUnitListArray.includes(item.unit));
        let energyConsumptionPoints = data.filter(item => this.unitService.energyConsumptionUnitListArray.includes(item.unit));
        let airflowVolumePoints = data.filter(item => this.unitService.airflowVolumeUnitListArray.includes(item.unit));
        let airPressurePoints = data.filter(item => this.unitService.airPressureUnitListArray.includes(item.unit));
        let waterPressurePoints = data.filter(item => this.unitService.waterPressureUnitListArray.includes(item.unit));
        let waterFlowPoints = data.filter(item => this.unitService.waterFlowUnitListArray.includes(item.unit));
        if (temperaturePoints.length > 0) {
            this.temperaturepoints(temperaturePoints, userPreference)
        }
        if (energyConsumptionPoints.length > 0) {
            this.energyConsumptionPoints(energyConsumptionPoints, userPreference)
        }
        if (airflowVolumePoints.length > 0) {
            this.airflowVolumePoints(airflowVolumePoints, userPreference)
        }
        if (airPressurePoints.length > 0) {
            this.airPressurePoints(airPressurePoints, userPreference)
        }
        if (waterPressurePoints.length > 0) {
            this.waterPressurePoints(waterPressurePoints, userPreference)
        }
        if (waterFlowPoints.length > 0) {
            this.waterFlowPoints(waterFlowPoints, userPreference)
        }
        return [...temperaturePoints, ...energyConsumptionPoints, ...airflowVolumePoints, ...airPressurePoints, ...waterPressurePoints, ...waterFlowPoints];
    }


     //energyConsumptionPoints
     energyConsumptionPoints(energyConsumptionPoints, userPreference) {
        if (energyConsumptionPoints.length > 0) {
            const userEnergyConsumptionPreference = userPreference.energyConsumptionUnit;
            if (userEnergyConsumptionPreference == this.unitService.energyConsumptionUnitListArray[1] || userEnergyConsumptionPreference == this.unitService.energyConsumptionUnitListArray[2] || userEnergyConsumptionPreference == this.unitService.energyConsumptionUnitListArray[3]) {
                for (let energyPoints of energyConsumptionPoints) {
                    let res = energyPoints.data;
                    for (let conversionData of res) {
                        this.energyConversionpoints(conversionData, userEnergyConsumptionPreference)
                    }
                }
            }
        }
    }
    //energyConversionPoints
    energyConversionpoints(conversionData, userEnergyConsumptionPreference) {
        if (conversionData.d_d != null) {
            let value = parseFloat(conversionData.d_d.split(':')[1]);
            let convertedValue
            if (userEnergyConsumptionPreference == this.unitService.energyConsumptionUnitListArray[1]) {
                convertedValue = this.unitService.energyConsumptionKBTU(value);
            } else if (userEnergyConsumptionPreference == this.unitService.energyConsumptionUnitListArray[2]) {
                convertedValue = this.unitService.energyConsumptionTonrefh(value);
            } else if (userEnergyConsumptionPreference == this.unitService.energyConsumptionUnitListArray[3]) {
                convertedValue = this.unitService.energyConsumptionGigajoules(value);
            }
            let feedbackData = 'n:' + convertedValue;
            conversionData.d_d = feedbackData;
        }
    }

    /**
     * Calculates the converted temperature values for the given temperature points based on the user's temperature preference.
     * 
     * @param temperaturePoints - The array of temperature points.
     * @param userPreference - The user's temperature preference.
     */
    temperaturepoints(temperaturePoints, userPreference) {
        if (temperaturePoints.length > 0) {
            const userTemperaturePreference = userPreference.temperatureUnit;
            if (userTemperaturePreference == this.unitService.temperatureUnitListArray[0]) {
                for (let tempPoints of temperaturePoints) {
                    let res = tempPoints.data;
                    for (let conversionData of res) {
                        if (conversionData.d_d != null) {
                            var pointId = (tempPoints.dis).split('-').slice(-1).pop();
                            let value = parseFloat(conversionData.d_d.split(':')[1]);
                            let convertedValue = this.unitService.fahrenheitToCelecius(value, pointId);
                            let feedbackData = 'n:' + convertedValue;
                            conversionData['d_d'] = feedbackData;
                        }
                    }

                }
            }
        }
    }

    /**
     * Converts the energy consumption value in the given conversionData object based on the user's energy consumption preference.
     * 
     * @param {object} conversionData - The conversion data object.
     * @param {string} userEnergyConsumptionPreference - The user's energy consumption preference.
     */

    //airflowVolumePoints
    airflowVolumePoints(airflowVolumePoints, userPreference) {
        if (airflowVolumePoints.length > 0) {
            const userAirflowVolumePreference = userPreference.airflowVolumeUnit;
            if (userAirflowVolumePreference == this.unitService.airflowVolumeUnitListArray[1] || userAirflowVolumePreference == this.unitService.airflowVolumeUnitListArray[2] || userAirflowVolumePreference == this.unitService.airflowVolumeUnitListArray[3]) {
                for (let airflowVolume of airflowVolumePoints) {
                    let res = airflowVolume.data;
                    for (let conversionData of res) {
                        this.airFlowVolumeConversionPoints(conversionData, userAirflowVolumePreference)
                    }
                }
            }
        }
    }

    /**
     * Converts the air flow volume based on the given conversion data and user's airflow volume preference.
     * 
     * @param conversionData - The conversion data containing the air flow volume to be converted.
     * @param userAirflowVolumePreference - The user's preference for airflow volume unit.
     */
    airFlowVolumeConversionPoints(conversionData, userAirflowVolumePreference) {

        if (conversionData.d_d != null) {
            let value = parseFloat(conversionData.d_d.split(':')[1]);
            let convertedValue
            if (userAirflowVolumePreference == this.unitService.airflowVolumeUnitListArray[1]) {
                convertedValue = this.unitService.airFlowMeterCubePerHour(value);
            } else if (userAirflowVolumePreference == this.unitService.airflowVolumeUnitListArray[2]) {
                convertedValue = this.unitService.airFlowLiterPerMinute(value);
            } else if (userAirflowVolumePreference == this.unitService.airflowVolumeUnitListArray[3]) {
                convertedValue = this.unitService.airFlowLiterPerSecond(value);
            }
            let feedbackData = 'n:' + convertedValue;
            conversionData.d_d = feedbackData;
        }
    }

    /**
     * Calculates the air pressure points based on the user's preference.
     * 
     * @param airPressurePoints - The array of air pressure points.
     * @param userPreference - The user's preference object.
     */
    airPressurePoints(airPressurePoints, userPreference) {
        if (airPressurePoints.length > 0) {
            const userAirPressurePreference = userPreference.airPressureUnit;
            if (userAirPressurePreference == this.unitService.airPressureUnitListArray[1] || userAirPressurePreference == this.unitService.airPressureUnitListArray[2] || userAirPressurePreference == this.unitService.airPressureUnitListArray[3]) {
                for (let airPressure of airPressurePoints) {
                    let res = airPressure.data;
                    for (let conversionData of res) {
                        this.airPressureConversionPoints(conversionData, userAirPressurePreference)
                    }
                }
            }
        }
    }

    /**
     * Converts the air pressure value in the given conversionData object based on the user's air pressure preference.
     * 
     * @param {any} conversionData - The conversion data object.
     * @param {string} userAirPressurePreference - The user's air pressure preference.
     */
    airPressureConversionPoints(conversionData, userAirPressurePreference) {
        if (conversionData.d_d != null) {
            let value = parseFloat(conversionData.d_d.split(':')[1]);
            let convertedValue
            if (userAirPressurePreference == this.unitService.airPressureUnitListArray[1]) {
                convertedValue = this.unitService.airPressureMmofWater(value);
            } else if (userAirPressurePreference == this.unitService.airPressureUnitListArray[2]) {
                convertedValue = this.unitService.airPressureCmOfWater(value);
            } else if (userAirPressurePreference == this.unitService.airPressureUnitListArray[3]) {
                convertedValue = this.unitService.airPressurePascle(value);
            }
            let feedbackData = 'n:' + convertedValue;
            conversionData.d_d = feedbackData;
        }
    }

    /**
     * Calculates the water pressure points based on the given data and user preference.
     * 
     * @param waterPressurePoints - The array of water pressure points.
     * @param userPreference - The user's preference object.
     */
    waterPressurePoints(waterPressurePoints, userPreference) {
        if (waterPressurePoints.length > 0) {
            const userWaterPressurePreference = userPreference.waterPressureUnit;
            if (userWaterPressurePreference == this.unitService.waterPressureUnitListArray[1] || userWaterPressurePreference == this.unitService.waterPressureUnitListArray[2]) {
                for (let waterPressure of waterPressurePoints) {
                    let res = waterPressure.data;
                    for (let conversionData of res) {
                        this.waterPressureConversionPoints(conversionData, userWaterPressurePreference);
                    }
                }
            }
        }
    }

    /**
     * Converts the water pressure value in the given conversionData object based on the user's water pressure preference.
     * 
     * @param conversionData - The data object containing the water pressure value to be converted.
     * @param userWaterPressurePreference - The user's preferred unit of water pressure.
     */
    waterPressureConversionPoints(conversionData, userWaterPressurePreference) {
        if (conversionData.d_d != null) {
            let value = parseFloat(conversionData.d_d.split(':')[1]);
            let convertedValue
            if (userWaterPressurePreference == this.unitService.waterPressureUnitListArray[1]) {
                convertedValue = this.unitService.waterPressurePsi(value);
            } else if (userWaterPressurePreference == this.unitService.waterPressureUnitListArray[2]) {
                convertedValue = this.unitService.waterPressureKpi(value);
            }
            let feedbackData = 'n:' + convertedValue;
            conversionData.d_d = feedbackData;
        }
    }

    /**
     * Calculates the water flow points based on the given data and user preference.
     * 
     * @param waterFlowPoints - The array of water flow points.
     * @param userPreference - The user's preference object.
     */
    waterFlowPoints(waterFlowPoints, userPreference) {
        if (waterFlowPoints.length > 0) {
            const userWaterFlowPreference = userPreference.waterFlowUnit;
            if (userWaterFlowPreference == this.unitService.waterFlowUnitListArray[1] || userWaterFlowPreference == this.unitService.waterFlowUnitListArray[2] || userWaterFlowPreference == this.unitService.waterFlowUnitListArray[3]) {
                for (let waterFlow of waterFlowPoints) {
                    let res = waterFlow.data;
                    for (let conversionData of res) {
                        this.waterFlowConversionPoints(conversionData, userWaterFlowPreference);
                    }
                }
            }
        }
    }

    /**
     * Converts the water flow value in the given conversionData object based on the user's water flow preference.
     * 
     * @param conversionData - The data object containing the water flow value to be converted.
     * @param userWaterFlowPreference - The user's preferred unit of water flow.
     */
    waterFlowConversionPoints(conversionData, userWaterFlowPreference) {
        if (conversionData.d_d != null) {
            let value = parseFloat(conversionData.d_d.split(':')[1]);
            let convertedValue
            if (userWaterFlowPreference == this.unitService.waterFlowUnitListArray[1]) {
                convertedValue = this.unitService.waterFlowGalToMeterCubePerHour(value);
            } else if (userWaterFlowPreference == this.unitService.waterFlowUnitListArray[2]) {
                convertedValue = this.unitService.waterFlowGalToLiterPerMinute(value);
            } else if (userWaterFlowPreference == this.unitService.waterFlowUnitListArray[3]) {
                convertedValue = this.unitService.waterFlowGalToLiterPerSecond(value);
            }
            let feedbackData = 'n:' + convertedValue;
            conversionData.d_d = feedbackData;
        }
    }
    //******************************************************************Conversion FUnctions Ends************************************************


    /**
     * Converts the staged fan value to volt.
     * 
     * @param data - The data to be converted.
     * @returns The converted data.
     */
    convertStagedFanValuetoVolt(data) {
        const dataChanges = [];
        data.forEach(x => {
            if (x.hasOwnProperty('predefined') && x.hasOwnProperty('fan') && x.hasOwnProperty('speed')) {
                x.data.forEach(y => {
                    if (y.d_d) {
                        const value = Number(this.helperService.stripHaystackTypeMapping(y.d_d)) * 10 / 100;
                        const valueToString = value.toString();
                        y.d_d = `n:${valueToString}`

                    }
                })
                dataChanges.push(x)
            } else if (x.hasOwnProperty('operating') && x.hasOwnProperty('mode') && x.hasOwnProperty('zone')) {
                x.data.forEach(y => {
                    if (y.d_d) {
                        const value = Number(this.helperService.stripHaystackTypeMapping(y.d_d));
                        this.deviceHelper.setOperationModeData(value);
                    }
                })
                dataChanges.push(x)
            } else {
                dataChanges.push(x)
            }
        })
        return dataChanges;
    }


    /**
     * Filters and returns the rows based on the singleDual value of the hoverZoneData.
     * 
     * @param res - The response object containing the rows.
     * @returns The filtered rows based on the singleDual value.
     */
    zoneSingleDualGraph(res) {
        const graphIndexCooling = [];
        const graphIndexHeating = [];
        res['rows'].find((current) => {
            if (this.hoverZoneData?.singleDual == "1" && (current.hasOwnProperty('heating') && current.hasOwnProperty('desired'))) {
                graphIndexCooling.push(current);
            } else if (this.hoverZoneData?.singleDual == "2" && (current.hasOwnProperty('cooling') && current.hasOwnProperty('desired'))) {
                graphIndexHeating.push(current);
            }

            if (this.hoverZoneData?.singleDual == "1" && (current.hasOwnProperty('limit') && current.hasOwnProperty('min') && current.hasOwnProperty('heating'))) {
                graphIndexCooling.push(current);
            } else if (this.hoverZoneData?.singleDual == "2" && (current.hasOwnProperty('limit') && current.hasOwnProperty('min') && current.hasOwnProperty('cooling'))) {
                graphIndexHeating.push(current);
            }

            if (this.hoverZoneData?.singleDual == "1" && (current.hasOwnProperty('limit') && current.hasOwnProperty('max') && current.hasOwnProperty('heating'))) {
                graphIndexCooling.push(current);
            } else if (this.hoverZoneData?.singleDual == "2" && (current.hasOwnProperty('limit') && current.hasOwnProperty('max') && current.hasOwnProperty('cooling'))) {
                graphIndexHeating.push(current);
            }
        });


        if (graphIndexCooling.length) {
            let coolArray = graphIndexCooling.map((i) => i.id);
            for (let i = 0; i <= graphIndexCooling.length; i++) {
                res['rows'] = res['rows'].filter((item) => {
                    return coolArray.indexOf(item.id) === -1;
                });
            }
        } else if (graphIndexHeating.length) {
            let heatArray = graphIndexHeating.map((i) => i.id);
            for (let i = 0; i <= graphIndexHeating.length; i++) {
                res['rows'] = res['rows'].filter((item) => {
                    return heatArray.indexOf(item.id) === -1;
                });
            }

        }
        return res['rows'];
    }

    /**
     * Retrieves the system profile type.
     * 
     * This method filters all the system modbus profiles with the gateway reference and groups the equipment references based on the profile type (EMR, BTU, VAV/DAB external AHU).
     * 
     * @returns {void}
     */
    getSystemProfileType() {
        //In the following method we are filtering  all the system modbus profiles with the gateway ref 
        // then we are  grouping the equip refs based on the profile if it is EMR or BTU or VAV/DAB extenal ahu
        const combinedQuery = `gatewayRef==@${this.refs.gateway} and (system and modbus and profile))`;
        this.apiService.findByQuery(combinedQuery)
            .subscribe(
                res => {
                    //
                    if (!res?.rows?.length) {
                        return;
                    }
                    res = this.helperService.stripHaystackTypeMapping(res);

                    const filteredRows = res.rows.filter(row => row.hasOwnProperty('profile') && row.profile !== undefined);
                    // Handle no rows with profile defined
                    if (filteredRows.length === 0) {
                        return;
                    }
                    // Grouping the rows based on profile so profile name will come as key and object will come as value
                    const groupedRows = filteredRows.reduce((data, row) => {
                        if (!data[row.profile]) {
                            data[row.profile] = [];
                        }
                        data[row.profile].push(row);
                        return data;
                    }, {});

                },
                error => {
                    console.error("Error occurred while fetching data:", error);
                }
            );
    }

    /**
     * Retrieves zone settings based on the provided parameters.
     * 
     * @param settingType - The type of setting.
     * @param pointID - The ID of the point.
     * @param endPointType - The type of endpoint.
     * @param profileEquipName - The name of the profile equipment (optional).
     */
    getZoneSettings(settingType: string, pointID: any, endPointType: string, profileEquipName: string = undefined) {
        if (pointID) {
            pointID.map((pId) => this.helperService.assemblePointIdData(pId, endPointType, settingType, profileEquipName, 'update'));
            Object.assign(this.priorityArray, { [settingType]: {} });
            this.priorityArray[settingType]['id'] = pointID;
        }
    }
    /**
     * Retrieves the CCU setting data.
     * This method makes use of the helper service to get the point data.
     * It subscribes to the response and updates the CCU setting and system reconfiguration data accordingly.
     * @returns {void}
     */

    getCCuSettingData() {
        this.helperService.getPointData()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(res => {
                if (res) {
                    this.ccuSetting = (res) ? ObjectUtil.deepClone(res) : this.ccuSetting;
                    this.getSystemReconfigurationData(res);
                }
            });
    }
    /**
     * Generates a filter query based on the system points.
     * 
     * @returns {string} The generated filter query.
     */
    filterQuery() {
        let concatQuery = 'point and ';
        //Extract `query` values from `systemPoints` and filter for valid strings
        const uniquePoints = [...new Set(
            this.systemPoints
                .map(item => item.query)
                .filter(query => typeof query === 'string' && query.trim() && query !== '(equipRef==@)')
        )];
        uniquePoints.forEach((query, index) => {
            // Append the query to the string with 'or' separator, except for the last one
            concatQuery += query + (uniquePoints.length - 1 === index ? '' : ' or ');
        });
        return concatQuery;
    }

    /**
     * Retrieves CCU setting data.
     * 
     * @remarks
     * This method fetches data from the API service using a filter query and updates the systemPoints array.
     * It also updates the ccuSettingObj and priorityArray objects based on the retrieved data.
     * The method is recursive and calls itself to fetch additional data if available.
     */
    getCCUSettingData() {
        const domainNames = ['supplyAirTemperature1', 'supplyAirTemperature2', 'supplyAirTemperature3', 'averageSat', 'minSat', 'maxSat', 'ductStaticPressureSensor1_2', 'ductStaticPressureSensor2_2', 'ductStaticPressureSensor3_2', 'averagePressure', 'minPressure', 'maxPressure'];
        this.apiService.getHaystackDataByQuery(this.filterQuery()).subscribe(({ rows }) => {
            if (rows?.length > 0) {
                this.systemPoints = this.systemPoints.map(r => {
                    rows?.forEach(row => {
                        let arr = 0;
                        if (row?.domainName) {
                            //Here we are specifically checking for vavAdvancedHybridAhuV2 profile to get the correct id for connect module points
                            // As we are getting the samee domain name for connect module points that we need to show in the UI excluding above domianNames so we are adding this additionial condition check.
                            if (this.checkForAdvanceAhuProfiles.indexOf(this.equipGraphicSystemProfile) > -1 && (row?.domainName == r?.domainName)) {
                                if (row?.hasOwnProperty('connectModule') && domainNames.includes(row?.domainName)) {
                                    return;
                                } else {
                                    r['id'] = row?.id?.replace('r:', '');
                                }
                            } else if (row?.domainName == r?.domainName) {
                                r['id'] = row?.id?.replace('r:', '');
                            }
                        } else {
                            r?.tags?.forEach(tag => {
                                if (row[tag]) {
                                    arr += 1;
                                }
                            });
                            if (r?.tags?.length == arr) {
                                r['id'] = row?.id?.replace('r:', '');
                            }
                        }
                    });
                    return r;
                });
                this.systemPoints.forEach(point => {
                    if (point.id) {
                        this.ccuSettingObj[point.pointName] = {};
                        this.ccuSettingObj[point.pointName]['id'] = point.id;
                        this.helperService.assemblePointIdData(point.id, point.type, point.pointName, undefined, 'update');
                        Object.assign(this.priorityArray, { [point.pointName]: {} });
                        this.priorityArray[point.pointName]['id'] = point.id;
                    }
                });
                this.getCCuSettingData();
            }
        });
    }

    /**
     * Retrieves the system reconfiguration data and updates the system settings.
     * 
     * @param result - The result object containing the updated system settings.
     */
    getSystemReconfigurationData(result: any) {
        const self = this;
        const systemSettings = ObjectUtil.deepClone(this.systemReconfigurationSettings)
        let equipData = ObjectUtil.deepClone(this.systemReconfigurationSettings);
        if (!ObjectUtil.isEqual(result, systemSettings)) {
            this.systemReconfigurationSettings = ObjectUtil.deepClone(result);
            if (this.isdomainName && ['VAV Staged RTU', 'VAV Staged RTU with VFD Fan', 'VAV Fully Modulating AHU','DAB Staged RTU', 'DAB Staged RTU with VFD Fan'].includes(this.equipGraphicsProfiles)) this.setRelayMappingforVAV()
            equipData = ObjectUtil.deepClone(result);
        }
        if (!self.systemReconfigProfileName || self.systemReconfigProfileName.trim() == 'System Profile...') {
            self.systemReconfigProfileName = self.systemWidgets[0] ? this.setSystemReconfigName(self.systemWidgets[0]?.name?.slice(8)) : '';
            self.systemReconfigTitle = self.systemWidgets[0] ? self.systemWidgets[0]?.name?.slice(8) : '';
        }
        if (self.systemReconfigProfileName === 'System Profile') {
            self.systemReconfigProfileName = 'Default System Profile';
            self.systemReconfigTitle = 'Default System Profile';
        }

        this.profileReconfigCheck = !['vavExternalAHUController', 'dabExternalAHUController', 'vavAdvancedHybridAhuV2','dabAdvancedHybridAhuV2'].includes(this.equipGraphicSystemProfile);
        if (equipData?.dcwbEnabled?.hasOwnProperty('val') && equipData?.dcwbEnabled?.val == 1) {
            this.equipGraphicsProfiles = environment.partnerName === 'carrier' ? "VVT-C Fully Modulating AHU with DCWB" : "DAB Fully Modulating AHU with DCWB"
        }
        this.equipGraphicData = ObjectUtil.deepClone(equipData);
    }

    //This method helps to set the default relay mapping values for system level VAV profiles, since relay-mapping points aren't created by CCU when associated relay is in OFF state.
    setRelayMappingforVAV() {
        const relayAssociations = this.systemReconfigProfileName?.includes('VAV Fully Modulating AHU') ? { "relay-7Mapping": { val: 0 }, "relay-3Mapping": { val: 0 }, "analogOut1MinCooling": { val: 2 }, "analogOut1MaxCooling": { val: 10 }, "analogOut2MinStatic": { val: 2 }, "analogOut2MaxStatic": { val: 10 }, "analogOut3MinHeating": { val: 2 }, "analogOut3MaxHeating": { val: 10 }, "analogOut4MinAir": { val: 2 }, "analogOut4MaxAir": { val: 10 } } : { "relay-1Mapping": { val: 0 }, "relay-2Mapping": { val: 1 }, "relay-3Mapping": { val: 10 }, "relay-4Mapping": { val: 5 }, "relay-5Mapping": { val: 6 }, "relay-6Mapping": { val: 11 }, "relay-7Mapping": { val: 15 } };
        for (const key in relayAssociations) {
            if (!this.systemReconfigurationSettings.hasOwnProperty(key) || !this.systemReconfigurationSettings[key]?.hasOwnProperty('val')) {
                this.systemReconfigurationSettings[key] = relayAssociations[key];
            }
        }
    }

    //This method returns the profile name to systemReconfigProfileName.VVT-C is changed to DAB here, to avoid impact on logics being done for DAB profiles. 
    setSystemReconfigName(profile) {
        if (profile?.includes('VVT-C')) {
            return profile?.replace('VVT-C', 'DAB')
        }
        return profile;
    }

    /**
     * Retrieves the equipment graphic points for the system layout component.
     * 
     * This function retrieves various equipment graphic points based on the system profile.
     * It first retrieves the equipObj, OAOObj, and btuObj from their respective functions.
     * Then, it checks the equipGraphicSystemProfile and performs different actions based on its value.
     * If the equipGraphicSystemProfile is not "vavAdvancedHybridAhuV2", it iterates over systemprofileEquipGraphics
     * and calls the getZoneSettings function for each profile object.
     * If the equipGraphicSystemProfile is "vavAdvancedHybridAhuV2", it calls the getAdvancedHybridAhuV2Points function.
     * It also retrieves specific zone settings for btuObj and OAOObj if they exist.
     * Finally, it calls the getZoneSettings function for 'chilled Water Valve' and 'epidemic mode' if applicable.
     */
    getEquipGraphicPoints() {
        const equipObj = this.getEquip(['system', 'equip', 'profile']) || [];
        const OAOObj = this.getOAOEquip();
        const btuObj = this.getBtuObj();
        /**EquipGraphics**/
        if (this.checkForAdvanceAhuProfiles.indexOf(this.equipGraphicSystemProfile) === -1) {
            systemprofileEquipGraphics.forEach((_profileObj: any) => {
                if (_profileObj.key == 'dcwbEnabled') {
                    this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(equipObj, _profileObj?.tags, null, _profileObj?.filterTags, _profileObj?.domainName), _profileObj.action);
                } else {
                    this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(equipObj, _profileObj?.tags, null, null, _profileObj?.domainName), _profileObj.action);
                }
            });
        }

        //Note: Here we are keeping the seperate method for VAV Advanced AHU there are lot of thing which may be included in future 
        // And also this profile is only deals with DM integatsion so it will be handled seperatley with out comnbining with other profiles 
        //Cooling stages and heating stages differ based on load and sat 
        if (this.checkForAdvanceAhuProfiles.indexOf(this.equipGraphicSystemProfile) === -1) {
            this.getAdvancedHybridAhuV2Points(equipObj);
        }

        if (btuObj) {
            this.getZoneSettings('Outlet Water Temperature', this.helperService.getPointIdbyTags(btuObj, ['outlet', 'temp', 'system', 'displayInUi']), 'read');
            this.getZoneSettings('Inlet Water Temperature', this.helperService.getPointIdbyTags(btuObj, ['inlet', 'system', 'temp', 'displayInUi']), 'read');
            this.getZoneSettings('Flow Rate', this.helperService.getPointIdbyTags(btuObj, ['system','point','btu','actual','flow','logical','displayInUi']), 'read');
        }
        this.getZoneSettings('chilled Water Valve', this.helperService.getPointIdbyTags(equipObj, ['chilled', 'water', 'valve']), 'read')
        if (OAOObj) {
            this.getZoneSettings('epidemic mode', this.helperService.getPointIdbyTags(equipObj, ['epidemic', 'mode']), 'read');
        }
    }

    /**
     * Retrieves user setting data based on the provided key.
     * 
     * @param key - The key used to retrieve the user setting data.
     */
    getUserSettingData(key: string) {
        this.getUserSettingDataSubscriptionCollection[key] = this.helperService.getPointData()
            .pipe(
                takeUntil(this.unsubscribe))
            .subscribe(res => {

                if (key == 'reConfig') {
                    this.delayChanges.next(ObjectUtil.deepClone({
                        val: res,
                        key: key
                    }));

                } else {
                    this.delayChanges.next(ObjectUtil.deepClone({
                        val: res,
                        key: key
                    }));

                }
            });
    }

    /**
     * Retrieves the required points for the advanced hybrid AHU V2 equipment.
     * 
     * @param equipObj - The equipment object.
     * @returns void
     */
    getAdvancedHybridAhuV2Points(equipObj) {
        //vavAdvancedAhuV2EquipGraphics object are defined in the puc component from there were fetching requipred points
        vavDabAdvancedAhuV2EquipGraphics.forEach((_profileObj: any) => {
            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(equipObj, _profileObj?.tags, null, null, _profileObj?.domainName), _profileObj.action);
        });
    }

    async transformData(dataArray: any[]): Promise<{ [key: string]: string }> {
        const result: { [key: string]: string } = {};

        dataArray.forEach(item => {
            const domainName = item.domainName;
            const value = item.data[0]?.val;

            if (domainName && value) {
                // Remove the 'n:' prefix if it exists
                const cleanedValue = value.startsWith('n:') ? value.slice(2) : value;
                result[domainName] = cleanedValue;
            }
        });

        return result;
    }

    async co2BasedDamperControlOn() {
        const equipObj =this.getEquip(['system', 'equip', 'profile']) || [];
        let hsCpuAnalogOut1 = this.helperService.getPointIdbyTags(equipObj, null, null, null, ['co2BasedDamperControlOn'])[0];
        try {
            const res = await firstValueFrom(this.siteService.getBulkWritablePointDataForQRCode([hsCpuAnalogOut1],this.qrCodeMetaData));
            if (res?.rows?.length > 0) {
                return this.helperService.stripHaystackTypeMapping(res.rows[0]?.val).replace(/\ .*/, '');
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    async toogleCheckforStaticPressurePoint(): Promise<string | undefined> {
        try {
            const equipObj = this.getEquip(['system', 'equip', 'profile']);
            const pointIds = this.getPointIdsForVavDabadvanceSystemProfiles(equipObj);
            
            // Fetch writable data and current read points data using lastValueFrom
            const [writableData, writableData1] = await Promise.all([
                pointIds.advanceAhuSpPoint.filter(v => v).length ? lastValueFrom(this.siteService.getBulkWritablePointDataForQRCode(pointIds.advanceAhuSpPoint.filter(v => v), this.qrCodeMetaData)) : Promise.resolve(null),
                pointIds.advanceAhuSpPointReadpoints.filter(v => v).length ? lastValueFrom(this.siteService.getReadByIdManyForQRCode(pointIds.advanceAhuSpPointReadpoints.filter(v => v), this.qrCodeMetaData)) : Promise.resolve(null)
            ]);

            const domainNames = writableData1?.rows?.map(item => item.domainName);
            const transformedWritableData = await this.transformData(writableData?.rows || []);
    

            return this.determineResult(transformedWritableData, domainNames);
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error;
        }
    }

    // Method to get point IDs
    private getPointIdsForVavDabadvanceSystemProfiles(equipObj: any): {
        advanceAhuSpPoint: (string | undefined)[],
        advanceAhuSpPointReadpoints: (string | undefined)[]
    } {
        const tags = [
            'pressureSensorBusAdd0', 'pressureBasedFanControlOn', 'analog1InputAssociation', 'analog2InputAssociation',
            'ductStaticPressureSensor1_1', 'ductStaticPressureSensor1_2', 'ductStaticPressureSensor1_10',
            'ductStaticPressureSensor2_1', 'ductStaticPressureSensor2_2', 'ductStaticPressureSensor2_10',
            'ductStaticPressureSensor3_1', 'ductStaticPressureSensor3_2', 'ductStaticPressureSensor3_10'
        ];

        const pointIds: { [key: string]: string | undefined } = {};
        tags.forEach(tag => {
            pointIds[tag] = this.helperService.getPointIdbyTags(equipObj, null, null, null, tag)[0];
        });

        // Return the structured object with arrays
        return {
            advanceAhuSpPoint: [
                pointIds['pressureSensorBusAdd0'],
                pointIds['pressureBasedFanControlOn'],
                pointIds['analog1InputAssociation'],
                pointIds['analog2InputAssociation']
            ],
            advanceAhuSpPointReadpoints: [
                pointIds['ductStaticPressureSensor1_1'], pointIds['ductStaticPressureSensor1_2'], pointIds['ductStaticPressureSensor1_10'],
                pointIds['ductStaticPressureSensor2_1'], pointIds['ductStaticPressureSensor2_2'], pointIds['ductStaticPressureSensor2_10'],
                pointIds['ductStaticPressureSensor3_1'], pointIds['ductStaticPressureSensor3_2'], pointIds['ductStaticPressureSensor3_10']
            ]
        };
    }

    checkFilesTaggedforProfile(equipId) {
        this.apiService.getTaggedFiles(equipId).subscribe((res) => {
            this.taggedFilesListSystemProfile = res;
            if(res && res.length > 0){
                this.enableViewAssetsSystemProfile = true;
            } else {
                this.enableViewAssetsSystemProfile = false;
            }
        }, (error) => {
            console.log('Error in fetching tagged files', error);
        })
    }

    getSensorKey(pressureFanControl: number, analogInput: number) {
      const sensorMaps = {
         0: { 12: 'ductStaticPressureSensor1_1', 13: 'ductStaticPressureSensor1_2', 14: 'ductStaticPressureSensor1_10' },
         1: { 15: 'ductStaticPressureSensor2_1', 16: 'ductStaticPressureSensor2_2', 17: 'ductStaticPressureSensor2_10' },
         2: { 18: 'ductStaticPressureSensor3_1', 19: 'ductStaticPressureSensor3_2', 20: 'ductStaticPressureSensor3_10' },
     }

       return sensorMaps[pressureFanControl]?.[analogInput];
   };

    private determineResult(transformedWritableData: any, domainNames: string[] | undefined): string | undefined {
        const pressureSensorBusAdd0Value = Number(transformedWritableData['pressureSensorBusAdd0']);
        const pressureBasedFanControlValue = Number(transformedWritableData['pressureBasedFanControlOn']);
        const analog1InputAssociationValue = Number(transformedWritableData['analog1InputAssociation']);
        const analog2InputAssociationValue = Number(transformedWritableData['analog2InputAssociation']);

        const getSensorResult = (analogInput: number): string | undefined => {
            const sensorKey = this.getSensorKey(pressureBasedFanControlValue, analogInput);
            return sensorKey && domainNames?.includes(sensorKey) ? sensorKey : undefined;
        };

        if ([12, 13, 14, 15, 16, 17, 18, 19, 20].includes(analog1InputAssociationValue)) {
            const result = getSensorResult(analog1InputAssociationValue);
            if (result) return result;
        }

        if ([12, 13, 14, 15, 16, 17, 18, 19, 20].includes(analog2InputAssociationValue)) {
            const result = getSensorResult(analog2InputAssociationValue);
            if (result) return result;
        }

        const additionalChecks = {
            0: { 1: 'ductStaticPressureSensor1_2' },
            1: { 2: 'ductStaticPressureSensor2_2' },
            2: { 3: 'ductStaticPressureSensor3_2' }
        };

        const additionalResult = additionalChecks[pressureBasedFanControlValue]?.[pressureSensorBusAdd0Value];
        if (additionalResult && domainNames?.includes(additionalResult)) {
            return additionalResult;
        }
    }

    
    /**
     * Retrieves the equipment object based on the provided tags.
     * 
     * @param tags - The tags used to retrieve the equipment object.
     * @returns The equipment object.
     */
    equipConfig(domainName: any) {
        return this.widgetData.filter(equip =>
            equip.domainName === domainName && equip?._id == this.refs.ccuConfigRef
        )[0];
    }

    graphicListChangeEvent(data) {
        this.onGraphicListChangedEvent = data;
    }
    
    showPredefinedEquipGraphics(data) {
        this.showPredefinedEquipGraphic[data?.entityRef] = data?.showPredefinedGraphics;
    }
}
